import React, { useState, useEffect } from "react";
import Iframe from "react-iframe";
import constantes from "../../utils/constantes";
import request from "../../utils/request";
import ModalGalery from "../ModalGalery";
import "./styles.css";

const getData = (limit, mime, activePage, setGaleryData, setTotalCount) => {
  const consulta =
    "/upload/files?_limit=" +
    limit +
    "&name_containss_ne=avatar&mime_containss=" +
    mime +
    "&_start=" +
    (activePage - 1) * limit +
    "&_sort=updatedAt:desc";

  request(constantes("URL_API") + consulta, { method: "GET" })
    .then(medias => setGaleryData(medias))
    .catch(err => console.log({ err }));

  request(
    constantes("URL_API") +
      "/upload/files?_limit=0&name_containss_ne=avatar&mime_containss=" +
      mime,
    { method: "GET" }
  )
    .then(count => setTotalCount(count.length))
    .catch(err => console.log({ err }));
};

const Upload = ({
  mime = "image",
  mediaProp = null,
  typeFile = "image/*",
  exportFunc,
  dataMedia
}) => {
  const [modalGaleria, setModalGaleria] = useState(false);
  const [media, setMedia] = useState({
    ...mediaProp,
    url:
      mediaProp.url === ""
        ? mime === "image"
          ? constantes("placeholder")
          : ""
        : mediaProp.url
  });
  const [loading, setLoading] = useState(false);
  const [upload, setUpload] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [totalCount, setTotalCount] = useState(0);
  const [galeryData, setGaleryData] = useState([]);
  const [copy_copied, setCopyCopied] = useState(false);
  const [copy_key, setCopyKey] = useState(null);

  useEffect(
    () => getData(limit, mime, activePage, setGaleryData, setTotalCount),
    [limit, mime, activePage]
  );

  useEffect(() => {
    setMedia({
      ...mediaProp,
      url: mediaProp.url === "" ? constantes("placeholder") : mediaProp.url
    });
  }, [mediaProp]);

  const saveGalery = () => {};

  const handlePageChange = pageNumber => {
    setActivePage(pageNumber);
  };

  const uploadMedia = file => {
    const imgToUpload = new FormData();
    imgToUpload.append("files", file);
    request(
      constantes("URL_API") + "/upload",
      { method: "POST", body: imgToUpload },
      false
    ).then(data => {
      setLoading(false);
      exportFunc({
        ...media,
        url: data[0].url
      });
    });
  };

  const onImageChange = async event => {
    if (event.target.files && event.target.files[0]) {
      setLoading(true);
      setUpload(true);
      const file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = e => {
        setMedia({ ...mediaProp, url: e.target.result });
        uploadMedia(file);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <div>
      <ModalGalery
        modalGaleria={modalGaleria}
        toggleGaleria={() => setModalGaleria(!modalGaleria)}
        saveGalery={() => saveGalery()}
        activePage={activePage}
        limit={limit}
        totalCount={totalCount}
        handlePageChange={pageNumber => handlePageChange(pageNumber)}
        galeryData={galeryData}
        mime={mime}
        dataImg={media}
        dataMedia={dataMedia}
        copy_copied={copy_copied}
        setCopyCopied={copy_copied => setCopyCopied(copy_copied)}
        copy_key={copy_key}
        setCopyKey={copy_key => setCopyKey(copy_key)}
        exportFunc={media => exportFunc(media)}
      />
      <div
        className={
          mime === "video"
            ? "image video"
            : mime === "audio"
            ? "image audio"
            : "image modal-image"
        }
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {upload ? (
          loading ? (
            <div style={{ position: "absolute" }}>Subiendo archivo...</div>
          ) : (
            <div style={{ position: "absolute" }}>Subido con exito</div>
          )
        ) : null}

        {mime === "image" ? (
          <img
            id="target"
            src={media.url}
            alt="Imagen Destacada"
            className="imageInput"
          />
        ) : mime === "video" ? (
          <video
            key="videoPreview"
            preload="metadata"
            id="target"
            className="imageInput"
            width="320"
            height="240"
            controls
            src={media.url}
            style={{ marginBottom: 20 }}
          >
            Tu navegador no soporta la etiqueta de video.
          </video>
        ) : mime === "audio" ? (
          <audio
            controls
            key="audioPreview"
            preload="metadata"
            id="target"
            className="imageInput"
            src={media.url}
            style={{ marginBottom: 20 }}
          >
            Tu navegador no soporta la etiqueta de audio.
          </audio>
        ) : (
          <Iframe
            url={
              "https://drive.google.com/viewerng/viewer?embedded=true&url=" +
              media.url
            }
            width="100%"
            height="300px"
            display="initial"
            position="relative"
            allowFullScreen
          />
        )}
      </div>

      <div className="shadow">
        <textarea
          onChange={event => exportFunc({ ...media, alt: event.target.value })}
          value={media.alt}
          placeholder={`Alt para ${
            mime === "image"
              ? "la imagen"
              : mime === "audio"
              ? "el audio"
              : mime === "application/pdf"
              ? "el documento"
              : "el video"
          }`}
          className="form-control extract"
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          padding: 10
        }}
      >
        <div className="btn btn-light">
          <i className="fas fa-cloud-upload-alt" />
          <label color="success" htmlFor={typeFile}>
            Subir archivo
          </label>
          <input
            type="file"
            className="form-control d-none"
            accept={typeFile}
            id={typeFile}
            name={typeFile}
            onChange={event => onImageChange(event)}
          />
        </div>

        <div className="btn btn-light" onClick={() => setModalGaleria(true)}>
          <i className="fas fa-images" />
          <label color="success">Elegir de la galería</label>
        </div>
      </div>
    </div>
  );
};

export default Upload;
