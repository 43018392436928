import React from 'react';
import './styles.css';

export default function AnalyticBoxSesiones (props){


    return(
        <div>
          <div className="rounded_contAn contents shadow col statCol">
                    <div >
                      <span className="statText">{props.texto}</span>  
                      <div className="statText">{props.sessions}</div>  
                      
                    </div>
                </div>
        </div>
    )
  }

    