import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import auth from "../../utils/auth";
import request from "../../utils/request";
import constantes from "../../utils/constantes";
import LoadUser from "../LoadUser";
import Loading from "../Loading";
import moment from "moment";

const OpenTok = require("opentok");

export default class ModalConvocatorias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      name: "",
      solviacanals: [],
      solviarols: [],
      selectedsolviarol: "",
      selectedsolviacanal: "",
      users: [],
      date: new Date(),
      borrador: false,
      desc: "",
      webinars: []
    };
  }

  componentDidMount() {
    request(
      constantes("URL_API") +
        "/graphql?query=query%7B%0D%0A%20%20solviacanals%7B%0D%0A%20%20%20%20_id%0D%0A%20%20%20%20name%0D%0A%20%20%7D%0D%0A%20%20solviarols%7B%0D%0A%20%20%20%20_id%0D%0A%20%20%20%20name%0D%0A%20%20%7D%0D%0A%20%20stores%7B%0D%0A%20%20%20%20_id%0D%0A%20%20%20%20name%0D%0A%20%20%7D%0D%0A%7D",
      {
        method: "GET"
      }
    ).then(response => {
      this.setState({
        solviacanals: response.data.solviacanals.map(solviacanal => {
          return {
            value: solviacanal._id,
            label: solviacanal.name
          };
        }),
        solviarols: response.data.solviarols.map(solviarol => {
          return {
            value: solviarol._id,
            label: solviarol.name
          };
        }),
        stores: response.data.stores.map(stores => {
          return {
            value: stores._id,
            label: stores.name
          };
        })
      });
    });
  }

  usuariosSelect = users => {
    this.setState(users);
  };

  loading = loading => {
    this.setState({ loading });
  };

  toggle = () => {
    this.cleanModal();
    this.props.toogle();
  };

  cleanModal = () => {
    this.setState({
      name: "",
      desc: "",
      selectedsolviacanal: "",
      selectedsolviarol: "",
      usuariosSelect: [],
      users: [],
      date: new Date(),
      borrador: false
    });
  };

  createAvWebniars = async id => {
    const avs = this.state.usuariosSelect.map(async x => {
      return request(constantes("URL_API") + "/avwebinars", {
        method: "POST",
        body: {
          state: "pendiente",
          user: x.value,
          webinar: id
        }
      });
    });

    Promise.all(avs).then(() => {
      this.toggle();
      this.props.componentDidMount();
    });
  };

  createWebinar = async () => {
    const opentok = new OpenTok(
      "46328782",
      "3a5eab55576070a32ebdd00d102c140596a92d9b"
    );

    //Generate a basic session. Or you could use an existing session ID.
    let sessionId = await opentok.createSession(
      { mediaMode: "routed", archiveMode: "always" },
      async (error, session) => {
        // let sessionId = await opentok.createSession({}, async (error, session) => {
        if (error) {
          console.log("Error creating session:", error);
          return undefined;
        } else {
          // console.log("Session ID: " + session.sessionId);
          let sessionId = session.sessionId;
          let userID = await auth.getUserInfo();
          let webinar = {
            name: this.state.name,
            description: this.state.desc,
            solviacanal: this.state.selectedsolviacanal.value,
            solviarol: this.state.selectedsolviarol.value,
            date: this.state.date,
            state: this.state.borrador ? "borrador" : "activo",
            userLeader: userID._id,
            sessionId
          };

          request(constantes("URL_API") + "/webinars", {
            method: "POST",
            body: webinar
          }).then(async response => {
            await this.createAvWebniars(response._id);
            ///Notificacion para aceptar la convocatoria webinar
            request(constantes("URL_API") + "/mynotifications", {
              method: "POST",
              body: {
                title: "Invitación al Webinar " + response.name,
                body:
                  "Has sido invitado al Webinar " +
                  response.name +
                  " porfavor accede para confirmar su asistencia.",
                webinar: response.id,
                data: {
                  acceptwebinar: response.id
                },
                date: moment()
                  .utc()
                  .format(),
                users: this.state.usuariosSelect.map(x => x.value)
              }
            });
            //////Notificaciones inicio de webinar, justo en el momento de empezar
            request(constantes("URL_API") + "/mynotifications", {
              method: "POST",
              body: {
                title: "Webinar " + response.name,
                body:
                  "Buenas " +
                  response.userLeader.name +
                  " comienza tu webinar, ya puedes entrar desde tu panel de administrador",
                webinar: response.id,
                date: moment(response.date)
                  .utc()
                  .format(),
                data: {
                  webinar: response.id
                },
                users: [response.userLeader._id]
              }
            });
            request(constantes("URL_API") + "/mynotifications", {
              method: "POST",
              body: {
                title: "Webinar " + response.name,
                body: "Buenas comienza tu webinar",
                webinar: response.id,
                date: moment(response.date)
                  .utc()
                  .format(),
                data: {
                  webinar: response.id
                },
                users: this.state.usuariosSelect.map(x => x.value)
              }
            });
            if (moment(response.date).diff(moment(), "minutes") >= 30) {
              //////Notificaciones inicio de webinar, 30' antes
              request(constantes("URL_API") + "/mynotifications", {
                method: "POST",
                body: {
                  title: "Webinar " + response.name,
                  body:
                    "Buenas " +
                    response.userLeader.name +
                    " comienza tu webinar en 30 minutos, podras acceder a el desde tu panel de administrador",
                  webinar: response.id,
                  date: moment(response.date)
                    .subtract(30, "minutes")
                    .utc()
                    .format(),
                  data: {
                    webinar: response.id
                  },
                  users: [response.userLeader._id]
                }
              });
              request(constantes("URL_API") + "/mynotifications", {
                method: "POST",
                body: {
                  title: "Webinar " + response.name,
                  body: "Buenas comienza tu webinar en tan solo 30 minutos",
                  webinar: response.id,
                  date: moment(response.date)
                    .subtract(30, "minutes")
                    .utc()
                    .format(),
                  data: {
                    webinar: response.id
                  },
                  users: this.state.usuariosSelect.map(x => x.value)
                }
              });
            }

            if (moment(response.date).diff(moment(), "minutes") >= 1) {
              //////Notificaciones inicio de webinar, 1' antes
              request(constantes("URL_API") + "/mynotifications", {
                method: "POST",
                body: {
                  title: "Webinar " + response.name,
                  body:
                    "Buenas " +
                    response.userLeader.name +
                    " comienza tu webinar en 1 minuto, ya puedes entrar desde tu panel de administrador",
                  webinar: response.id,
                  date: moment(response.date)
                    .subtract(1, "minutes")
                    .utc()
                    .format(),
                  data: {
                    webinar: response.id
                  },
                  users: [response.userLeader._id]
                }
              });
              request(constantes("URL_API") + "/mynotifications", {
                method: "POST",
                body: {
                  title: "Webinar " + response.name,
                  body:
                    "Buenas comienza tu webinar en tan solo 1 minuto. Ya puedes acceder",
                  webinar: response.id,
                  date: moment(response.date)
                    .subtract(1, "minutes")
                    .utc()
                    .format(),
                  data: {
                    webinar: response.id
                  },
                  users: this.state.usuariosSelect.map(x => x.value)
                }
              });
            }
          });
        }
      }
    );
  };

  handleChangeUsers = selectedOption => {
    this.setState({
      usuariosSelect: selectedOption
    });
  };

  render() {
    return (
      <Modal isOpen={this.props.modalOpen} toggle={this.toggle}>
        <Loading loading={this.state.loading} />
        <ModalHeader toggle={this.toggle}>Nuevo Webinars</ModalHeader>

        <ModalBody>
          <Row style={{ marginBottom: 20 }}>
            <Col>
              <input
                style={{
                  width: "100%",
                  height: 31,
                  backgroundColor: "RGB(255, 255, 255)",
                  borderColor: "RGB(204, 204, 204)",
                  borderRadius: "4px",
                  paddingLeft: 20,
                  borderStyle: "solid",
                  borderWidth: "1px"
                }}
                value={this.state.name}
                onChange={name => this.setState({ name: name.target.value })}
                placeholder="Nombre Webinars"
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col>
              <input
                style={{
                  width: "100%",
                  height: 31,
                  paddingLeft: 20,
                  backgroundColor: "white"
                }}
                value={this.state.desc}
                onChange={desc => this.setState({ desc: desc.target.value })}
                type="textarea"
                placeholder="Descripcion Webinars"
              />
            </Col>
          </Row>

          <LoadUser
            loading={this.loading}
            usuariosSelect={this.usuariosSelect}
            solviacanals={this.state.solviacanals}
            solviarols={this.state.solviarols}
            stores={this.state.stores}
            cursesolviacanals={[]}
            cursesolviarols={[]}
            cursestores={[]}
          />

          <Row>
            <Col>
              <Select
                isMulti
                options={this.state.users}
                className="form-control rounded-select rounded_cont shadow"
                onChange={this.handleChangeUsers}
                value={this.state.usuariosSelect}
                placeholder={"Usuarios"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <DateTimePicker
                onChange={date => this.setState({ date })}
                value={this.state.date}
              />
            </Col>
            <Col xs="5">
              <div
                className="pretty p-switch p-fill"
                style={{ margin: "17px 0px 0px -20px" }}
              >
                <input
                  name="borrador"
                  id="borrador"
                  onChange={event =>
                    this.setState({ borrador: event.target.checked })
                  }
                  type="checkbox"
                  checked={this.state.borrador}
                />
                <div className="state">
                  <label htmlFor="borrador">
                    <em>Guardar como borrador</em>
                  </label>
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            className="btn-gradient-style"
            onClick={this.createWebinar}
          >
            Crear Convocatoria
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
