import React, {Component} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem } from 'reactstrap';
import PropTypes from 'prop-types';
import auth from '../../utils/auth';
import renderHTML from 'react-render-html';
import { Link } from 'react-router-dom';
import constantes from '../../utils/constantes';
import './styles.css';

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      zonas_admin: auth.getUserInfo().zonas_admin,
      // zonas_admin: {
      //     "content": true,
      //     "training": true,
      //     "focus": true,
      //     "smartlearning": true
      //},
      activas: '',
    };

    
    this.capitalize = this.capitalize.bind(this);

  }

  capitalize(str){
      return str.charAt(0).toUpperCase() + str.slice(1);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }


  async componentDidMount(){
      var activas = [];
      for (var key in this.state.zonas_admin) {
        if(this.state.zonas_admin[key] === true){
          activas[key] = this.state.zonas_admin[key]; 
        }
      }
      this.setState( { activas: activas });   
  }

  render() {

      return(
        <div>
        <Navbar className="posnavbar" expand="sm">
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav stacked="true" className="ml-auto" navbar>
              {
                Object.keys(this.state.activas).map( (key) => {
                  return(
                    <NavItem key={key}>
                      <Link className="textoblanco" to={"/"+key}>{ this.capitalize(key)}<i className={'fas ' + constantes("area_class")[key]}></i></Link>
                    </NavItem>
                  );
                })
              }
              <NavItem>
                <Link className="textoblanco" to="/users">Usuarios<i className="fas fa-users"/></Link>
              </NavItem>
              <NavItem>
                <Link className="textoblanco" to="/auth/login" onClick={() => auth.clearAppStorage()}>Cerrar Sesión <i className="fas fa-sign-out-alt"/></Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
      );
  }
}

Navbar.propTypes = {
  light: PropTypes.bool,
  dark: PropTypes.bool,
  fixed: PropTypes.string,
  color: PropTypes.string,
  role: PropTypes.string,
  expand: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
  // pass in custom element to use
}

export default NavBar;
