import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Collapse} from 'reactstrap';
import ReactTable from "react-table";
import './styles.css';
import Loading from '../../components/Loading';


class ModalDesgloseUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            loading: false,
            collapse:"hwhw"
        };
    }

    cleanModal = () => {
        this.setState({
            modalOpen: false,
            loading: false
        })
    }
    toggleCollapse = (i) => {        
        let stateName = 'collapse'+[i]
        this.state[stateName] === true?
        this.setState({ [stateName]: ![stateName] }):
        this.setState({[stateName]:true});
    }

    toggle = async () => {       
        if (this.state.modalOpen === true) {
            this.cleanModal()
        } else {
            this.setState({ modalOpen: !this.state.modalOpen })
        }
    }
    

    render() {
        return (
            <div>
                <Loading loading={this.state.loading} />
                <Modal isOpen={this.state.modalOpen} toggle={this.toggle} className={"modal-lg"}>
                    <ModalHeader toggle={this.toggle}>
                        {this.props.user.email}
                    </ModalHeader>                    
                    <ModalBody>
                        <h3>Histórico de cursos</h3>
                        {this.props.user.avancecursos.map((e, i) => 
                         <div key={i} className="fichaCurso shadow p-3 mb-5 bg-white rounded">
                                {/* <div onClick={()=>this.toggleCollapse(i)}>
                                <h4>{this.state["collapse"+i]===true?<i className="fas fa-chevron-down"/>:<i className="fas fa-chevron-right"/>} {e.cursos.nombre}</h4>
                                {console.log(this.props.user.email + "tiene "+this.props.user.avancetests.length+" avances")}                                
                                {this.props.user.avancetests.map(e =>console.log(e._id + " con el curso "+e.curso._id))}  
                                {console.log(e.cursos._id)}                              
                                {console.log(this.props.user.avancetests.find(t => t.curso._id === e.cursos._id))}
                                <span>Estado: {e.estao} - Progreso: {(e.avance.filter(e => e.status ==="completado").length/e.avance.length)*100}% 
                                    {e.estao==="activo"||e.estao==="iniciado"?null:(" - Nota del examen: "+
                                        (this.props.user.avancetests.find(t => t.curso._id === e.cursos._id).chosenanswers.filter(a => a.value==="true").length/
                                        this.props.user.avancetests.find(t => t.curso._id === e.cursos._id).chosenanswers.length)*10)
                                        +"/10"
                                        }
                                </span> 
                                </div> */}
                                <Collapse isOpen={this.state["collapse"+i]}>                                
                                <ReactTable
                                    data={e.avance.reverse()}
                                    columns={[
                                        {
                                            Header: 'Entrada',
                                            accessor: "_id",
                                            headerClassName: "title",
                                            Cell: row => (
                                                <div className="title">
                                                    {row.original.tipo === "entrada" ? this.props.entradasRef.filter(e => e._id === row.value)[0].title : this.props.examsRef.filter(e => e._id === row.value)[0].name}
                                                </div>
                                            )
                                        },
                                        {
                                            Header: "Estado",
                                            accessor: "status",
                                            width: 220,
                                            Cell: row => (
                                                <div className="type">
                                                    <span>
                                                        {row.value}
                                                    </span>
                                                </div>
                                            )
                                        },
                                        {
                                            Header: "Tipo",
                                            accessor: "tipo",
                                            width: 220,
                                            Cell: row => (
                                                <div className="tipe">
                                                    {row.value}
                                                </div>
                                            )
                                        }
                                    ]}
                                    defaultSorted={[
                                        {
                                            id: "user.email",
                                            desc: true
                                        }
                                    ]}
                                    defaultPageSize={10}
                                    className="-striped -highlight"
                                /> 
                                </Collapse>
                         </div>
                        )}
                    
                    </ModalBody>
                </Modal>
                <span onClick={this.toggle} style={{ color: 'black' }}><i className="fas fa-pencil-alt" /></span>
            </div>
        );
    }
}
export default ModalDesgloseUser;