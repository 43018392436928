import React, { Component } from "react";
import ReactTable from "react-table";
import request from "../../utils/request";
import constantes from "../../utils/constantes";
import TimeContentCard from "../../components/TimeContentCard";
import TablaParticipantes from "../../components/TablaParticipantes";
import { Link } from "react-router-dom";

export default class ConvocatoriaReactTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  deleteWebinar = id => {
    const uri =
      "/graphql?query=%7B%0A%20%20webinar(id%3A%20%22" +
      id +
      "%22)%7B%0A%20%20%20%20_id%0A%20%20%20%20mynotifications%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D";
    request(constantes("URL_API") + uri, { method: "GET" }).then(response => {
      response.data.webinar.mynotifications &&
        response.data.webinar.mynotifications.length > 0 &&
        response.data.webinar.mynotifications.map(x =>
          request(constantes("URL_API") + "/mynotifications/" + x._id, {
            method: "DELETE"
          })
        );
    });
    request(constantes("URL_API") + "/webinars/" + id, {
      method: "DELETE"
    })
      .then(() => {
        this.props.componentDidMount();
      })
      .catch(() => {
        this.props.componentDidMount();
      });
  };

  render() {
    return (
      <ReactTable
        data={this.props.webinars}
        columns={[
          {
            Header: "",
            accessor: "_id",
            width: 40,
            Cell: row => (
              <div className="delete">
                <Link to={"/webinars/" + row.value}>
                  <i className="fas fa-play" />
                </Link>
                {/* {row.original.state==="en progreso" ?

                                    (<Link to={"/webinars/" + row.value}>
                                        <i className="fas fa-play"></i>
                                    </Link>) :
                                    (<div>
                                    <i className="fas fa-ban"></i>
                                </div>)
                                } */}
              </div>
            )
          },
          {
            Header: "Nombre del Webinar",
            accessor: "name",
            Cell: row => (
              <div className="delete">{row.value ? row.value : ""}</div>
            )
          },
          // {
          //     Header: "Descripción",
          //     accessor: "description",
          //     headerClassName: 'retos',
          //     Cell: row => (
          //             <div className = "name">
          //                 { row.value }
          //             </div>
          //         )
          // },
          {
            Header: "Fecha",
            accessor: "date",
            width: 150,
            Cell: row => (
              <div className="delete">
                <TimeContentCard time={row.value} />
              </div>
            )
          },
          // {
          //     Header: "Canal",
          //     accessor: "solviacanal",
          //     width: 150,
          //     Cell: row => (
          //             <div className = "delete">
          //             {row.value ? row.value.name : ""}
          //             </div>
          //         )
          // },
          // {
          //     Header: "Rol",
          //     accessor: "solviarol",
          //     width: 160,
          //     Cell: row => (
          //             <div className = "delete">
          //                 {row.value ? row.value.name : ""}
          //             </div>
          //         )
          // },
          {
            Header: "Encargado",
            accessor: "userLeader",
            width: 100,
            Cell: row => (
              <div className="delete">
                {row.value ? row.value.username : ""}
              </div>
            )
          },
          {
            Header: "Estado",
            accessor: "state",
            width: 120,
            Cell: row => <div className="delete">{row.value}</div>
          },
          {
            Header: "",
            accessor: "avwebinars",
            width: 40,
            Cell: row => <TablaParticipantes row={row} />
          },
          {
            Header: "",
            accessor: "_id",
            width: 40,
            Cell: row => (
              <div className="delete">
                <span
                  onClick={() => {
                    if (
                      window.confirm("¿Estás seguro que quieres borrar esto?")
                    ) {
                      this.deleteWebinar(row.value);
                    }
                  }}
                >
                  {" "}
                  <i className="fas fa-trash-alt" />
                </span>
              </div>
            )
          }
        ]}
        defaultPageSize={10}
        className="-striped -highlight"
      />
    );
  }
}
