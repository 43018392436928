import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Col,
  Row,
  Alert
} from "reactstrap";
import Dropzone from "react-dropzone-uploader";
import Papa from "papaparse";
import ReactTable from "react-table";
import constantes from "../../utils/constantes";
import auth from "../../utils/auth";


const handleSubmit = (file, setDataImport) => {
  Papa.parse(file, {
    delimiter: ";",
    skipEmptyLines: true,
    complete: results => {
      setDataImport(results.data);
      //     loading: false
    }
  });
};

const onChangeStatus = (data, setDataImport, setError) => {
  //   this.setState({ loading: true });
  if (data.meta.status === "done") {
    handleSubmit(data.file, setDataImport);
  } else if (data.meta.status === "removed") {
    setDataImport([]);
    //   loading: false
  } else if (data.meta.status === "rejected_file_type") {
    setError("Error: Archivo no valido. Suba un .csv");
    //   loading: false
  }
};

const ModalEncuestaEdit = ({ reset, id }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [dataImport, setDataImport] = useState([]);
  const [error, setError] = useState("");
  const [newQuestion, setNewQuestion] = useState("");
  const [newPuntuacion, setNewPuntuacion] = useState({ "": "" });
  const [puntuacion, setPuntuacion] = useState([
    { Siempre: 3 },
    { "A veces": 2 },
    { "Rara vez": 1 },
    { Nunca: 0 }
  ]);
  const [form, setForm] = useState({
    name: "",
    examenfinal: false,
    tabactivo: "importar"
  });
  const [interpretacion, setInterpretacion] = useState([
    {
      inicio: 0,
      fin: 45,
      text: "Bien",
      picture:
        "https://training-solvia.s3.eu-west-1.amazonaws.com/ed0db2407fb84e72a37ab4625dc74799.png"
    },
    {
      inicio: 45,
      fin: 59,
      text: "Regular",
      picture:
        "https://training-solvia.s3.eu-west-1.amazonaws.com/ed0db2407fb84e72a37ab4625dc74799.png"
    },
    {
      inicio: 60,
      fin: 0,
      text: "Mal",
      picture:
        "https://training-solvia.s3.eu-west-1.amazonaws.com/ed0db2407fb84e72a37ab4625dc74799.png"
    }
  ]);
  useEffect(() => {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.getToken()}`
    }
    fetch(constantes("URL_API") + "/exams/" + id, {
      method: "GET",
      headers,
    }).then(data => data.json())
      .then(response => {
        setDataImport(response.preguntas)
        setInterpretacion(response.interpretacion)
        setPuntuacion(response.puntuacion)
        let name = response.name
        let examenfinal = response.examenfinal
        setForm({ ...form, name, examenfinal })
      });
  }, []
  )

  const deleteRow = rowID => {
    let newData = dataImport
      .slice(0, rowID)
      .concat(dataImport.slice(rowID + 1, dataImport.length));
    setDataImport(newData);
  };

  const deleteRowPuntuacion = rowID => {
    setPuntuacion(
      puntuacion.slice(0, rowID).concat(puntuacion.slice(rowID + 1, 4))
    );
  };

  const submint = () => {
    const finalresult = {
      name: form.name,
      examenfinal: true,
      puntuacion,
      preguntas: dataImport.map(x => x[0]),
      type: "encuesta",
      area_admin: "smartlearning",
      interpretacion
    };

    if (
      finalresult.name.trim() !== "" ||
      finalresult.puntuacion.length > 0 ||
      finalresult.preguntas.length > 0
    ) {
      console.log("ASFAS")
      fetch(constantes("URL_API") + "/exams", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.getToken()}`
        },
        body: JSON.stringify(finalresult)
      }).then(() => {
        setPuntuacion([
          { Siempre: 3 },
          { "A veces": 2 },
          { "Rara vez": 1 },
          { Nunca: 0 }
        ]);
        setNewPuntuacion({ "": "" });
        setDataImport([]);
        setForm({
          name: "",
          examenfinal: false,
          tabactivo: "importar"
        });
        setModalOpen(false);
        reset();
      });
    } else {
      setError(
        "La encuesta deber tener un nombre, almenos una pregunta y una puntuación"
      );
    }
  };
  return (
    <div>
      <Modal
        isOpen={modalOpen}
        toggle={() => {
          setForm({ name: "", examenfinal: false, tabactivo: "importar" });
          setDataImport([]);
          setModalOpen(!modalOpen);
        }}
        className={"modal-lg"}
      >
        <ModalHeader
          toggle={() => {
            setForm({ name: "", examenfinal: false, tabactivo: "importar" });
            setDataImport([]);
            setModalOpen(!modalOpen);
          }}
        >
          Edición de encuesta
        </ModalHeader>

        <ModalBody>
          <Container>
            {error.trim() !== "" && (
              <Alert color="danger" toggle={() => setError("")}>
                {error}
              </Alert>
            )}
            <Row style={{ marginBottom: "20px" }}>
              <Col xs={"9"}>
                <input
                  style={{
                    width: "100%",
                    height: 31,
                    backgroundColor: "RGB(255, 255, 255)",
                    borderColor: "RGB(204, 204, 204)",
                    borderRadius: "4px",
                    borderStyle: "solid",
                    borderWidth: "1px"
                  }}
                  value={form.name}
                  onChange={event =>
                    setForm({ ...form, name: event.target.value })
                  }
                  placeholder="Nombre Encuesta"
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Dropzone
                onChangeStatus={data =>
                  onChangeStatus(data, setDataImport, setError)
                }
                maxFiles={1}
                inputContent={"Adjunte su archivo .csv aquí..."}
                multiple={false}
                accept={"text/csv"}
              />
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Col>
                <Row style={{ marginBottom: "20px" }}>
                  <ReactTable
                    width="200%"
                    data={dataImport}
                    columns={[
                      {
                        Header: "Preguntas",
                        accessor: "0",
                        Cell: row => (
                          <div className="nameEncuesta">{row.value}</div>
                        )
                      },
                      {
                        Header: "",
                        accessor: "3",
                        width: 40,
                        Cell: row => (
                          <div className="delete">
                            <span
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "¿Estás seguro que quieres borrar esto?"
                                  )
                                ) {
                                  deleteRow(row.index);
                                }
                              }}
                            >
                              {" "}
                              <i className="fas fa-trash-alt"></i>
                            </span>
                          </div>
                        )
                      }
                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                  />
                </Row>
                <Row
                  style={{
                    marginBottom: "20px",
                    alignItems: "center",
                    alignContent: "center"
                  }}
                >
                  Añadir pregunta:{" "}
                  <input
                    type="text"
                    onChange={event => setNewQuestion(event.target.value)}
                    value={newQuestion}
                    style={{
                      marginLeft: "20px",
                      marginRight: "20px"
                    }}
                  />
                  <span
                    onClick={() => {
                      setDataImport([...dataImport, [newQuestion]]);
                      setNewQuestion("");
                    }}
                  >
                    <i className="fas fa-plus"></i>
                  </span>
                </Row>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <Row>
                  <Col>
                    <p style={{ width: "130px" }}>Respuesta</p>
                  </Col>
                  <Col>
                    <p style={{ width: "130px" }}>Puntuación</p>
                  </Col>
                  <Col></Col>
                </Row>
                {puntuacion.length > 0 &&
                  puntuacion.map((x, i) => (
                    <Row key={"key" + i} style={{ marginBottom: "20px" }}>
                      <Col>
                        <input
                          type="text"
                          style={{ width: "130px" }}
                          value={Object.keys(x)[0]}
                          onChange={event => {
                            const newPuntuacion = [...puntuacion];
                            newPuntuacion[i] = {
                              [event.target.value]: Object.values(x)[0]
                            };
                            setPuntuacion(newPuntuacion);
                          }}
                        />
                      </Col>
                      <Col>
                        <input
                          type="number"
                          style={{ width: "130px" }}
                          value={Object.values(x)[0]}
                          onChange={event => {
                            const newPuntuacion = [...puntuacion];
                            newPuntuacion[i] = {
                              [Object.keys(x)[0]]: parseInt(event.target.value)
                            };
                            setPuntuacion(newPuntuacion);
                          }}
                        />
                      </Col>
                      <Col>
                        <span onClick={() => deleteRowPuntuacion(i)}>
                          <i className="fas fa-minus"></i>
                        </span>
                      </Col>
                    </Row>
                  ))}
                <Row style={{ marginBottom: "20px" }}>
                  <Col>
                    <input
                      type="text"
                      style={{ width: "130px" }}
                      value={Object.keys(newPuntuacion)[0]}
                      onChange={event => {
                        setNewPuntuacion({
                          [event.target.value]: Object.values(newPuntuacion)[0]
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <input
                      type="number"
                      style={{ width: "130px" }}
                      value={Object.values(newPuntuacion)[0]}
                      onChange={event => {
                        setNewPuntuacion({
                          [Object.keys(newPuntuacion)[0]]: parseInt(
                            event.target.value
                          )
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <span
                      onClick={() => {
                        if (
                          Object.keys(newPuntuacion)[0] !== "" &&
                          Object.values(newPuntuacion)[0] !== ""
                        ) {
                          setPuntuacion([...puntuacion, newPuntuacion]);
                          setNewPuntuacion({ "": "" });
                        }
                      }}
                    >
                      <i className="fas fa-plus"></i>
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>Interpretación</Row>
            {interpretacion.length > 0 &&
              interpretacion.map((int, i) => {
                return (
                  <Row key={i}>
                    <Col>
                      <input
                        type="number"
                        value={int.inicio}
                        disabled={i === 0 ? true : false}
                        onChange={event => {
                          const newInterpretacion = [...interpretacion];
                          newInterpretacion[i] = {
                            ...interpretacion[i],
                            inicio: parseInt(event.target.value, 10)
                          };
                          setInterpretacion(newInterpretacion);
                        }}
                      />
                    </Col>
                    <Col>
                      <input
                        type="number"
                        value={int.fin}
                        disabled={
                          i === interpretacion.length - 1 ? true : false
                        }
                        onChange={event => {
                          const newInterpretacion = [...interpretacion];
                          newInterpretacion[i] = {
                            ...interpretacion[i],
                            fin: parseInt(event.target.value, 10)
                          };
                          setInterpretacion(newInterpretacion);
                        }}
                      />
                    </Col>
                    <Col>
                      <input
                        type="text"
                        value={int.text}
                        onChange={event => {
                          const newInterpretacion = [...interpretacion];
                          newInterpretacion[i] = {
                            ...interpretacion[i],
                            text: event.target.value
                          };
                          setInterpretacion(newInterpretacion);
                        }}
                      />
                    </Col>
                    <Col>
                      <input
                        type="text"
                        value={int.picture}
                        onChange={event => {
                          const newInterpretacion = [...interpretacion];
                          newInterpretacion[i] = {
                            ...interpretacion[i],
                            picture: event.target.value
                          };
                          setInterpretacion(newInterpretacion);
                        }}
                      />
                    </Col>
                  </Row>
                );
              })}
            <Row>
              <Button className="btn-gradient-style" onClick={() => submint()}>
                Guardar Cambios
              </Button>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
      <span
        onClick={() => {
          setForm({ name: "", examenfinal: false, tabactivo: "importar" });
          setDataImport([]);
          setModalOpen(!modalOpen);
        }}

      >
        <i className="fas fa-pencil-alt" />
      </span>

    </div>
  );
};

export default ModalEncuestaEdit;
