import React, { Component } from 'react';
import Metricas from "../Metricas";
import Retos from '../Retos';
import constantes from '../../utils/constantes';
import './styles.css';

class Training extends Component {
  
  constructor(props){
    super(props);
    let area = "training";
    localStorage.setItem('admin', area);
  }

  render(){

    return( <Retos history={this.props.history}/>);
  }

}
export default Training;