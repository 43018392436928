import React, { Component } from 'react';
import { OTSession, OTPublisher, startArchive } from 'opentok-react';
import request from '../../utils/request';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import constantes from '../../utils/constantes';
import ModalFinWebinar from '../../components/ModalFinWebinar';
import WebinarRecordings from '../../components/WebinarRecordings';
import './styles.css';

const OpenTok = require('opentok');
const apiKey = "46328782";
const secret = "3a5eab55576070a32ebdd00d102c140596a92d9b";

let opentok = new OpenTok(apiKey, secret);

export default class OpenTokVideo extends Component {
 constructor(props) {
   super(props);
   this.state= {
     video:false,
     audio:true,
     videoSource:false,
     start: false,
     modalOpen: false,
     end: false
   }
  

  this.publisherEventHandlers = {
    streamCreated: event => {
      
      console.log('Publisher stream created!');
    },
    streamDestroyed: event => {
      console.log('Publisher stream destroyed!');
    }
  };
   this.state = { streams: [], token: null, conectedPeople: [], recordID: "" };
 }

toggle = () => {
  this.setState({modalOpen:!this.state.modalOpen})
}

finalizarwebinar = () => {

  request(constantes("URL_API")+"/webinars/"+this.state.response.data.webinars[0]._id,{
    method: "PUT",
    body: {
      state: "finalizado"
    }
  })

  this.setState({
    end: true,
    modalOpen: false,
    start: false,
    iniciado: false,
  })
}

 endSession = () => {
   this.setState({
     modalOpen: true
   })
 }

 startSession = async() => {
  // opentok.getArchive( console.log(response) )
  const webinarId = this.props.webinarID;

  const response = await request(constantes("URL_API")+"/graphql?query=%7B%0A%20%20webinars(where%3A%7B_id%3A%22"+webinarId+"%22%7D)%7B%0A%20%20%20%20_id%0A%20%20%20%20name%0A%20%20%20%20sessionId%0A%09%09state%0A%20%20%7D%0A%7D", {method: "GET"});

  if(response.data.webinars[0].state === "finalizado"){
    this.setState({
      end:true,
      response
    })
  }else{
    let tokenOptions = {};
    let token;
    tokenOptions.role = "moderator";
    //tokenOptions.role = "subscriber";
    tokenOptions.data = `usernameID=${this.props.user._id}&username=${this.props.user.username}&webinar=${webinarId}`;

    token = opentok.generateToken(response.data.webinars[0].sessionId, tokenOptions); 
    // console.log(opentok.getBroadcast())

    await this.setState({
      opentok,
      response: response,
      token
    }) 
  }
 }

 async componentWillMount(){
   this.startSession();
 }

  render() {
    this.eventHandlers = {
      
      connectionCreated: event => {
        // console.log(this.props)
        let data = event.connection.data.split("&").map( x=> x.split("=")[1]);
        this.setState({
          conectedPeople: [...this.state.conectedPeople, {id:data[0],name:data[1]}]
        })
      },

      connectionDestroyed: event => {
        let data = event.connection.data.split("&").map( x=> x.split("=")[1]);
        
        let idToDelete = data[0];

        this.setState({conectedPeople: this.state.conectedPeople.filter(x => x.id !== idToDelete)})
      }
    }

    

  return(
    <div>
      <ModalFinWebinar toggle={this.toggle} finalizarwebinar={this.finalizarwebinar} modalOpen={this.state.modalOpen}/>
      {
        this.state.end && <WebinarRecordings webinar={this.state.response.data.webinars[0]._id}/>
      }
      { this.state.start && (
        <div>
          <OTSession 
            apiKey={apiKey} 
            sessionId={this.state.response.data.webinars[0].sessionId} 
            token={this.state.token} 
            eventHandlers={this.eventHandlers}
          >
            
            <OTPublisher
              properties={{
                // audioFallbackEnabled: true,
                showControls: true,
                fitMode:'cover',
                // frameRate:24,
                insertDefaultUI:true,
                publishVideo:this.state.video === true ? true : false ,
                publishAudio:this.state.audio,
                videoSource: this.state.videoSource ? 'screen' : undefined,
                style:{
                  audioLevelDisplayMode:'on',
                  backgroundImageURI:"https://economia3.com/wp-content/uploads/2019/04/estand-Solvia-SIMA-2017-e1556098387799.jpg"
                }
              }}
              eventHandlers={this.publisherEventHandlers}
            >
            </OTPublisher>
          </OTSession>
        </div>)  }
        {!this.state.end&&<Container style={{marginTop:20}}>
          <Row>
            <Col>
              <Button disabled={this.state.token?false:true} className={!this.state.iniciado ? 'btn-gradient-style' : 'boton-add2'} onClick={()=>this.state.iniciado?this.endSession():this.setState({start:true,iniciado:true}) }>{this.state.token ? this.state.iniciado ? 'Terminar Webinar': 'Iniciar Webinar' : 'Cargando....'}</Button>
            </Col>
          </Row>
            <Row>
              <Col>
                <Button className={!this.state.video ? 'btn-gradient-style' : 'boton-add2'} onClick={()=>this.setState({video: !this.state.video}) }>{this.state.video ? 'Desactivar': 'Activar'} Video</Button>
              </Col>
              <Col>
                <Button className={!this.state.audio ? 'btn-gradient-style' : 'boton-add2'} onClick={()=>this.setState({audio: !this.state.audio})}>{this.state.audio ? 'Desactivar': 'Activar'} Audio</Button>
              </Col>
              {this.state.start && this.state.video &&<Col>
                <Button className={!this.state.videoSource ? 'btn-gradient-style' : 'boton-add2'} onClick={()=>this.setState({videoSource:!this.state.videoSource})}>Cambiar a {this.state.videoSource? 'WebCam' : 'Pantalla'}</Button>
              </Col>}
            </Row>  
          </Container>}
    </div>
  ) 
  }
}
