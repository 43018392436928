import React, {Component} from 'react';
import { Button,Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input} from 'reactstrap';
import ReactTable from "react-table";
import Loading from '../../components/Loading';
import request from '../../utils/request';
import './styles.css';
import constantes from '../../utils/constantes';

class Retos extends Component {
  constructor(props){
    super(props);
    this.state = {
      editId: '',
      idAvatar:'',
      idEditando:'',
      loading: false,
      modal: false,
      modalEditReto: false,        
      newAvatar: '',
      newCantidad: '',
      newDescription: '',
      newRecordatorios: [],
      newRepeticiones: '',
      newRetoName: '',
      photo: '/img/userplaceholder.jpg',
      retos: [],
      selectBloque: [],

    };    
    this.addReto = this.addReto.bind(this);
    this.deleteReto = this.deleteReto.bind(this);
    this.editReto = this.editReto.bind(this);
    this.filtraRetos = this.filtraRetos.bind(this);     
    this.gestionaBloque = this.gestionaBloque.bind(this);
    this.gestionaRecords = this.gestionaRecords.bind(this); 
    this.handleNew = this.handleNew.bind(this);
    this.handleNewRecordatorios = this.handleNewRecordatorios.bind(this);
    this.handleNewavatar = this.handleNewavatar.bind(this);
    this.handlechangeAvatar = this.handlechangeAvatar.bind(this);
    this.handlenewCantidad = this.handlenewCantidad.bind(this);
    this.limpiezaEstados = this.limpiezaEstados.bind(this); 
    this.printReminders = this.printReminders.bind(this);
    this.setLoading = this.setLoading.bind(this);    
    this.storeAvatar = this.storeAvatar.bind(this);
    this.storechangeAvatar = this.storechangeAvatar.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  limpiezaEstados(){
    this.setState({         
      newAvatar:'',          
      newRetoName: '',
      newDescription: '',
      newRepeticiones: '',
      newCantidad: '',
      newRecordatorios:[],   
      editId: '',
      idAvatar:'',
      idEditando:'',
      photo: '/img/userplaceholder.jpg',
      loading: false,
    })
  }
  filtraRetos(value){
    if (value.target.value !=="" || value.target.value!==undefined) {
      let dataFiltro = this.state.retos2.filter(array => array.name.includes(value.target.value))
      this.setState({retos: dataFiltro})      
    } else {
      this.setState({retos: this.state.retos2})
      }
    }  
  setLoading(loading){
    this.setState({
      loading
    });
  }
  actualizaRetos(id){
    const retosMod= [...this.state.retos]; //copia el estado
    retosMod.find(e => e._id===id).status = !(retosMod.find(e => e._id===id).status); 
    this.setState({retos : retosMod});
  }
  toggle(id = "") {
    //si está abierto lo cerramos
    if (this.state.modal===true)
    {this.limpiezaEstados();
      this.setState({
        modal: !this.state.modal
        
      });} else {
        //compruebo que tenga ID válido
        if (id!==""){
          //Si tiene ID, cargamos la data del ID y abrimos.
          this.setState({loading:true})
            request(constantes("URL_API") + "/retos/"+id, { method: "GET" })    
            .then((retos) => this.setState({
              editRetoData: retos,
              newRetoName: retos.name,
              newDescription: retos.description,
              newCantidad: retos.amount,
              newRepeticiones: retos.often,
              idEditando: id,
              photo: retos.media!==null?retos.media.url: '/img/userplaceholder.jpg',
              newRecordatorios: retos.subretos,
              modal: !this.state.modal,
              loading:false,
              
              }));        
        } else {
          //Si no tiene ID, abrimos el modal
          this.setState({
            modal: !this.state.modal          
          });
        }
      }
  }
 
  gestionaBloque = (id) => {
    let array = this.state.selectBloque;
    if (array.includes(id))
    {
      //eliminar elemento del array
      var index = array.indexOf(id);
      if (index > -1) {
      array.splice(index, 1);
    }
      
    } else {
      //Añadir al array
      array.push(id)
    };
    this.setState({selectBloque: array});
  }

  editReto(id){        
    let body = {
    name: this.state.newRetoName,
    description: this.state.newDescription,
    amount: this.state.newCantidad,
    often: this.state.newRepeticiones,
    subretos:this.state.newRecordatorios,
    area_admin: localStorage.getItem('admin')
                }
    request(constantes("URL_API") + "/retos/"+id, {
      method: "PUT",
      body: body,
     }).then( response => {
                  this.handlechangeAvatar("retos", response._id);
                    this.limpiezaEstados();
                    this.componentDidMount();
                }).then(() => this.setState({
                  modal: !this.state.modal
                })).catch( err => console.log( err) )
    }    

  addReto(){  
    
      let body = {
        name: this.state.newRetoName,
        description: this.state.newDescription,
        amount: this.state.newCantidad,
        often: this.state.newRepeticiones,
        subretos:this.state.newRecordatorios,
        area_admin: localStorage.getItem('admin')
    }
    
    request(constantes("URL_API") + "/retos", {
        method: "POST",
        body: body,
        

    }).then( response => {      
        this.handleNewavatar("retos", response._id);                 
        this.limpiezaEstados();
        this.componentDidMount();
    }).then(() => this.setState({
      modal: !this.state.modal
    }))    
   
    }
  deleteReto(id){
    request(constantes("URL_API") + "/retos/"+id, { method: "DELETE" })
    .then((response) => this.componentDidMount()).catch( err => console.log( err));
  }
  handleNewRecordatorios(value, position){
    let array = this.state.newRecordatorios;
    let objeto = new Object();
    objeto.texto = value.target.value;
    objeto.offset = 0;
    objeto.tipo = "";
    array[position]=objeto;
    this.setState({
        newRecordatorios: array
    }); 
    
  }
  handleNew(value, concepto){
    let estado = "new"+concepto;
    this.setState({
        [estado]: value.target.value
    });
  }
  handlenewCantidad(value){
    this.setState({
        newCantidad: value.target.value
    }, ()=>{this.gestionaRecords()});    
    
  }
  
  storeAvatar(value){ 
    if (value.target.files && value.target.files[0]) {            
      this.setState({
        idAvatar: value.target.files[0]
        }); 
        let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({photo: e.target.result});
            };
            reader.readAsDataURL(value.target.files[0]); 
  }}
  storechangeAvatar(value){ 
    if (value.target.files && value.target.files[0]) {
      this.setState({
        idAvatar: value.target.files[0]
        }, () => {        
        this.handlechangeAvatar(this.state.idEditando);
      });
          
  }}
  handleNewavatar = async (ref, refId) => { 
      const imgToUpload = new FormData();
        imgToUpload.append('files', this.state.idAvatar);
        imgToUpload.append('field', "picture");
		    imgToUpload.append('refId', refId);
        imgToUpload.append('ref', ref);        
        request(constantes("URL_API") + "/upload", { method: 'POST',
                                                      body: imgToUpload,
                                                    }, false)                                                    
          
          .catch( err => console.log( err ) );
      }
  handlechangeAvatar = async () => {   
        const imgToUpload = new FormData();
          imgToUpload.append('files', this.state.idAvatar);
          imgToUpload.append('field', "picture");
          imgToUpload.append('refId', this.state.idEditando);
          imgToUpload.append('ref', "reto");       
          request(constantes("URL_API") + "/upload", { method: 'POST',
                                                        body: imgToUpload,
                                                      }, false) 
            .catch( err => console.log( err ) );
        } 
  printReminders(){          
          let inputRecordatorios = []            
          for (let paso = 0; paso <this.state.newRecordatorios.length; paso++) {            
            inputRecordatorios.push(
              <div className="row" key={paso}>
              <div className="col">
                <FormGroup>
                  <Label for={"Recordatorio "+(paso+1)}>Recordatorio {paso+1}</Label>
                  <Input type="text" 
                        name="name" 
                        id={"Recordatorio "+(paso+1)} 
                        key={paso} 
                        placeholder={"Recordatorio "+(paso+1)} 
                        onChange={ (value) => this.handleNewRecordatorios(value, paso)}
                        value={this.state.newRecordatorios[paso].texto} 
                        />                 
                </FormGroup>
                </div>                                    
              </div>
            );            
          };          
            
          return inputRecordatorios;          
        }      
  
  gestionaRecords(){
    if (this.state.newCantidad>this.state.newRecordatorios.length)
          { let diff = this.state.newCantidad - this.state.newRecordatorios.length
            for (let paso = 0; paso < diff; paso++){
              let recor2 = this.state.newRecordatorios;
              recor2.push({texto:'',offset:0, tipo:''});
              this.setState({newRecordatorios: recor2});
            } 
          } else if (this.state.newCantidad<this.state.newRecordatorios.length && this.state.newCantidad>=0){
            
            let diff = this.state.newRecordatorios.length - this.state.newCantidad
            for (let paso = 0; paso < diff; paso++){                
              let recor3 = this.state.newRecordatorios;
              recor3.pop();
              this.setState({newRecordatorios: recor3});
            } 
          } 
  }

  componentDidMount(){
    this.setState({loading:true})
    var consulta = "/graphql?query=%7B%0A%20retos(sort%3A%22name%22,%20limit%3A%200)%7B%0A%20%20%20%20picture%7B%0A%20%20%20%20%20%20url%0A%20%20%20%20%7D%0A%20%20%20%20_id%0A%20%20%20%20name%0A%20%20%20%20amount%0A%20%20%20%20often%0A%20%20%20%20reminder%0A%20%20%20%20description%20%20%0A%20%20%20%20area_admin%0A%20%20%7D%0A%7D";
    request(constantes("URL_API") + consulta, { method: "GET" })
    .then((retos) => {
      if(Object.keys(retos).includes("errors")){
        localStorage.clear();
        this.props.history.push('/auth/login');
      }else{
        this.setState({
          retos: retos.data.retos.filter(e => e.area_admin === localStorage.getItem('admin')),
          retos2: retos.data.retos.filter(e => e.area_admin === localStorage.getItem('admin')),      
        },()=>{this.setState({loading:false})})
      }
    });    
   
    
  }
  

  render() {
      const isDisabled = this.state.newRetoName==="" || 
      this.state.newDescription ===""|| 
      this.state.newCantidad<1 || 
      this.state.newCantidad==="" ||
      this.state.newRepeticiones<1 ||
      this.state.newRepeticiones==="" || 
      (this.state.newRecordatorios.length>=1?this.state.newRecordatorios[0].texto==="":null)
      ?true:false;
      return(

        <div className = "main_content listado">
          <div><h3>Retos</h3></div>
          <div className="rounded_cont contents shadow">
              <div className="row">
              
                <div className="col">
                <Loading loading={this.state.loading}/>
                <input type="text" placeholder="Nombre de Reto" onChange={this.filtraRetos} className="form-control list-search"/>
                    <ReactTable
                      data={this.state.retos}                      
                      columns={[
                        {
                          Header: '',                        
                          accessor: "_id",
                          width: 40,
                          Cell: row => (
                          <div className=""><input name={row.value} id={row.value} type="checkbox" onChange={() => this.gestionaBloque(row.value)} checked={this.state.selectBloque.includes(row.value)?true:false}/>
                          <div className="state"></div></div>                     
                            )
                        },                        
                        {
                          Header: "Nombre de Reto",
                          accessor: "name",
                          //width: 120,                          
                          Cell: row => (
                            <div className = "name">
                              { row.value } 
                            </div>
                          )
                        },
                        {
                          Header: "Descripción",
                          accessor: "description",                                                  
                          Cell: row => (
                            <div className = "desc">
                            { row.value }                              
                             </div>
                           )
                         },
                        {
                          Header: "Cantidad",
                          accessor: "amount",
                          width:80,
                          headerClassName: 'amount',
                          Cell: row => (
                            <div className = "amount">
                              { row.value }
                            </div>
                          )
                        },
                        {
                          Header: "Repeticiones",
                          accessor: "often",
                          width: 80,                          
                          Cell: row => (
                            <div className = "status">
                              { row.value }
                            </div>
                          )
                        },                        
                         {
                          Header: "Editar",
                          accessor: "_id",
                          width: 80,                          
                          Cell: row => (
                            <div className = "edit">
                            <span onClick={() =>{this.toggle(row.value)}}><i className="fas fa-pencil-alt"/></span>
                              
                             </div>
                           )
                         },
                        {
                          Header: "Eliminar",
                          accessor: "_id",
                          width: 80,                          
                          Cell: row => (
                            <div className = "delete">
                              <span onClick={ () => {
                                    if (window.confirm("¿Estás seguro que quieres eliminar este reto?")){
                                      this.deleteReto(row.value)
                                    }
                                  }
                                } > <i className="fas fa-trash-alt"/> 
                              </span>
                            </div>
                          )
                        }
                      ]}
                      defaultPageSize={10}
                      className="-striped -highlight"
                    />
                </div>
                {this.state.selectBloque.length > 0?
              <div className="col-12">
                <div className="row menuBloque">
                  <div className="col-10"> <span >Has seleccionado {this.state.selectBloque.length} {this.state.selectBloque.length<2?"reto":"retos"}.</span>
                    </div>
                    <div className="col-2 text-center"> <span onClick={ () => {
                                if (window.confirm("¿Estás seguro que quieres eliminar estos retos?")){
                                  this.state.selectBloque.forEach((element)=>this.deleteReto(element))                                                                    
                                };
                                this.setState({selectBloque:[]});                                
                              }
                            } > <i className="fas fa-trash-alt"/> 
                          </span>
                    </div>
                </div>
              </div>:""}
            </div>
          </div>

          <div className="row">
            <div className = "col">
              <div className = "btns">
                <div className="ml-0 mr-0 pl-0 pr-0 buttons">                                
                <div className = "col-3">
                  <Button color="primary" onClick={()=>this.toggle()} >Añadir Reto</Button>
                  </div>  
                </div>                
              </div>
            </div>
          </div>

                        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className + ('marcomodal')}>          
                          <ModalBody className="registroUser">
                            <div className="row">
                              <div className="col">
                              <Form>
                                <div className="row">
                                  <div className="col">
                                    <FormGroup>
                                      <Label for="UserName">Título de Reto</Label>
                                      <Input className="form-control" type="text" name="name" id="userName" placeholder="Título de reto" value={this.state.newRetoName} onChange={(value)=>this.handleNew(value,"RetoName")}/>
                                    </FormGroup>
                                    </div>                                                                        
                                  </div>
                                <div className="row">
                                  <div className="col">
                                    <FormGroup>
                                      <Label for="description">Descripción del Reto</Label>
                                      <Input  className="form-control" type="textarea" name="desc" id="description" placeholder="Descripción de reto" value={this.state.newDescription} onChange={(value)=>this.handleNew(value, "Description")}/>
                                    </FormGroup>
                                    </div>                                    
                                  </div> 
                                   <div className = "row">
                                      <div className="col">
                                        <FormGroup>
                                          <Label htmlFor="avatar">Imagen del Reto</Label>
                                          <div className="row">
                                            <div className="col-3"><img id="target" src={this.state.photo} alt="Imagen Destacada" className="imageInput"/></div>
                                            <Input className="form-control" type="file" name="file" id="avatar" accept=".jpg,.png, .gif" onChange={this.storeAvatar}/>
                                          </div>                                          
                                        </FormGroup>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <FormGroup>
                                          <Label for="UserName">Cantidad de recordatorios</Label>
                                          <Input className="form-control" type="number" name="name" id="cantidad" placeholder="Cantidad de recordatorios" value={this.state.newCantidad} onChange={this.handlenewCantidad}/>
                                        </FormGroup>
                                      </div>                                    
                                      <div className="col">
                                        <FormGroup>
                                          <Label for="UserName">Dias entre recordatorios</Label>
                                          <Input className="form-control" type="number" name="name" id="repeticiones" placeholder="Dias entre recordatorios" value={this.state.newRepeticiones} onChange={(value)=>this.handleNew(value, "Repeticiones")}/>
                                        </FormGroup>
                                      </div>                                    
                                    </div>
                                    {this.printReminders()}
                                    
                                  </Form> 
                                </div>  
                              </div>  
                              <div className="row">

                              </div>
                          </ModalBody>
                        <ModalFooter>
                          <Button color="primary" 
                            onClick={this.state.idEditando===''?this.addReto:() =>{this.editReto(this.state.idEditando)}} 
                            disabled={isDisabled}
                                      >{this.state.idEditando===''?'Añadir Reto':'Guardar cambios'}</Button>
                          <Button color="secondary" onClick={this.toggle}>Descartar</Button>                          
                        </ModalFooter>
                      </Modal>                    
        </div>
      );
  }
}
export default Retos;