import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import request from '../../utils/request';
import constantes from '../../utils/constantes';

export default class WebinarRecordings extends Component {
    constructor(props){
        super(props);
        this.state = {
            pointer: 0,
            length: 0,
            recordings: []
        }
    }

    loadData = () => {
        const uri = `/graphql?query=%7B%0A%20%20webinars(where%3A%7B_id%3A%22${this.props.webinar}%22%7D)%7B%0A%20%20%20%20_id%0A%20%20%20%20recordings%0A%20%20%7D%0A%7D`
        request(constantes("URL_API")+uri,{
            method: "GET"
        }).then( response => {
            console.log(response)
            this.setState({
                recordings: response.data.webinars[0].recordings,
                length: response.data.webinars[0].recordings.length-1
            })
        })
    }

    componentDidMount = () => {
        this.loadData()
    }

    retroceso = () => {
        let pointer = this.state.pointer;

        if(pointer - 1 < 0){
            pointer = this.state.length;
        }else{
            pointer--;
        }

        this.setState({
            pointer
        })
    }
    
    avance = () => {
        let pointer = this.state.pointer;

        if(pointer + 1 > this.state.length){
            pointer = 0;
        }else{
            pointer++;
        }

        
        this.setState({
            pointer
        })
    }

    render() {
        let ret = this.state.recordings.length > 0 ?

            <div>
                {this.state.length>0&&<div onClick={this.retroceso}><i className="fas fa-arrow-circle-left"></i></div>}
                    <video style={{width: "100%", minHeight: "500px"}} preload="metadata" className="imageInput" controls src={this.state.recordings[this.state.pointer]}>Your browser does not support the video tag.</video>
                {this.state.length>0&&<div onClick={this.avance}><i className="fas fa-arrow-circle-right"></i></div>}
            </div>
        :

        <div>
            <p>Aun no se han terminado de subir los videos, intentelo mas tarde</p>
            <Button className='btn-gradient-style' onClick={this.loadData}>Actualizar</Button>
        </div>

        return ret;
    }
}