import React, {Component} from 'react';
import './styles.css';
import ReactLoading from 'react-loading';

class Loading extends Component {
  render() {
      return(
        this.props.loading===true?<div className="load"> 
            <ReactLoading type="spin" color="black" height={70} width={70} />
        </div>:null
      );
  }
}

export default Loading;
