import React, {Component} from 'react';
import { Button,Label, Input, FormText } from 'reactstrap';
import request from '../../utils/request';
import auth from '../../utils/auth';
import './styles.css';
import constantes from '../../utils/constantes';

class Profile extends Component {
  constructor(props){
    super(props);
    this.textInput = React.createRef();
    this.state = {
        roles: [],        
        editId: '',
        idAvatar:'',
        idEditando:'',
        photo: '/img/userplaceholder.jpg',

    };       
    this.editUser = this.editUser.bind(this);
    this.handleNew = this.handleNew.bind(this);    
    this.handlechangeAvatar = this.handlechangeAvatar.bind(this);
    this.storechangeAvatar = this.storechangeAvatar.bind(this);     
    this.limpiaEstados = this.limpiaEstados.bind(this);
    this.reload = this.reload.bind(this);     
  }
  limpiaEstados(){
    this.setState({
        roles: [],        
        editId: '',
        idAvatar:'',
        idEditando:'',
        photo: '/img/userplaceholder.jpg',
        newAvatar: null,
    })
  }
  reload = () => 
  { console.log("patata");
      //RELOAD COMPONENT
      this.componentDidMount();
  };
  editUser(id){    
    if (this.state.muestraPass===this.state.confirmPass) {
          if (this.state.newEmail === this.state.mailRef)
              {let user = {
                username: this.state.newUsername,
                name: this.state.newName,
                surname: this.state.newApellido,              
                role:this.state.newRole,
                status: this.state.newEstado,
                }
                request(constantes("URL_API") + "/user/"+id, {
                    method: "PUT",
                    body: user,
                }).then( response => {
                    
                    this.limpiaEstados();
                    this.componentDidMount();
                })
              } else {
                let user = {
                  username: this.state.newUsername,
                  email: this.state.newEmail,
                  name: this.state.newName,
                  surname: this.state.newApellido,              
                  role:this.state.newRole,
                  status: this.state.newEstado,     
                  }
                  request(constantes("URL_API") + "/user/"+id, {
                      method: "PUT",
                      body: user, 
                  }).then( response => {
                      this.handlechangeAvatar("user", response._id);
                      this.limpiaEstados();
                      this.componentDidMount();
                  }) 
              };
      }     
    }
  handleNew(value, concepto){
      let estado = "new"+concepto;
      this.setState({
          [estado]: value.target.value
      });
    }
  storechangeAvatar(value){ 
    if (value.target.files && value.target.files[0]) {
      this.setState({
        idAvatar: value.target.files[0]
        }, () => {        
        this.handlechangeAvatar(auth.getUserInfo()._id);
      });
      let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({photo: e.target.result});
            };
            reader.readAsDataURL(value.target.files[0]); 
            
  }}
  handlechangeAvatar = async () => { 
        console.log("añado avatar!")   
        const imgToUpload = new FormData();
          imgToUpload.append('files', this.state.idAvatar);
          imgToUpload.append('field', "picture");
          imgToUpload.append('refId', auth.getUserInfo()._id);
          imgToUpload.append('ref', "user");
          imgToUpload.append('source', 'users-permissions')        
          request(constantes("URL_API") + "/upload", { method: 'POST',
                                                        body: imgToUpload,
                                                      }, false)  
            .catch( err => console.log( err ) );
        }  
  printRoles(){
    
    return this.state.roles.filter((roles) => {
                    if (this.state.newRoleName==="Administrator"){
                      return roles.name==="Comercial" || roles.name==="Generico" || roles.name==="Administrator"
                    } else {
                      return roles.name==="Comercial" || roles.name==="Generico"
                    }
                  }).map((roles) => {
                    return(
                      <option value={roles._id} key={roles._id}>{roles.name}</option>  
                    );
                  });
                }
  
  componentDidMount(){
    request(constantes("URL_API") + "/graphql?query=%7B%0Aroles%7B%0A%20%20name%0A%09_id%0A%20%20%7D%0A%7D", { method: "GET" })
    .then((roles) => this.setState({
      roles: roles.data.roles
    }));
    request(constantes("URL_API") + "/graphql?query=%7B%0A%20%20user(id%3A%20%22"+auth.getUserInfo()._id+"%22)%7B%0A%20%20%20%20picture%7B%0A%20%20%20%20%20%20url%0A%20%20%20%20%7D%0A%20%20%20%20_id%0A%20%20%20%20username%0A%09%09name%0A%09%09surname%0A%20%20%20%20email%0A%20%20%20%20status%0A%20%20%20%20role%7B%0A%20%20%20%20%20%20name%0A%09%09%09_id%0A%20%20%20%20%7D%20%20%0A%20%20%7D%0A%7D", { method: "GET" })
    .then((usuarios) => this.setState({
      editUserData: usuarios.data.user,
      mailRef: usuarios.data.user.email,
      newApellido: usuarios.data.user.surname,
      newEmail: usuarios.data.user.email,
      newEstado: usuarios.data.user.status,
      newName: usuarios.data.user.name,
      newRole: usuarios.data.user.role._id,
      newRoleName: usuarios.data.user.role.name,
      newUsername: usuarios.data.user.username,
      photo: usuarios.data.user.picture?usuarios.data.user.picture.url: '/img/userplaceholder.jpg',
    }));  
  }

  render() {
    
      return(

        <div className = "main_content listado">
          <div><h3>Perfil</h3></div>
          <div className="rounded_cont contents shadow">
            <div className="row profile">
              <div className="modal-registry">
                  <div className="row">
                    <div className="col">
                  <div className="row">
                    {/*Imagen del administrador */}
                    <div className="col-4">
                      <div className="row">
                        <div className="col">
                          <Label htmlFor="avatar">Fotografía</Label>
                          <div className="row">
                            <img id="target" src={this.state.photo} alt="Imagen Destacada" className="imageInput col-2"/>
                          </div>
                          <div className="row">  
                            <Input className="col-12" type="file" name="file" id="avatar" accept=".jpg,.png, .gif" onChange={this.storechangeAvatar}/>
                            <FormText color="muted">
                              Elige la imagen principal que quieras utilizar.
                            </FormText>
                          </div>   
                        </div>
                      </div>
                    </div>
                    {/* Datos del administrador */}
                    <div className="col-8">
                      <div className="row">
                        <div className="col">
                          <div className="row">
                            <div className="col">
                              <Label for="UserName">Nombre de Usuario</Label>
                              <Input type="text" name="username" id="userName" placeholder="Nombre de usuario" value={this.state.newUsername} onChange={(value)=>this.handleNew(value, "Username")} className = "rounded_cont shadow"/>
                            </div>
                            <div  className="col-5">
                              <Label for="rol">Rol</Label>
                              <Input type="select" name="select" id="rol" value={this.state.newRole} onChange={(value)=>this.handleNew(value,"Role")} className="rounded_cont shadow">
                                <option value="" key="none">Ninguno</option>
                                {this.printRoles()}
                              </Input>                    
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <Label for="exampleEmail">Email</Label>
                              <Input type="email" name="email" id="exampleEmail" placeholder="Email" value={this.state.newEmail} onChange={(value)=>this.handleNew(value, "Email")} className="rounded_cont shadow"/>
                            </div>
                            <div  className="col-4">
                              <Label for="activo">Estado</Label>
                              <Input type="select" name="select" id="activo" value={this.state.newEstado} onChange={(value)=>this.handleNew(value,"Estado")} className="rounded_cont shadow">
                                <option value="true">Activo</option>
                                <option value ="false">Inactivo</option>
                              </Input>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5">
                              <Label for="UserNombre">Nombre</Label>
                              <Input type="text" name="username" id="userNombre" placeholder="Nombre" value={this.state.newName} onChange={(value)=>this.handleNew(value,"Name")} className="rounded_cont shadow"/>
                            </div>
                            <div className="col">
                              <Label for="UserApellido">Apellidos</Label>
                              <Input type="text" name="userapellido" id="userApellido" placeholder="Apellidos" value={this.state.newApellido} onChange={(value)=>this.handleNew(value,"Apellido")} className="rounded_cont shadow"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                  </div>
                  </div>  
                  <div className="row">
                    <div className="col">
                      <Button color="primary" onClick={() =>{this.editUser(auth.getUserInfo()._id)}}>Guardar cambios</Button>
                    </div>
                    <div className="col">
                      <Button color="secondary" onClick={()=>this.reload()}>Descartar</Button> 
                    </div>                      
                  </div>
                  </div>            
               </div>
          </div>
        </div>   
      );
  }
}

export default Profile;