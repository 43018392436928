import React, { Component } from 'react';
import request from '../../utils/request';
import ReactTable from "react-table";
import TimeContentCard from "../../components/TimeContentCard/";
import CategoriaForm from '../../components/CategoriaForm';
import './styles.css';
import constantes from '../../utils/constantes';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import SearchListFilterComponent from '../../components/SearchListFilterComponent';
import SearchListServerSide from '../../utils/SearchListServerSide';

class Categorias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categorias: [],
      categoria: "",
      categoria_padre: "",
      nombre: "",
      newCategoriaName: "",
      descripcion_corta: "",
      toggleAddCategory: false,
      toggleEditCategory: false,
      modal: false,
      modalEntradas: false,
      limit: 5,
      start: 0,
      total: 0,
      filter: [],
      model: "categorias",
      entradasCategoria: [],
      sin_asignar: "",
      area_admin: true
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.loadFilter = this.loadFilter.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  fetchData(state, instance) {
    this.setState({
      start: instance.state.page * instance.state.pageSize,
      limit: instance.state.pageSize,
      sort: state.sorted,
      filter: this.state.filter
    });
  }
  setLoading(loading) {
    this.setState({
      loading
    });
  }

  loadFilter(response, filter) {
    this.setState({
      categorias: response,
      loading: false,
      filter: filter
    });
  }

  handleNew(value, concepto) {
    let estado = "new" + concepto;
    this.setState({
      [estado]: value.target.value
    });
  }

  deleteCategory(id) {
    request(constantes("URL_API") + "/categorias/" + id, { method: "DELETE" })
      .then(response => this.componentDidMount())
      .catch(err => console.log(err));
  }

  editCategoria(action, categoria) {
    switch (action) {
      case "add":
        this.setState({ toggleAddCategory: true, toggleEditCategory: false });
        break;

      case "close":
        this.setState({ toggleAddCategory: false, toggleEditCategory: false });
        break;

      case "edit":
      default:
        this.setState({ categoria: categoria });
        this.setState({ toggleAddCategory: false, toggleEditCategory: true });
        break;
    }
    this.toggle();
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  toggleEntradas = (entradas = []) => {
    if (this.state.modalEntradas === true) {
      this.setState({
        modalEntradas: !this.state.modalEntradas,
        entradasCategoria: []
      });
    } else {
      this.setState({
        entradasCategoria: entradas, 
        modalEntradas: !this.state.modalEntradas
      });
    }
  };

  async componentDidMount() {
    this.setState({ loading: true });
    let url = "/graphql?query=%7B%0A%20%20entradas(%20where%3A%20%7B%20borrador_ne%3A%20true,%20area_admin%3A%20%22" +
      localStorage.getItem("admin") +
      "%22%20%7D)%7B%0A%20%20%20%20categoria%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D%0A"
      // console.log(url)
    await request(
      
      constantes("URL_API") + url,
      { method: "GET" }
    ).then(response => {
      if (Object.keys(response).includes("errors")) {
        localStorage.clear();
        this.props.history.push("/auth/login");
      } else {
        this.setState({
          sin_asignar: response.data.entradas.filter(
            entradas => entradas.categoria === null
          ).length
        });
      }
    });

    await request(constantes("URL_API") + "/categorias", { method: "GET" })
      .then(categoria => {
        console.log(categoria);
        this.setState({
          total: parseInt(categoria / this.state.limit) + 1
        });
      })
      .catch(err => {
        localStorage.clear();
        this.props.history.push("/auth/login");
      });

    await SearchListServerSide(
      "categorias",
      this.state.start,
      0,
      this.state.sort,
      this.state.area_admin,
      this.state.filter
    ).then(response => {
      if (!response) {
        localStorage.clear();
        this.props.history.push("/auth/login");
      } else {
        // console.log(response, this.state.limit)
        this.setState({
          categorias: response
        });
      }
    });

    this.setState({ loading: false });
  }

  render() {
    return (
      <div className="main_content listado">
        <div>
          <h3>Categorías</h3>
        </div>
        <div className="rounded_cont contents shadow">
          <div className="row">
            <div className="col">
              <SearchListFilterComponent
                setLoading={this.setLoading}
                loadFilter={this.loadFilter}
                model={this.state.model}
                start={this.state.start}
                limit={this.state.limit}
                sort={this.state.sort}
                field="nombre"
                placeholder="Nombre"
              />
              <ReactTable
                manual
                pages={this.state.total}
                defaultPageSize={this.state.limit}
                onFetchData={this.fetchData} // Request new data when things change
                data={this.state.categorias}
                loading={this.state.loading}
                columns={[
                  {
                    Header: "",
                    accessor: "image",
                    width: 120,
                    Cell: row => (
                      <div className="avatar shadow">
                        <img
                          src={
                            row.value != null
                              ? row.value.url
                              : "/img/userplaceholder.jpg"
                          }
                          alt=""
                        />
                      </div>
                    )
                  },
                  {
                    Header: "Nombre",
                    accessor: "nombre",
                    headerClassName: "category",
                    Cell: row => <div className="category">{row.value}</div>
                  },
                  {
                    Header: "Nº de entradas",
                    accessor: "entradas",
                    width: 160,
                    Cell: row => (
                      <div className="file-type" style={{cursor:"pointer"}} onClick={()=>this.toggleEntradas(row.value)}>{row.value.length}</div>
                    )
                  },
                  {
                    Header: "Editar",
                    accessor: "_id",
                    width: 100,
                    Cell: row => (
                      <div className="edit">
                        <span
                          data-id={row.value}
                          onClick={() =>
                            this.editCategoria("edit", row.original)
                          }
                        >
                          <i className="fas fa-pencil-alt" />
                        </span>
                      </div>
                    )
                  },
                  {
                    Header: "Eliminar",
                    accessor: "_id",
                    width: 100,
                    Cell: row => (
                      <div className="delete">
                        <span
                          onClick={() => {
                            if (
                              window.confirm(
                                "¿Estás seguro que quieres borrar esto?"
                              )
                            ) {
                              this.deleteCategory(row.value);
                            }
                          }}
                        >
                          {" "}
                          <i className="fas fa-trash-alt" />
                        </span>
                      </div>
                    )
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col sin-asignar">
            Entradas sin categoría asignada:{" "}
            <span>{this.state.sin_asignar}</span>
          </div>
        </div>

        <div className="add-category">
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className + "marcomodal"}
          >
            <ModalBody className="registroUser text-center">
              {this.state.toggleAddCategory === true &&
              this.state.toggleEditCategory === false ? (
                <ModalHeader>Añadir categoría</ModalHeader>
              ) : this.state.toggleAddCategory === false &&
                this.state.toggleEditCategory === true ? (
                <ModalHeader>Editar categoría</ModalHeader>
              ) : null}

              <CategoriaForm
                categorias={this.state.categorias}
                categoria={
                  this.state.toggleEditCategory ? this.state.categoria : ""
                }
                toggle={this.toggle}
                componentDidMount={this.componentDidMount}
              />
            </ModalBody>
          </Modal>
          <Modal
isOpen={this.state.modalEntradas}
toggle={this.toggleEntradas}
className={this.props.className + "marcomodal"}
>
<ModalBody className="registroUser text-center">
<ReactTable
               data={this.state.entradasCategoria}
                columns={[
                  
                  {
                    Header: "Título",
                    accessor: "title",
                    headerClassName: "title",
                    Cell: row => (
                      <div className="title">                        
                          {row.value}                        
                      </div>
                    )
                  },
                  {
                    Header: "Categoría",
                    accessor: "categoria.nombre",
                    width: 100,
                    Cell: row => <div className="type">{row.value}</div>
                  },
                  {
                    Header: "Tipo",
                    accessor: "type",
                    width: 80,
                    Cell: row => <div className="type">{row.value}</div>
                  },
                  {
                    Header: "Publicado",
                    accessor: "publish_date",
                    width: 150,
                    Cell: row => (
                      <div className="published_date">
                        {row.value === undefined ? (
                          "Pendiente"
                        ) : (
                          <TimeContentCard time={row.value} horas={true} />
                        )}
                      </div>
                    )
                  },
                  {
                    Header: "Estado",
                    accessor: "borrador",
                    width: 110,
                    Cell: row => (
                      <div className="estado">
                        {row.value === true ? (
                          <span className="draft">Borrador</span>
                        ) : row.original.publish_date >
                          new Date().toISOString() ? (
                          <span className="scheduled">Programada</span>
                        ) : (
                          "Publicada"
                        )}
                      </div>
                    )
                  }
                ]}
                defaultSorted={[
                  {
                    id: "publish_date",
                    desc: true
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />  

 
</ModalBody>
</Modal>

          <div className="col">
            <div className="col-3">
              <Button color="primary" onClick={() => this.editCategoria("add")}>
                Nueva categoría
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Categorias;