import React, {Component} from 'react';
import {
  Collapse,
  Navbar,
  Nav,
  NavItem
} from 'reactstrap';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import { Link } from 'react-router-dom';
import constantes from '../../utils/constantes';
import './styles.css'

class NavBarVertical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      menu_elem: ''
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  componentDidMount(){
    this.setState({ 
      menu_elem: constantes("menu_elem")[localStorage.getItem("admin")],
      menu_elem_contenidos: constantes("menu_elem_contenidos")[localStorage.getItem("admin")]
    });
  }

  render() {
      return(
          <Navbar light expand="sm row nav_side">
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav vertical className="nav_ul" navbar>
              {
                Object.keys(this.state.menu_elem).map( (key) => {
                    return(
                      <NavItem className ={"nav_li shadow " + this.state.menu_elem[key]} key={key}>
                        <Link to={"/"+this.state.menu_elem[key]} >{renderHTML(key)}</Link>
                      </NavItem>
                    );
                })
              }   

              {
                this.state.menu_elem_contenidos&&<span style={{marginTop: "15px", fontSize: "large", fontWeight: "bold"}}>Contenidos</span>
              }          

              {
                this.state.menu_elem_contenidos&&Object.keys(this.state.menu_elem_contenidos).map( (key) => {
                  return(
                    <NavItem className ={"nav_li shadow " + this.state.menu_elem_contenidos[key]} key={key}>
                      <Link to={"/"+this.state.menu_elem_contenidos[key]} >{renderHTML(key)}</Link>
                    </NavItem>
                  );
                })
              }
              </Nav>
            </Collapse>
          </Navbar>
      );
  }
}

Navbar.propTypes = {
  light: PropTypes.bool,
  dark: PropTypes.bool,
  fixed: PropTypes.string,
  color: PropTypes.string,
  role: PropTypes.string,
  expand: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
  // pass in custom element to use
}

export default NavBarVertical;
