import React, { Component } from "react";
import ReactTable from "react-table";
import request from "../../utils/request";
import constantes from "../../utils/constantes";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container
} from "reactstrap";
import auth from "../../utils/auth";
import moment from "moment";
import DateTimePicker from "react-datetime-picker";

export default class TablaParticipantes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: true,
      modalOpen: false,
      resetCurso: ""
    };
  }

  remember = async row => {
    if (row.original.token !== "") {
      let noti = {
        to: row.original.user.token,
        title:
          "Recordatorio de invitación para el Webinar " +
          this.state.webinar.name +
          ".",
        body: "Buenas " + row.original.user.name + ", acepte la convocatoria.",
        data: { acceptWebinar: this.state.webinar._id },
        sound: "default"
      };

      fetch("https://exp.host/--/api/v2/push/send", {
        method: "POST",
        body: JSON.stringify(noti),
        headers: {
          accept: "application/json",
          "accept-encoding": "gzip, deflate",
          "content-type": "application/json"
        }
      });
    }
  };

  toggle = () => {
    this.setState({
      resetCurso: "",
      modalOpen: !this.state.modalOpen
    });
  };

  showTable = () => {
    const webid = this.props.row.row._id;
    const courseID = this.props.courseID;
    this.setState({
      modalOpen: true
    });
    if (!courseID) {
      let graph =
        "/graphql?query=%7B%0A%20%20webinar(id%3A%22" +
        webid +
        "%22)%7B%0A%20%20%20%20_id%0A%20%20%20%20name%0A%20%20%20%20avwebinars%7B%0A%20%20%20%20%20%20state%0A%20%20%20%20%20%20user%7B%0A%20%20%20%20%20%20%20%20name%0A%20%20%20%20%20%20%20%20surname%0A%20%20%20%20%20%20%20%20email%0A%20%20%20%20%20%20%20%20token%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D";
      request(constantes("URL_API") + graph, {
        method: "GET"
      }).then(response => {
        this.setState({
          webinar: response.data.webinar,
          avwebinars: response.data.webinar.avwebinars,
          loading: false
        });
      });
    } else {
      const graph =
        "/graphql?query=%0A%0A%7B%0A%20%20curso(id%3A%22" +
        webid +
        "%22)%7B%0A%20%20%20%20_id%0A%20%20%20%20avancecursos%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20user%7B%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%20%20name%0A%20%20%20%20%20%20%20%20surname%0A%20%20%20%20%20%20%20%20email%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D%0A";
      request(constantes("URL_API") + graph, {
        method: "GET"
      }).then(response => {
        this.setState({
          avwebinars: response.data.curso.avancecursos,
          loading: false
        });
      });
    }
  };

  resetCurso = row => {
    // let graph =
    //   "/graphql?query=%0A%7B%0A%20%20avancecurso(id%3A%22" +
    //   row.value +
    //   "%22)%7B%0A%20%20%20%20_id%0A%20%20%20%20avance%0A%20%20%20%20name%0A%20%20%20%20estao%0A%20%20%20%20init_date%0A%20%20%20%20end_date%0A%20%20%20%20cursos%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20avancetests%7B%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%20%20chosenanswers%0A%20%20%20%20%20%20%20%20user%7B_id%7D%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%20%0A%7D";

    let graph =
      "/graphql?query=%0A%7B%0A%20%20avancecurso(id%3A%22" +
      row.value +
      "%22)%7B%0A%20%20%20%20_id%0A%20%20%20%20avance%0A%20%20%20%20name%0A%20%20%20%20estao%0A%20%20%20%20init_date%0A%20%20%20%20end_date%0A%20%20%20%20cursos%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20avancetests%7B%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%20%20chosenanswers%0A%20%20%20%20%20%20%20%20user%7B_id%7D%0A%20%20%20%20%20%20%20%20exam%7B%0A%20%20%20%20%20%20%20%20%20%20type%0A%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%20%0A%7D";
    request(constantes("URL_API") + graph, {
      method: "GET"
    }).then(response => {
      this.setState({
        resetCurso: response.data.avancecurso,
        row,
        init_date: response.data.avancecurso.init_date,
        end_date: response.data.avancecurso.end_date
      });
    });
  };

  deleteCurso = row => {
    const userid = row.original.user._id;
    let graph =
      "/graphql?query=%0A%0A%7B%0A%20%20avancecurso(id%3A%22" +
      row.value +
      "%22)%7B%0A%20%20%20%20_id%0A%20%20%20%20cursos%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20avancetests%7B%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%20%20user%7B%0A%20%20%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%20%0A%7D%0A";
    request(constantes("URL_API") + graph, {
      method: "GET"
    }).then(response => {
      const avancecursosId = response.data.avancecurso._id;
      const avancetestId = response.data.avancecurso.cursos.avancetests.length > 0 && response.data.avancecurso.cursos.avancetests.filter(
        x => x.user.id === userid
      )[0]._id;
      request(constantes("URL_API") + "/avancecursos/" + avancecursosId, {
        method: "DELETE"
      }).then(() =>
        request(constantes("URL_API") + "/avancetests/" + avancetestId, {
          method: "DELETE"
        }).then(() => this.toggle()).catch(e => console.log({ e }))
      ).catch(e => console.log({ e }));
    });
  };

  updateAvance = async () => {
    //         avcurso
    // status: null,
    // respuesta: "",
    // avtest
    //  "value": null,
    //     "answer": ""
    const userid = this.state.row.original.user._id;
    let { cursos, ...finalAvCurso } = this.state.resetCurso;
    let {
      user,
      ...finalAvTest
    } = this.state.resetCurso.cursos.avancetests.filter(
      x => x.user._id === userid
    )[0];
    const finalAvCursoId = finalAvCurso._id;
    const finalAvTestId = finalAvTest._id;
    delete finalAvCurso._id;
    delete finalAvTest.avance_id;
    // console.log({ finalAvCurso, finalAvTest })
    finalAvCurso.avance = finalAvCurso.avance.map(x => ({
      ...x,
      status: null,
      respuesta: ""
    }));
    finalAvCurso.estao = "activo";
    if (finalAvTest.exam.type === "test") {
      finalAvTest.chosenanswers = finalAvTest.chosenanswers.map(x => ({
        ...x,
        value: null,
        answer: ""
      }));
    } else {
      finalAvTest.chosenanswers = [];
    }
    delete finalAvTest.exam;
    finalAvTest.state = "activo";
    request(constantes("URL_API") + "/avancecursos/" + finalAvCursoId, {
      method: "PUT",
      body: finalAvCurso
    }).then(response => {
      request(constantes("URL_API") + "/avancetests/" + finalAvTestId, {
        method: "PUT",
        body: finalAvTest
      }).then(() => this.toggle());
    });
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggle}
          className={"modal-lg"}
        >
          <ModalHeader toggle={this.toggle}>Participantes</ModalHeader>

          <ModalBody>
            {this.state.resetCurso !== "" ? (
              <Container>
                <Row>
                  <p>
                    Va a reiniciar el progreso del curso{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.props.row.original.nombre}
                    </span>{" "}
                    para el usuario{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.state.row.original.user.email}
                    </span>
                  </p>
                </Row>
                <Row>
                  <Col>
                    Fecha Inicio
                    <DateTimePicker
                      className="rounded_cont calendar"
                      onChange={date => this.setState({ init_date: date })}
                      value={new Date(this.state.init_date)}
                    />
                  </Col>
                  <Col>
                    Fecha Fin
                    <DateTimePicker
                      className="rounded_cont calendar"
                      onChange={date => this.setState({ end_date: date })}
                      value={new Date(this.state.end_date)}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Button
                      className="btn-gradient-styleOk"
                      onClick={() => this.updateAvance()}
                    >
                      Reiniciar{/* <i className="fas fa-undo"></i>*/}
                    </Button>
                  </Col>

                  <Col>
                    <Button
                      className="btn-gradient-styleReset"
                      onClick={() => this.setState({ resetCurso: "" })}
                    >
                      Cancelar{/* <i className="fas fa-undo"></i>*/}
                    </Button>
                  </Col>
                </Row>
              </Container>
            ) : this.props.courseID ? (
              <ReactTable
                data={this.state.avwebinars}
                columns={[
                  {
                    Header: "Nombre",
                    accessor: "user",
                    Cell: row => (
                      <div className="name">
                        {row.value ? row.value.name : ""}
                      </div>
                    )
                  },
                  {
                    Header: "Apellidos",
                    accessor: "user",
                    Cell: row => (
                      <div className="surname">
                        {row.value ? row.value.surname : ""}
                      </div>
                    )
                  },
                  {
                    Header: "Email",
                    accessor: "user",
                    Cell: row => (
                      <div className="email">
                        {row.value ? row.value.email : ""}
                      </div>
                    )
                  },
                  {
                    Header: "Estado",
                    accessor: "state",
                    show: !this.props.courseID ? true : false,
                    Cell: row => (
                      <div className="state">
                        {row.value === "pendiente" ? (
                          <div onClick={() => this.remember(row)}>
                            Recordar<i className="far fa-share-square"></i>
                          </div>
                        ) : row.value === "aceptado" ? (
                          "Aceptado"
                        ) : (
                          <div onClick={() => this.remember(row)}>
                            Cancelado<i className="far fa-share-square"></i>
                          </div>
                        )}
                      </div>
                    )
                  },
                  {
                    Header: "Reinicio del Curso",
                    accessor: "_id",
                    Cell: row => (
                      <div className="reset">
                        <Button
                          className="btn-gradient-styleReset"
                          onClick={() => this.resetCurso(row)}
                        >
                          Reiniciar{/* <i className="fas fa-undo"></i>*/}
                        </Button>
                      </div>
                    )
                  },
                  {
                    Header: "Borrar Curso",
                    accessor: "_id",
                    Cell: row => (
                      <div className="reset">
                        <Button
                          className="btn-gradient-styleReset"
                          onClick={() => this.deleteCurso(row)}
                        >
                          Borrar
                        </Button>
                      </div>
                    )
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            ) : (
              <ReactTable
                data={this.state.avwebinars}
                columns={[
                  {
                    Header: "Nombre",
                    accessor: "user",
                    Cell: row => (
                      <div className="name">
                        {row.value ? row.value.name : ""}
                      </div>
                    )
                  },
                  {
                    Header: "Apellidos",
                    accessor: "user",
                    Cell: row => (
                      <div className="surname">
                        {row.value ? row.value.surname : ""}
                      </div>
                    )
                  },
                  {
                    Header: "Email",
                    accessor: "user",
                    Cell: row => (
                      <div className="email">
                        {row.value ? row.value.email : ""}
                      </div>
                    )
                  },
                  {
                    Header: "Estado",
                    accessor: "state",
                    show: !this.props.courseID ? true : false,
                    Cell: row => (
                      <div className="state">
                        {row.value === "pendiente" ? (
                          <div onClick={() => this.remember(row)}>
                            Recordar<i className="far fa-share-square"></i>
                          </div>
                        ) : row.value === "aceptado" ? (
                          "Aceptado"
                        ) : (
                          <div onClick={() => this.remember(row)}>
                            Cancelado<i className="far fa-share-square"></i>
                          </div>
                        )}
                      </div>
                    )
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            )}
          </ModalBody>
        </Modal>
        <div className="delete" onClick={this.showTable}>
          {this.props.row.value && this.props.row.value.length}
          <i className="fas fa-users"></i>
        </div>
      </div>
    );
  }
}
