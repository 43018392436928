import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Pagination from "react-js-pagination";
import CopyToClipboard from "react-copy-to-clipboard";
import "../Upload/styles.css";
import constantes from "../../utils/constantes";

const AudioGalery = ({
  media,
  dataMedia,
  copy_copied,
  setCopyCopied,
  copy_key,
  setCopyKey,
  exportFunc,
  toggleGaleria
}) => {
  return (
    <div className="col-3 mediacont" key={media._id}>
      <div
        id={media._id}
        className="audio"
        onClick={() => {
          exportFunc({
            ...dataMedia,
            url: media.url === "" ? constantes("placeholder") : media.url
          });
          toggleGaleria();
        }}
      >
        <audio
          preload="metadata"
          controls
          id={media.name}
          className="imageInput"
          src={media.url}
        >
          Your browser does not support the audio element.
        </audio>
        <span className="media-title">{media.name}</span>

        {dataMedia && dataMedia.url === media.url ? (
          <div className="color-selected-overlay" />
        ) : (
          ""
        )}
      </div>

      <CopyToClipboard
        text={media.url}
        onCopy={() => {
          setCopyCopied(true);
          setCopyKey(media._id);
        }}
      >
        <span
          className={
            copy_copied && copy_key === media._id ? "copyURL copied" : "copyURL"
          }
        ></span>
      </CopyToClipboard>
      <span className="copyURL-txt">
        {copy_copied && copy_key === media._id
          ? "Copiado al portapapeles"
          : media.url}
      </span>
    </div>
  );
};

const VideoGalery = ({
  media,
  dataMedia,
  copy_copied,
  setCopyCopied,
  copy_key,
  setCopyKey,
  exportFunc,
  toggleGaleria
}) => {
  return (
    <div className="col-3 mediacont" key={media._id}>
      <div
        id={media._id}
        className="video"
        onClick={() => {
          exportFunc({
            ...dataMedia,
            url: media.url === "" ? constantes("placeholder") : media.url
          });
          toggleGaleria();
        }}
      >
        <video
          preload="metadata"
          id={media._id}
          className="imageInput"
          controls
          src={media.url}
        >
          Your browser does not support the video tag.
        </video>
        <span className="media-title">{media.name}</span>
        {dataMedia && dataMedia.url === media.url ? (
          <div className="color-selected-overlay" />
        ) : (
          ""
        )}
      </div>

      <CopyToClipboard
        text={media.url}
        onCopy={() => {
          setCopyCopied(true);
          setCopyKey(media._id);
        }}
      >
        <span
          className={
            copy_copied && copy_key === media._id ? "copyURL copied" : "copyURL"
          }
        ></span>
      </CopyToClipboard>
      <span className="copyURL-txt">
        {copy_copied && copy_key === media._id
          ? "Copiado al portapapeles"
          : media.url}
      </span>
    </div>
  );
};

const ImageGalery = ({
  media,
  dataImg,
  copy_copied,
  setCopyCopied,
  copy_key,
  setCopyKey,
  exportFunc,
  toggleGaleria
}) => {
  return (
    <div className="col-3 mediacont" key={media._id}>
      <div
        id={media._id}
        onClick={() => {
          exportFunc({
            ...dataImg,
            url: media.url === "" ? constantes("placeholder") : media.url
          });
          toggleGaleria();
        }}
        style={{
          backgroundImage: `url('${
            media.url !== null ? media.url : "/img/userplaceholder.jpg"
          }')`
        }}
      >
        {dataImg && dataImg.url === media.url ? (
          <div className="color-selected-overlay" />
        ) : (
          ""
        )}
      </div>

      <CopyToClipboard
        text={media.url}
        onCopy={() => {
          setCopyCopied(true);
          setCopyKey(media._id);
        }}
      >
        <span
          className={
            copy_copied && copy_key === media._id ? "copyURL copied" : "copyURL"
          }
        ></span>
      </CopyToClipboard>
      <span className="copyURL-txt">
        {copy_copied && copy_key === media._id
          ? "Copiado al portapapeles"
          : media.url}
      </span>
    </div>
  );
};

const DocGalery = ({
  media,
  dataImg,
  copy_copied,
  setCopyCopied,
  copy_key,
  setCopyKey,
  exportFunc,
  toggleGaleria
}) => {
  return (
    <div className="col-3 mediacont" key={media._id}>
      <div
        id={media._id}
        onClick={() => {
          exportFunc({
            ...dataImg,
            url: media.url === "" ? constantes("placeholder") : media.url
          });
          toggleGaleria();
        }}
        style={{
          backgroundImage: `url('${constantes("placeholderDoc")}')`
        }}
      >
        {dataImg && dataImg.url === media.url ? (
          <div className="color-selected-overlay" />
        ) : (
          ""
        )}
      </div>

      <CopyToClipboard
        text={media.url}
        onCopy={() => {
          setCopyCopied(true);
          setCopyKey(media._id);
        }}
      >
        <span
          className={
            copy_copied && copy_key === media._id ? "copyURL copied" : "copyURL"
          }
        ></span>
      </CopyToClipboard>
      <span className="copyURL-txt">
        {copy_copied && copy_key === media._id
          ? "Copiado al portapapeles"
          : media.url}
      </span>
    </div>
  );
};

const ModalGalery = ({
  modalGaleria,
  toggleGaleria,
  exportFunc,
  activePage,
  limit,
  totalCount,
  handlePageChange,
  galeryData,
  mime,
  dataImg,
  dataMedia,
  copy_copied,
  setCopyCopied,
  copy_key,
  setCopyKey
}) => {
  return (
    <Modal
      isOpen={modalGaleria}
      toggle={toggleGaleria}
      className="container-fluid galeria"
    >
      <ModalHeader className="modalHeader" toggle={toggleGaleria}>
        Seleccione su medio
      </ModalHeader>

      <ModalBody className="text-center row">
        {galeryData &&
          galeryData.length > 0 &&
          galeryData.map((media, i) => {
            return mime === "image" ? (
              <ImageGalery
                key={i}
                media={media}
                dataImg={dataImg}
                copy_copied={copy_copied}
                setCopyCopied={copy_copied => setCopyCopied(copy_copied)}
                copy_key={copy_key}
                setCopyKey={copy_key => setCopyKey(copy_key)}
                exportFunc={media => exportFunc(media)}
                toggleGaleria={() => toggleGaleria(!modalGaleria)}
              />
            ) : mime === "video" ? (
              <VideoGalery
                key={i}
                media={media}
                dataMedia={dataMedia}
                copy_copied={copy_copied}
                setCopyCopied={copy_copied => setCopyCopied(copy_copied)}
                copy_key={copy_key}
                setCopyKey={copy_key => setCopyKey(copy_key)}
                exportFunc={media => exportFunc(media)}
                toggleGaleria={() => toggleGaleria(!modalGaleria)}
              />
            ) : mime === "audio" ? (
              <AudioGalery
                key={i}
                media={media}
                dataMedia={dataMedia}
                copy_copied={copy_copied}
                setCopyCopied={copy_copied => setCopyCopied(copy_copied)}
                copy_key={copy_key}
                setCopyKey={copy_key => setCopyKey(copy_key)}
                exportFunc={media => exportFunc(media)}
                toggleGaleria={() => toggleGaleria(!modalGaleria)}
              />
            ) : (
              <DocGalery
                key={i}
                media={media}
                dataMedia={dataMedia}
                copy_copied={copy_copied}
                setCopyCopied={copy_copied => setCopyCopied(copy_copied)}
                copy_key={copy_key}
                setCopyKey={copy_key => setCopyKey(copy_key)}
                exportFunc={media => exportFunc(media)}
                toggleGaleria={() => toggleGaleria(!modalGaleria)}
              />
            );
          })}
        <Pagination
          activePage={activePage}
          itemsCountPerPage={limit}
          totalItemsCount={totalCount}
          onChange={pageNumber => handlePageChange(pageNumber)}
        />
      </ModalBody>

      {/* <ModalFooter>
        <Button className="col" color="primary" onClick={() => saveGalery()}>
          Guardar
        </Button>{" "}
        <Button className="col" color="secondary" onClick={toggleGaleria}>
          Cancelar
        </Button>
      </ModalFooter> */}
    </Modal>
  );
};

export default ModalGalery;
