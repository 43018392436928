import React, { Component } from 'react';
import SearchListServerSide from '../../utils/SearchListServerSide';

class SearchListFilterComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            model: this.props.model,
            start: this.props.start,
            limit: this.props.limit,
            sort: this.props.sort,
            field: this.props.field,
            placeholder: this.props.placeholder,
            defaultfilter: this.props.defaultfilter,
            area_admin: localStorage.getItem("admin")
        };
        this.handleChange = this.handleChange.bind(this);
        this.submintFilter = this.submintFilter.bind(this);
        this.enviaCosas = this.enviaCosas.bind(this);
        this.recibeString = this.recibeString.bind(this);
    }
    recibeString(string) {
        if (typeof (this.props.recogeEstado) === "function") {
            this.props.recogeEstado(string)
        }
    }
    handleChange(value) {
        if (value.target.value.trim() === "") {
            this.enviaCosas()
        }
        this.setState({
            filter: [{
                id: this.state.field,
                value: value.target.value
            }],
            value: value.target.value
        })
    }

    enviaCosas() {
        if (this.state.value.trim() !== "") {
            if (this.props.field === "publish_date") {
                this.submintFilter();
                if (typeof (this.props.cambio) === "function") {
                    this.props.cambio("fecha")
                }
            } else {
                this.submintFilter();
                if (typeof (this.props.cambio) === "function") {
                    this.props.cambio("contenido")
                }
            }
        }

    }
    async submintFilter() {
        await this.props.setLoading(true);
        await SearchListServerSide(this.state.model, this.state.start, this.state.limit, this.state.sort, this.state.area_admin, this.state.filter, this.state.defaultfilter, this.recibeString, this.props.extraQuery)
            .then(response => {
                this.props.loadFilter(response, this.state.filter);
            });
    }
    render() {
        return <div style={{ display: 'flex', flexDirection: 'row' }}>
            <input type={this.props.field === "publish_date" ? "date" : "text"} placeholder={this.state.placeholder} value={this.state.value} onChange={(event) => this.handleChange(event)} className="form-control" />
            <div className="list-search" onClick={this.enviaCosas}>
            </div>
        </div>
    }
}

export default SearchListFilterComponent;