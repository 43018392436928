/* Fichero de constantes de la aplicación */

const constantes = param => {
  const URL_APP_PRO = "https://smartlearning.hub7s.com/forgotpassword/";
  const URL_APP_DEV = "http://localhost:3000/forgotpassword/";
  const URL_API_NEW_PRE = "http://18.202.169.123:1339";
  const URL_API_DEMO = "https://onceapi.hub7s.com";

  const mainlogo =
    "https://training-solvia.s3.eu-west-1.amazonaws.com/6dc5472ebe644b34874963b1805108ad.png";
  const placeholder =
    "https://training-solvia.s3.eu-west-1.amazonaws.com/b99009ffaebe4fc8a0a2d57beb1d7bbb.png";
  const splash = "";
  const placeholderDoc =
    "https://training-solvia.s3.eu-west-1.amazonaws.com/5f9d0a10ceeb41d8b39411da479e914b.png";
  const smalllogo =
    "https://training-solvia.s3.eu-west-1.amazonaws.com/ea6fcac7d8154728a7b2fbf7efc074d2.png";
  const markerprescriptor =
    "https://s3-eu-west-1.amazonaws.com/hub7/assets/preshub.png";
  const markerinmueble =
    "https://s3-eu-west-1.amazonaws.com/hub7/assets/casahub.png";
  const maincolor = "#2BB7EF";
  const secondarycolor = "#17A3DC";
  const version = "2.2.0";

  // API DE FOCUS 2
  //const URL_API_PRO = "https://api.cexsolvia.com";

  const area_class = [];
  area_class["content"] = "fa-file-alt";
  area_class["training"] = "fa-sitemap";
  area_class["focus"] = "fa-tachometer-alt";
  area_class["smartlearning"] = "fa-graduation-cap";

  const menu_elem = [];
  menu_elem["content"] = {
    Inicio: "content",
    "Nuevo Contenido": "new",
    Contenidos: "contents",
    Analítica: "contentanalytics",
    Comentarios: "comments",
    Medios: "media",
    //  'Usuarios': 'users',
    Categorias: "categorias",
    Notificaciones: "notification"
  };
  menu_elem["training"] = {
    Métricas: "training",
    Logros: "logros",
    Retos: "retos",
    Itinerarios: "itinerarios"
  };
  menu_elem["training"] = {
    Inicio: "metricas",
    Retos: "retos",
    Itinerarios: "itinerarios",
    Usuarios: "users"
  };
  menu_elem["focus"] = {
    // 'Inicio': 'focus', 'Usuarios': 'users'
  };

  menu_elem["smartlearning"] = {
    // 'Usuarios': 'users' ,
    Analítica: "analytics",
    Cursos: "listadocursos",
    // 'Medios': 'media',
    Notificaciones: "notification"
  };

  const menu_elem_contenidos = [];
  menu_elem_contenidos["smartlearning"] = {
    Contenidos: "contents",
    "Nuevo Contenido": "new",
    Exámenes: "exam",
    Preguntas: "questions",
    // Webinars: "convocatoria"
  };

  let data = {
    area_class,
    menu_elem,
    menu_elem_contenidos,

    // Current active URLs
    URL_API: URL_API_DEMO,
    URL_APP: URL_APP_DEV,
    ///as

    markerinmueble,
    markerprescriptor,
    mainlogo,
    smalllogo,
    splash: "",
    maincolor,
    secondarycolor,
    placeholder,
    placeholderDoc,
    version
  };
  return data[param];
};

export default constantes;
