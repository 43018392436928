import React, { Component } from "react";
import request from "../../utils/request";
import constantes from "../../utils/constantes";
import ReactTable from "react-table";
import Loading from "../../components/Loading";
import "./styles.css";
import ModalExamenEdit from "./ModalExamenEdit";
import ModalEncuestaEdit from "./ModalEncuestaEdit";

class TablaExamenes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  deleteExam = async id => {
    await request(constantes("URL_API") + "/exams/" + id, {
      method: "DELETE"
    })
      .then(() => this.props.componentDidMount())
      .catch(() => this.props.componentDidMount());
  };

  render() {
    return (
      <div>
        <Loading loading={this.state.loading} />
        <input
          type="text"
          placeholder="Nombre de examen"
          onChange={() => this.filtraExams}
          className="form-control list-search"
        />
        <ReactTable
          data={this.props.exams}
          columns={[
            // {
            // Header: '',
            // accessor: "_id",
            // width: 40,
            // Cell: row => (
            // <div className=""><input name={row.value} id={row.value} type="checkbox" onChange={() => this.gestionaBloque(row.value)} checked={this.state.selectBloque.includes(row.value) ? true : false} />
            // <div className="state"></div></div>
            // )
            // },
            {
              Header: "Tipo",
              accessor: "type",
              width: 85,
              Cell: row => <div className="name">{row.value}</div>
            },
            {
              Header: "Nombre de Examen",
              accessor: "name",
              //width: 120,
              Cell: row => <div className="name">{row.value}</div>
            },
            {
              Header: "Nº de preguntas",
              accessor: "questions.length",
              width: 80,
              headerClassName: "retos",
              Cell: row => <div className="retos">{row.value}</div>
            },
            {
              Header: "Editar",
              accessor: "_id",
              width: 80,
              Cell: row => (
                row.original.type==="test"?<div className="edit" style={{ color: "black" }}>
                  <ModalExamenEdit
                    componentDidMount={this.props.componentDidMount}
                    id={row.value}
                  />
                </div>:<ModalEncuestaEdit
                    reset={this.props.componentDidMount}
                    id={row.value}
                  />
              )
            },
            {
              Header: "Eliminar",
              accessor: "_id",
              width: 80,
              Cell: row => (
                <div className="delete">
                  <span
                    onClick={() => {
                      if (
                        window.confirm(
                          "¿Estás seguro que quieres eliminar este examen?"
                        )
                      ) {
                        this.deleteExam(row.value);
                      }
                    }}
                  >
                    {" "}
                    <i className="fas fa-trash-alt"></i>
                  </span>
                </div>
              )
            }
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </div>
    );
  }
}
export default TablaExamenes;
