import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import TimeContentCard from "../../components/TimeContentCard/";
import request from "../../utils/request";
import constantes from "../../utils/constantes";
import Loading from "../../components/Loading";
import SearchListFilterComponent from "../../components/SearchListFilterComponent";
import SearchListServerSide from "../../utils/SearchListServerSide";
import "./styles.css";
import "react-table/react-table.css";

class Contents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entradas: [],
      selectBloque: [],
      limit: 10,
      fechaRef: null,
      start: 0,
      total: 0,
      sort: [],
      filter: [],
      comentariosAsociados: [],
      model: "entradas",
      loading: false,
      recogeContenido: "",
      recogeFecha: "",
      defaultfilter: [],
      area_admin: localStorage.getItem('admin')
    };
    this.deleteContent = this.deleteContent.bind(this);
    this.deleteBloque = this.deleteBloque.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.loadFilter = this.loadFilter.bind(this);
    this.gestionaBloque = this.gestionaBloque.bind(this);
    this.recogeContenido = this.recogeContenido.bind(this);
    this.ultimoCambio = this.ultimoCambio.bind(this);
    this.recogeFecha = this.recogeFecha.bind(this);
    this.relleno = this.relleno.bind(this);
    this.actualizaEntradas = this.actualizaEntradas.bind(this);
  }

  getCountComentarios = () => {

  }

  fetchData(state, instance) {
    this.setState({
      start: instance.state.page * instance.state.pageSize,
      limit: instance.state.pageSize,
      sort: state.sorted,
      filter: this.state.filter
    });
  }
  relleno(string) {
    return string;
  }
  recogeFecha(string) {
    this.setState({ recogeFecha: string });
  }
  recogeContenido(string) {
    this.setState({ recogeContenido: string });
  }
  ultimoCambio(string) {
    this.setState({ ultimoCambio: string });
  }
  gestionaBloque = id => {
    let array = this.state.selectBloque;
    if (array.includes(id)) {
      //eliminar elemento del array
      var index = array.indexOf(id);
      if (index > -1) {
        array.splice(index, 1);
      }
    } else {
      //Añadir al array
      array.push(id);
    }
    this.setState({ selectBloque: array });
  };

  async deleteBloque(id) {
    await request(
      constantes("URL_API") +
      "/graphql?query=%7B%0A%20%20entrada%20(id%3A%20%22" +
      id +
      "%22)%20%7B%0A%20%20%20%20_id%0A%20%20%20%20comentarios%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D",
      { method: "GET" }
    ).then(entrada =>
      this.setState({
        comentariosAsociados: entrada.data.entrada.comentarios.map(
          element => element._id
        )
      })
    );
    this.state.comentariosAsociados.forEach(element => {
      request(constantes("URL_API") + "/comentarios/" + element, {
        method: "DELETE"
      }).catch(err => console.log(err));
    });
    request(constantes("URL_API") + "/entradas/" + id, { method: "DELETE" })
      .then(response => this.componentDidMount())
      .catch(err => console.log(err));
  }

  async deleteContent(id) {
    await request(
      constantes("URL_API") +
      "/graphql?query=%7B%0A%20%20entrada(id%3A%22" +
      id +
      "%22)%7B%0A%20%20%20%20_id%0A%20%20%20%20comentarios%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%20%20mynotifications%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D",
      { method: "GET" }
    ).then(entradas => {
      console.log(entradas);
      entradas.data.entrada.mynotifications &&
        entradas.data.entrada.mynotifications.length > 0 &&
        entradas.data.entrada.mynotifications.map(x => {
          request(constantes("URL_API") + "/mynotifications/" + x._id, {
            method: "DELETE"
          }).catch(err => console.log(err));
        });
      this.setState({
        comentariosAsociados: entradas.data.entrada.comentarios.map(
          element => element._id
        )
      });
    });
    this.state.comentariosAsociados.forEach(element => {
      request(constantes("URL_API") + "/comentario/" + element, {
        method: "DELETE"
      }).catch(err => console.log(err));
    });
    request(constantes("URL_API") + "/entradas/" + id, { method: "DELETE" })
      .then(response => {
        this.componentDidMount();
      })
      .catch(err => {
        this.componentDidMount();
        console.log(err);
      });
  }

  setLoading(loading) {
    this.setState({
      loading
    });
  }

  loadFilter(response, filter) {
    this.setState({
      entradas: response,
      loading: false,
      filter: filter
    });
  }
  actualizaEntradas(id) {
    const entradasMod = [...this.state.entradas]; //copia el estado
    entradasMod.find(e => e._id === id).destacada = !entradasMod.find(
      e => e._id === id
    ).destacada;
    this.setState({ entradas: entradasMod });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.start !== this.state.start ||
      prevState.limit !== this.state.limit ||
      prevState.sort !== this.state.sort ||
      prevState.filter !== this.state.filter
    ) {
      this.setState({
        loading: true
      });
      await SearchListServerSide(
        "entradas",
        0,
        0,
        this.state.sort,
        this.state.area_admin,
        this.state.filter,
        []
      ).then(response => {
        if (!response) {
          localStorage.clear();
          this.props.history.push("/auth/login");
        } else {
          this.setState({
            total: response.length
          });
        }
      });
      await SearchListServerSide(
        "entradas",
        this.state.start,
        this.state.limit,
        this.state.sort,
        this.state.area_admin,
        this.state.filter,
        "",
        this.relleno,
        this.state.ultimoCambio
          ? this.state.ultimoCambio === "contenido"
            ? this.state.recogeFecha
            : this.state.ultimoCambio === "fecha"
              ? this.state.recogeContenido
              : undefined
          : undefined
      ).then(response => {
        if (!response) {
          localStorage.clear();
          this.props.history.push("/auth/login");
        } else {
          this.setState({
            area_admin: localStorage.getItem("admin"),
            entradas: response,
            loading: false
          });
        }
      });
    }
  }

  async recargaEntradas() {
    await SearchListServerSide(
      "entradas",
      this.state.start,
      this.state.limit,
      this.state.sort,
      this.state.area_admin,
      this.state.filter,
      "",
      this.relleno,
      this.state.ultimoCambio
        ? this.state.ultimoCambio === "contenido"
          ? this.state.recogeFecha
          : this.state.ultimoCambio === "fecha"
            ? this.state.recogeContenido
            : undefined
        : undefined
    ).then(response =>
      this.setState({
        entradas: response
      })
    );
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });
    await SearchListServerSide(
      "entradas",
      0,
      0,
      this.state.sort,
      this.state.area_admin,
      this.state.filter,
      this.state.defaultfilter
    ).then(response => {
      if (!response) {
        localStorage.clear();
        this.props.history.push("/auth/login");
      } else {
        this.setState({
          total: response.length
        });
      }
    });
    await SearchListServerSide(
      "entradas",
      this.state.start,
      this.state.limit,
      this.state.sort,
      this.state.area_admin,
      this.state.filter,
      "",
      this.relleno,
      this.state.ultimoCambio
        ? this.state.ultimoCambio === "contenido"
          ? this.state.recogeFecha
          : this.state.ultimoCambio === "fecha"
            ? this.state.recogeContenido
            : undefined
        : undefined
    ).then(response => {
      if (!response) {
        localStorage.clear();
        this.props.history.push("/auth/login");
      } else {
        this.setState({
          entradas: response,
          loading: false
        });
      }
    });
    this.setState({
      loading: false
    });
  }

  render() {
    return (
      <div className="main_content listado">
        <div>
          <h3>Contenidos</h3>
        </div>
        <div className="rounded_cont contents shadow">
          <div className="row">
            <div className="col">
              {/* <input type="date"onChange={this.handleFechaRef}/> */}
              <div className="row">
                <Loading loading={this.state.loading} />
                {/* <div className="col-12"><SearchListFilterComponent setLoading={this.setLoading} loadFilter={this.loadFilter} model={this.state.model} start={this.state.start} limit={this.state.limit} sort={this.state.sort} field="title" placeholder="Buscar contenido" recogeEstado={this.recogeContenido} extraQuery={this.state.recogeFecha}/></div> */}
                <div className="col-9">
                  <SearchListFilterComponent
                    setLoading={this.setLoading}
                    loadFilter={this.loadFilter}
                    model={this.state.model}
                    start={this.state.start}
                    limit={this.state.limit}
                    sort={this.state.sort}
                    field="title"
                    placeholder="Buscar contenido"
                    recogeEstado={this.recogeContenido}
                    extraQuery={this.state.recogeFecha}
                    cambio={this.ultimoCambio}
                  />
                </div>
                <div className="col-3">
                  <SearchListFilterComponent
                    setLoading={this.setLoading}
                    loadFilter={this.loadFilter}
                    model={this.state.model}
                    start={this.state.start}
                    limit={this.state.limit}
                    sort={this.state.sort}
                    field="publish_date"
                    recogeEstado={this.recogeFecha}
                    extraQuery={this.state.recogeContenido}
                    cambio={this.ultimoCambio}
                  />
                </div>
              </div>
              <ReactTable
                manual
                pages={
                  parseInt(this.state.total / this.state.limit) <
                    this.state.total / this.state.limit
                    ? parseInt(this.state.total / this.state.limit) + 1
                    : this.state.total / this.state.limit
                }
                defaultPageSize={this.state.limit}
                onFetchData={this.fetchData}
                data={this.state.entradas}
                columns={[
                  {
                    Header: "",
                    accessor: "_id",
                    width: 60,
                    Cell: row => (
                      <div className="">
                        <input
                          type="checkbox"
                          className=""
                          onClick={() => this.gestionaBloque(row.value)}
                          defaultChecked={
                            this.state.selectBloque.includes(row.value)
                              ? true
                              : false
                          }
                        />
                        <div className="state" />
                      </div>
                    )
                  },
                  {
                    Header: "Título",
                    accessor: "title",
                    headerClassName: "title",
                    Cell: row => (
                      <div className="title">
                        <Link to={"/edit/" + row.original._id}>
                          {row.value}
                        </Link>
                      </div>
                    )
                  },
                  {
                    Header: "Categoría",
                    accessor: "categoria.nombre",
                    show:
                      this.state.area_admin === "smartlearning" ? false : true,
                    width: 100,
                    Cell: row => <div className="type">{row.value}</div>
                  },
                  {
                    Header: "Tipo",
                    accessor: "type",
                    width: 80,
                    Cell: row => <div className="type">{row.value}</div>
                  },
                  {
                    Header: "Publicado",
                    accessor: "publish_date",
                    show:
                      this.state.area_admin === "smartlearning" ? false : true,
                    width: 150,
                    Cell: row => (
                      <div className="published_date">
                        {row.value === undefined ? (
                          "Pendiente"
                        ) : (
                          <TimeContentCard time={row.value} horas={true} />
                        )}
                      </div>
                    )
                  },
                  {
                    Header: "Estado",
                    accessor: "borrador",
                    width: 110,
                    Cell: row => (
                      <div className="estado">
                        {row.value === true ? (
                          <span className="draft">Borrador</span>
                        ) : row.original.publish_date >
                          new Date().toISOString() ? (
                          <span className="scheduled">Programada</span>
                        ) : (
                          "Publicada"
                        )}
                      </div>
                    )
                  },
                  {
                    Header: "Destacada",
                    accessor: "destacada",
                    show:
                      this.state.area_admin === "smartlearning" ? false : true,
                    width: 75,
                    Cell: row => (
                      <div className="destacada">
                        <img
                          src={
                            row.value
                              ? "/img/ico/icon_destaca.png"
                              : "/img/ico/icon_nodestaca.png"
                          }
                          alt="Destacar"
                          onClick={() => {
                            request(
                              constantes("URL_API") +
                              "/entradas/" +
                              row.original._id,
                              { method: "PUT", body: { destacada: !row.value } }
                            )
                              .then(() =>
                                this.actualizaEntradas(row.original._id)
                              )
                              .catch(err => {
                                console.log(err);
                              });
                          }}
                        />
                      </div>
                    )
                  },
                  {
                    Header: "Editar",
                    accessor: "_id",
                    width: 75,
                    Cell: row => (
                      <Link to={"/edit/" + row.value} className="edit">
                        <i className="fas fa-pencil-alt" />
                      </Link>
                    )
                  },
                  {
                    Header: "Eliminar",
                    accessor: "_id",
                    width: 75,
                    Cell: row => (
                      <div className="delete">
                        <span
                          onClick={() => {
                            if (
                              window.confirm(
                                "¿Estás seguro que quieres borrar esto?"
                              )
                            ) {
                              this.deleteContent(row.value);
                            }
                          }}
                        >
                          {" "}
                          <i className="fas fa-trash-alt" />
                        </span>
                      </div>
                    )
                  }
                ]}
                defaultSorted={[
                  {
                    id: "publish_date",
                    desc: true
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
            {this.state.selectBloque.length > 0 ? (
              <div className="col-12">
                <div className="row menuBloque">
                  <div className="col">
                    {" "}
                    <span>
                      Has seleccionado {this.state.selectBloque.length}{" "}
                      {this.state.selectBloque.length < 2
                        ? "entrada"
                        : "entradas"}
                      .
                    </span>
                  </div>
                  <div className="col-1">
                    {" "}
                    <span
                      className="pointer"
                      onClick={() => {
                        if (
                          window.confirm(
                            "¿Estás seguro que quieres dejar visibles estas entradas?"
                          )
                        ) {
                          this.state.selectBloque.forEach(function (element) {
                            request(
                              constantes("URL_API") + "/entradas/" + element,
                              { method: "PUT", body: { borrador: false } }
                            );
                          });
                        }
                        this.setState({ selectBloque: [] });
                        this.componentDidMount();
                      }}
                    >
                      {" "}
                      <b className="verde">Publicado</b>
                    </span>
                  </div>
                  <div className="col-1">
                    {" "}
                    <span
                      className="pointer"
                      onClick={() => {
                        if (
                          window.confirm(
                            "¿Estás seguro que quieres convertir estas entradas en borradores?"
                          )
                        ) {
                          this.state.selectBloque.forEach(function (element) {
                            request(
                              constantes("URL_API") + "/entradas/" + element,
                              { method: "PUT", body: { borrador: true } }
                            );
                          });
                        }
                        this.setState({ selectBloque: [] });
                        this.componentDidMount();
                      }}
                    >
                      {" "}
                      <b className="rojo">Borrador</b>
                    </span>
                  </div>
                  <div className="col-1">
                    {" "}
                    <span
                      className="pointer"
                      onClick={() => {
                        if (
                          window.confirm(
                            "¿Estás seguro que quieres borrar estas entradas?"
                          )
                        ) {
                          this.state.selectBloque.forEach(element => {
                            this.deleteContent(element);
                          });
                        }
                        this.setState({ selectBloque: [] });
                        this.componentDidMount();
                      }}
                    >
                      <i className="fas fa-trash-alt" />
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Contents;
