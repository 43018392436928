import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Pagination from "react-js-pagination";
import constantes from "../../utils/constantes";
import Loading from "../Loading";
import request from "../../utils/request";
import "./styles.css";
import CopyToClipboard from "react-copy-to-clipboard";

class UploadMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueInput: "",
      medias: [],
      modalGaleria: false,
      id_image: "",
      modal: false,
      photo: this.props.currentImage
        ? this.props.currentImage.url
        : "/img/userplaceholder.jpg",
      alt: this.props.currentImage
        ? this.props.currentImage.alt
        : this.props.alt,
      image: "/img/userplaceholder.jpg",
      bigPhoto: "",
      toggleBigPhoto: false,
      selectedMedias: this.props.dataMedia
        ? [this.props.dataMedia._id, this.props.dataMedia.url]
        : [],
      selectedDestacada: this.props.dataImg
        ? [this.props.dataImg._id, this.props.dataImg.url]
        : [],
      avisoMedia: false,
      start: 0,
      limit: 8,
      activePage: 1,
      totalCount: 0,
      copy_copied: false,
      copy_key: "",
      loading: false
    };

    this.printGalery = this.printGalery.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleGaleria = this.toggleGaleria.bind(this);
    this.subirMedio = this.subirMedio.bind(this);
    this.seleccionaMedio = this.seleccionaMedio.bind(this);
    this.confirmaMedio = this.confirmaMedio.bind(this);
    this.compruebaGuardar = this.compruebaGuardar.bind(this);
    this.fin = this.fin.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getData = this.getData.bind(this);
    this.deleteSelectedMedia = this.deleteSelectedMedia.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.handleChangeAlt = this.handleChangeAlt.bind(this);
  }

  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,
      start: (pageNumber - 1) * this.state.limit
    });
  }
  setLoading(loading) {
    this.setState({
      loading
    });
  }
  seleccionaMedio(media) {
    if (this.props.mime === "image") {
      let selectedDestacada = [];
      selectedDestacada.push(media.url);
      selectedDestacada.push(this.state.alt);
      this.setState({ selectedDestacada });
    } else {
      let selectedMedias = [];
      selectedMedias.push(media.url);
      selectedMedias.push(this.state.alt);
      this.setState({ selectedMedias });
    }
  }

  deleteSelectedMedia() {
    if (this.props.mime === "image") {
      this.props.export("");
      this.setState({
        disableButton: false,
        id_image: "",
        subidoLocal: false,
        photo: "/img/userplaceholder.jpg",
        image: "/img/userplaceholder.jpg"
      });
    } else {
      this.props.export("");
      this.setState({
        disableButton: false,
        id_image: "",
        subidoLocal: false,
        photo: "/img/userplaceholder.jpg",
        image: "/img/userplaceholder.jpg"
      });
    }
  }

  subirMedio() {
    this.setState({ loading: true });
    let b = this.state.image;
    b.area_admin = localStorage.getItem("admin");
    b.zonamedia = "admin";
    this.setState({ image: b });
    const imgToUpload = new FormData();
    imgToUpload.append("area_admin", localStorage.getItem("admin"));
    imgToUpload.append("zonamedia", "admin");
    imgToUpload.append("files", this.state.image);

    request(
      constantes("URL_API") + "/upload",
      { method: "POST", body: imgToUpload },
      false
    )
      .then(response => {
        if (this.props.mime === "image") {
          let selectedDestacada = [];
          selectedDestacada.push(response[0].url);
          selectedDestacada.push(this.state.alt);
          this.setState(
            {
              selectedDestacada,
              loading: false
            },
            () => {
              this.props.export(this.state.selectedDestacada);
            }
          );
        } else {
          let selectedMedias = [];
          selectedMedias.push(response[0].url);
          selectedMedias.push(this.state.alt);
          this.setState(
            {
              id_image: response[0].id,
              photo: response[0].url,
              selectedMedias: selectedMedias,
              loading: false
            },
            () => {
              this.props.export(this.state.selectedMedias);
            }
          );
        }

        this.getData(true);
        // if (typeof this.props.recarga() === "function") {
        //   this.props.recarga();
        // }
        this.props.togglePadre();
      })

      .catch(err => console.log(err));
  }

  fin() {
    this.setState({
      avisoMedia: !this.state.avisoMedia
    });
  }

  onImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      this.setState({
        subidoLocal: true,
        disableButton: false,
        loading: true,
        image: event.target.files[0]
      });
      let reader = new FileReader();
      reader.onload = e => {
        this.setState({ photo: e.target.result });
        // this.setState({photo: e.target.result, loading:false});
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onMediaChange(event) {
    if (event.target.files && event.target.files[0]) {
      this.setState({
        mediaEdit: event.target.files[0]
      });
      let reader = new FileReader();
      reader.onload = e => {
        this.setState({ photo: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  toggleGaleria() {
    this.getData();
    this.setState({
      modalGaleria: !this.state.modalGaleria
    });
  }

  confirmaMedio() {
    if (this.props.mime === "image") {
      //console.log(this.state.selectedDestacada)
      this.props.export(this.state.selectedDestacada);
      this.setState({
        disableButton: true,
        subidoLocal: false,
        modalGaleria: !this.state.modalGaleria,
        id_image: this.state.selectedDestacada[0],
        photo: this.state.selectedDestacada[1]
      });
    } else {
      this.props.export(this.state.selectedMedias);
      this.setState({
        disableButton: true,
        subidoLocal: false,
        modalGaleria: !this.state.modalGaleria,
        id_image: this.state.selectedMedias[0],
        photo: this.state.selectedMedias[1]
      });
    }
  }

  compruebaGuardar() {
    if (this.state.image !== "/img/userplaceholder.jpg") {
      this.setState({ disableButton: true });
      this.subirMedio();
    } else {
      if (this.props.mime === "image") {
        if (this.state.selectedDestacada.lenght > 0) {
          this.setState({ disableButton: true });
          this.fin();
        }
      } else {
        if (this.state.selectedMedias.lenght > 0) {
          this.setState({ disableButton: true });
          this.fin();
        }
      }
    }
  }

  handleChangeAlt(value) {
    this.setState({
      alt: value.target.value
    });
  }

  printGalery() {
    return this.state.medias
      .filter(media => media.mime.includes(this.props.mime))
      .map(media => {
        return this.props.mime === "image" ? (
          <div className="col-3 mediacont" key={media._id}>
            <div
              id={media._id}
              onClick={() => this.seleccionaMedio(media)}
              style={{
                backgroundImage: `url('${
                  media.url !== null ? media.url : "/img/userplaceholder.jpg"
                }')`
              }}
            >
              {this.state.selectedDestacada.includes(media._id) ||
              (this.state.selectedMedias.includes(media._id) &&
                media._id.indexOf(this.state.exportMedia) > -1) ? (
                <div className="color-selected-overlay" />
              ) : (
                ""
              )}
            </div>

            <CopyToClipboard
              text={media.url}
              onCopy={() =>
                this.setState({ copy_copied: true, copy_key: media._id })
              }
            >
              <span
                className={
                  this.state.copy_copied && this.state.copy_key === media._id
                    ? "copyURL copied"
                    : "copyURL"
                }
              ></span>
            </CopyToClipboard>
            <span className="copyURL-txt">
              {this.state.copy_copied && this.state.copy_key === media._id
                ? "Copiado al portapapeles"
                : media.url}
            </span>
          </div>
        ) : this.props.mime === "video" ? (
          <div className="col-3 mediacont" key={media._id}>
            <div
              id={media._id}
              className="video"
              onClick={() => this.seleccionaMedio(media)}
            >
              <video
                preload="metadata"
                id={media._id}
                className="imageInput"
                controls
                src={media.url}
              >
                Your browser does not support the video tag.
              </video>
              <span className="media-title">{media.name}</span>
              {this.state.selectedMedias.includes(media._id) ? (
                <div className="color-selected-overlay media" />
              ) : (
                ""
              )}
            </div>

            <CopyToClipboard
              text={media.url}
              onCopy={() =>
                this.setState({ copy_copied: true, copy_key: media._id })
              }
            >
              <span
                className={
                  this.state.copy_copied && this.state.copy_key === media._id
                    ? "copyURL copied"
                    : "copyURL"
                }
              ></span>
            </CopyToClipboard>
            <span className="copyURL-txt">
              {this.state.copy_copied && this.state.copy_key === media._id
                ? "Copiado al portapapeles"
                : media.url}
            </span>
          </div>
        ) : (
          <div className="col-3 mediacont" key={media._id}>
            <div
              id={media._id}
              className="audio"
              onClick={() => this.seleccionaMedio(media)}
            >
              <audio
                preload="metadata"
                controls
                id={media.name}
                className="imageInput"
                src={media.url}
              >
                Your browser does not support the audio element.
              </audio>
              <span className="media-title">{media.name}</span>
              {this.state.selectedMedias.includes(media._id) ? (
                <div className="color-selected-overlay media" />
              ) : (
                ""
              )}
            </div>

            <CopyToClipboard
              text={media.url}
              onCopy={() =>
                this.setState({ copy_copied: true, copy_key: media._id })
              }
            >
              <span
                className={
                  this.state.copy_copied && this.state.copy_key === media._id
                    ? "copyURL copied"
                    : "copyURL"
                }
              ></span>
            </CopyToClipboard>
            <span className="copyURL-txt">
              {this.state.copy_copied && this.state.copy_key === media._id
                ? "Copiado al portapapeles"
                : media.url}
            </span>
          </div>
        );
      });
  }
  getData(bol) {
    if (bol) {
      request(
        constantes("URL_API") +
          "/upload/files?_limit=0&name_containss_ne=avatar&mime_containss=" +
          this.props.mime,
        { method: "GET" }
      )
        // request(constantes("URL_API") + "/upload/files?_limit=0&name_containss_ne=avatar&zonamedia=admin&mime_containss="+this.props.mime, { method: "GET" })
        .then(count => {
          this.setState({
            totalCount: count.length
          });
        })
        .catch(err => {
          localStorage.clear();
        });
    }
    var consulta =
      "/upload/files?_limit=" +
      this.state.limit +
      "&name_containss_ne=avatar&mime_containss=" +
      this.props.mime +
      "&_start=" +
      this.state.start +
      "&_sort=updatedAt:desc";
    // var consulta = "/upload/files?_limit="+this.state.limit+"&name_containss_ne=avatar&zonamedia=admin&area_admin="+localStorage.getItem("admin")+"&mime_containss="+this.props.mime+"&_start="+this.state.start+"&_sort=updatedAt:desc";

    request(constantes("URL_API") + consulta, { method: "GET" })
      .then(medias => {
        this.setState({
          medias: medias
        });
      })
      .catch(err => {
        localStorage.clear();
      });
    // console.log(this.state.totalCount);
  }

  componentDidMount() {
    console.log(this.props.currentImage);
    this.getData(true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentImage !== prevProps.currentImage) {
      this.setState({
        photo: this.props.currentImage
          ? this.props.currentImage.url
          : "/img/userplaceholder.jpg",
        alt: this.props.currentImage
          ? this.props.currentImage.alt
          : "/img/userplaceholder.jpg"
      });
    }
    if (this.state.photo !== prevState.photo) {
      // this.setState({loading: false});
      setTimeout(
        function() {
          this.setState({ loading: false });
        }.bind(this),
        10
      );
    }

    if (this.props.mime !== prevProps.mime) {
      this.getData(true);
      this.setState({ start: 0, activePage: 1 });
    }

    if (prevState.start !== this.state.start) {
      this.getData(true);
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col">
          <div className="container-fluid">
            <Loading loading={this.state.loading} />

            <ModalBody className="new-content-image">
              <div className="row">
                <div
                  className={
                    this.props.mime === "video"
                      ? "image video"
                      : this.props.mime === "audio"
                      ? "image audio"
                      : "image modal-image"
                  }
                >
                  {this.props.mime === "image" ? (
                    <img
                      id="target"
                      src={this.state.photo}
                      alt="Imagen Destacada"
                      className="imageInput"
                    />
                  ) : this.props.mime === "video" ? (
                    <video
                      key="videoPreview"
                      preload="metadata"
                      id="target"
                      className="imageInput"
                      width="320"
                      height="240"
                      controls
                      src={this.state.photo}
                    >
                      Your browser does not support the video tag.
                    </video>
                  ) : this.props.mime === "audio" ? (
                    <audio
                      controls
                      key="audioPreview"
                      preload="metadata"
                      id="target"
                      className="imageInput"
                      src={this.state.photo}
                    >
                      Your browser does not support the audio element.
                    </audio>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="rounded_cont shadow">
                <textarea
                  onChange={this.handleChangeAlt}
                  value={this.state.alt !== null ? this.state.alt : ""}
                  placeholder="Alt para la entrada"
                  className="form-control extract"
                />
              </div>

              {this.props.input ? (
                <div className="row carga-imagen">
                  <div className="col btn btn-light">
                    <i className="fas fa-cloud-upload-alt" />
                    <label color="success" htmlFor="upload_file">
                      Subir archivo
                    </label>
                    <input
                      type="file"
                      id="upload_file"
                      className="form-control d-none"
                      accept={this.props.typeFile}
                      name={
                        this.props.mime === "image" ? "group" : "group_media"
                      }
                      id={this.props.mime === "image" ? "group" : "group_media"}
                      onChange={this.onImageChange.bind(this)}
                    />
                  </div>
                </div>
              ) : null}

              {this.props.elige === "elige" ? (
                <div className="row carga-imagen">
                  <div
                    className="col btn btn-light"
                    onClick={this.toggleGaleria}
                  >
                    <i className="fas fa-images" />
                    <label color="success">Elegir de la galería</label>
                  </div>

                  <div
                    className="col text-center btn btn-light"
                    onClick={this.deleteSelectedMedia}
                  >
                    <i className="fas fa-trash-alt" />
                  </div>
                </div>
              ) : null}

              <div className="row">
                {this.state.avisoMedia === true ? (
                  <Button className="col-12" color="primary" onClick={this.fin}>
                    Archivo asociado
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </ModalBody>
            {this.state.subidoLocal || this.props.listamedia ? (
              <ModalFooter className="uploadmedia_buttons_modalfooter">
                <Button
                  color="primary"
                  disabled={this.state.disableButton}
                  onClick={this.compruebaGuardar}
                >
                  {this.state.disableButton === true
                    ? "Guardado"
                    : this.props.mime === "image"
                    ? this.props.subeConfirma === true
                      ? "Subir imagen"
                      : "Guardar imagen"
                    : this.props.mime === "video"
                    ? this.props.subeConfirma === true
                      ? "Subir video"
                      : "Guardar video"
                    : this.props.mime === "audio"
                    ? this.props.subeConfirma === true
                      ? "Subir audio"
                      : "Guardar audio"
                    : this.props.subeConfirma === true
                    ? "Subir archivo"
                    : "Guardar archivo"}
                </Button>
                {this.props.cancel ? (
                  <Button color="secondary" onClick={this.props.toggle}>
                    Cancelar
                  </Button>
                ) : null}
              </ModalFooter>
            ) : null}
          </div>

          <Modal
            isOpen={this.state.modalGaleria}
            toggle={this.toggleGaleria}
            className="container-fluid galeria"
          >
            <ModalHeader className="modalHeader" toggle={this.toggleGaleria}>
              Elija su medio
            </ModalHeader>

            <Loading loading={this.state.loading} />

            <ModalBody className="text-center row">
              {this.printGalery()}

              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.limit}
                totalItemsCount={this.state.totalCount}
                onChange={pageNumber => this.handlePageChange(pageNumber)}
              />
            </ModalBody>

            <ModalFooter>
              <Button
                className="col"
                color="primary"
                onClick={this.confirmaMedio}
              >
                Guardar
              </Button>{" "}
              <Button
                className="col"
                color="secondary"
                onClick={this.toggleGaleria}
              >
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default UploadMedia;
