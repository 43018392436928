import React, { Component } from "react";
import UploadMedia from "../../components/UploadMedia";
import CursoSelectRol from "../../components/CursoSelectRol";
import { Row, Col, Input, Button, Modal, ModalBody, Form } from "reactstrap";
import constantes from "../../utils/constantes";
import auth from "../../utils/auth";
import request from "../../utils/request";
import ReactTable from "react-table";
import "./styles.css";

export default class Cursos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        name: "",
        surname: "",
        email: "",
        canal: [],
        rol: []
      },
      contenido: [],
      contenido2: [],
      contenidosCurso: [],
      imageSelected: [],
      examenes: [],
      entradasId: [],
      selectBloque: [],
      selectBloqueObligatorio: [],
      selectedContent: [],
      listaAvances: [],
      examenCurso: "",
      modalContent: false,
      hayContent: false,
      alertCurso: false,
      selectedsolviacanal: [],
      selectedsolviarol: [],
      selectedstores: [],
      data: {
        solviacanals: [],
        solviarols: [],
        stores: []
      }
    };
  }

  componentDidMount = async () => {
    this.props.toggleLoading();
    if (this.props.isEditing === true) {
      let idCursoEdit = this.props.idCursoEdited;
      const uriCursoEdit = "/cursos/" + idCursoEdit;
      const token = auth.getToken();
      await fetch(constantes("URL_API") + uriCursoEdit, {
        method: "GET",
        body: JSON.stringify(),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
        .then(data => data.json())
        .then(response => {
          let cursoEdit = response;
          console.log(cursoEdit);
          this.setState({
            hayContent: true,
            selectBloque: cursoEdit.entrada,
            contenidosCurso: cursoEdit.entrada,
            examenCurso: cursoEdit.exam ? cursoEdit.exam._id : null,
            photo: cursoEdit.imagen
              ? cursoEdit.imagen
              : { url: constantes("placeholder"), alt: "" },
            imageSelected: cursoEdit.imagen
              ? Object.values(cursoEdit.imagen)
              : [constantes("placeholder"), ""]
            // photo:
            //   cursoEdit.imagen === null
            //     ? "img/userplaceholder.jpg"
            //     : cursoEdit.imagen.url,
            // imageSelected:
            //   cursoEdit.imagen === null
            //     ? "img/userplaceholder.jpg"
            //     : [cursoEdit.imagen._id, cursoEdit.imagen.url]
          });

          this.nameInput.value = cursoEdit.nombre;
          this.aprobadoInput.value = cursoEdit.porcentaje_total
            ? cursoEdit.porcentaje_total
            : 70;
          this.descriptionInput.value = cursoEdit.descripcion;
          // this.obligatorioInput.checked = cursoEdit.obligatorio;
        })
        .catch(err => console.log(err));
    }
    const token = auth.getToken();
    const uriE = "/entradas?_limit=0&area_admin=smartlearning";
    await fetch(constantes("URL_API") + uriE, {
      method: "GET",
      body: JSON.stringify(),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    })
      .then(data => data.json())
      .then(response => {
        let contentList = response;
        this.setState({
          contenido: contentList,
          contenido2: contentList
        });
      })
      .catch(err => console.log(err));

    const uriEx = "/exams";
    await fetch(constantes("URL_API") + uriEx, {
      method: "GET",
      body: JSON.stringify(),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    })
      .then(data => data.json())
      .then(response => {
        let exList = response;
        exList.forEach(ex => {
          if (ex.examenfinal) {
            this.setState({
              examenes: [...this.state.examenes, ex]
            });
          }
        });
      })
      .catch(err => console.log(err));

    await fetch(
      constantes("URL_API") +
      "/graphql?query=%7B%0D%0A%20solviarols(limit%3A0)%7B%0D%0A%20%20%09_id%0D%0A%20%20name%0D%0A%09%7D%0D%0A%20%20solviacanals(limit%3A0)%7B%0D%0A%20%20%20%20_id%0D%0A%20%20%20%20name%0D%0A%20%20%7D%0D%0A%20%20stores(limit%3A0)%7B%0D%0A%20%20%20%20_id%0D%0A%20%20%20%20name%0D%0A%20%20%7D%0D%0A%7D",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    )
      .then(data => data.json())
      .then(response =>
        this.setState({
          data: {
            solviacanals: response.data.solviacanals.map(solviacanal => {
              return {
                value: solviacanal._id,
                label: solviacanal.name
              };
            }),
            solviarols: response.data.solviarols.map(solviarol => {
              return {
                value: solviarol._id,
                label: solviarol.name
              };
            }),
            stores: response.data.stores.map(store => {
              return {
                value: store._id,
                label: store.name
              };
            })
          }
        })
      );
    this.props.toggleLoading();
  };

  limpiezaEstados = () => {
    this.setState({
      contenido: [],
      contenidosCurso: [],
      // certificados:[],
      imageSelected: [],
      examenes: [],
      entradasId: [],
      selectedContent: [],
      listaAvances: [],
      hayContent: false,
      alertCurso: false
    });
    this.nameInput.value = "";
    this.aprobadoInput.value = 70;
    this.descriptionInput.value = "";
    // this.obligatorioInput.checked = false;
  };

  handleChangeCanal = async selectedOption => {
    this.setState({ selectedsolviacanal: selectedOption });
  };

  handleChangeRol = async selectedOption => {
    await this.setState({ selectedsolviarol: selectedOption });
  };

  handleChangeStore = async selectedOption => {
    await this.setState({ selectedstores: selectedOption });
  };

  toggleAddContent = () => {
    this.setState({
      modalContent: !this.state.modalContent,
      contenidosCurso: []
    });
  };
  portadaImage = async array => {
    await this.setState({
      imageSelected: array
    });
  };

  toggleIMG = () => {
    this.setState({ modalIMG: !this.state.modalIMG });
  };

  gestionaBloque = id => {
    let array = this.state.selectBloque;
    if (array.includes(id)) {
      //eliminar elemento del array
      var index = array.indexOf(id);
      if (index > -1) {
        array.splice(index, 1);
      }
    } else {
      //Añadir al array
      array.push(id);
    }
    this.setState({ selectBloque: array });
  };

  gestionaBloqueObligatorio = id => {
    let array = this.state.selectBloqueObligatorio;
    if (array.includes(id)) {
      //eliminar elemento del array
      var index = array.indexOf(id);
      if (index > -1) {
        array.splice(index, 1);
      }
    } else {
      //Añadir al array
      array.push(id);
    }
    this.setState({ selectBloqueObligatorio: array });
  };

  onRemove = index => {
    const items = this.state.selectBloque;
    items.splice(index, 1);
    this.setState({
      selectBloque: items
    });
  };

  filtrarContenidos = async () => {
    for (let i = 0; i < this.state.selectBloque.length; i++) {
      const contenidos = this.state.contenido.filter(cont => {
        return cont._id === this.state.selectBloque[i];
      });

      await this.setState({
        contenidosCurso: [...this.state.contenidosCurso, ...contenidos]
      });
    }
    this.setState({
      hayContent: true,
      modalContent: !this.state.modalContent
    });
  };

  filtrarEntradas = value => {
    if (value.target.value !== "" || value.target.value !== undefined) {
      let dataFiltro = this.state.contenido2.filter(array =>
        array.title.includes(value.target.value)
      );
      this.setState({ contenido: dataFiltro });
    } else {
      this.setState({ contenido: this.state.contenido2 });
    }
  };

  setEntradasId = async () => {
    let arrayEnt = this.state.contenidosCurso.map(ent => ent._id);

    await this.setState({
      entradasId: [...this.state.entradasId, ...arrayEnt]
    });
  };

  createCourse = async event => {
    // this.nameInput.value === "" ||
    // this.state.examenCurso === "" ||
    // this.aprobadoInput.value === "" ||
    // this.state.selectBloque.length === 0
    await this.setEntradasId();
    const complete =
      this.nameInput.value !== "" &&
        this.state.examenCurso !== "" &&
        this.aprobadoInput.value !== "" &&
        this.state.selectBloque.length !== 0
        ? true
        : false;
    let cursos = {
      nombre: this.nameInput.value,
      descripcion: this.descriptionInput.value,
      complete,
      porcentaje_total: this.aprobadoInput.value,
      imagen: {
        url: this.state.imageSelected[0],
        alt: this.state.imageSelected[1]
      },
      entrada: this.state.selectBloque.map(x => x._id),
      exam: this.state.examenCurso,
      area_admin: localStorage.getItem("admin"),
      stores: this.state.selectedstores.map(x => x.value),
      solviarols: this.state.selectedsolviarol.map(x => x.value),
      solviacanals: this.state.selectedsolviacanal.map(x => x.value),
      obligatorias: this.state.selectBloqueObligatorio.map(x => {
        return {
          _id: x
        };
      })
    };

    if (!this.props.isEditing) {
      const uriCursos = "/cursos";
      const method = "POST";
      const token = auth.getToken();
      fetch(constantes("URL_API") + uriCursos, {
        method,
        body: JSON.stringify(cursos),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
        .then(data => data.json())
        .then(async response => {
          this.limpiezaEstados();
          await this.props.reloadLista();
          this.props.closeModal();
          if (response.statusCode === 400) throw new Error(response.message);
        })
        .catch(err => console.log("error:" + err));
    } else {
      // cursos.imagen={photo:this.state.photo}
      // console.log(cursos)
      request(constantes("URL_API") + "/cursos/" + this.props.idCursoEdited, {
        method: "PUT",
        body: cursos
      })
        .then(async () => {
          this.limpiezaEstados();
          await this.props.reloadLista();
          this.props.closeModal();
        })
        .catch(err => console.log(err));
    }

    if (this.state.selectBloqueObligatorio !== []) {
      for (let i = 0; i < this.state.selectBloqueObligatorio.length; i++) {
        let body = {
          obligatorio: true
        };
        request(
          constantes("URL_API") +
          "/entradas/" +
          this.state.selectBloqueObligatorio[i],
          {
            method: "PUT",
            body
          }
        ).catch(err => console.log(err));
      }
    }
  };

  handleChangeExam(event) {
    this.setState({ examenCurso: event.target.value });
  }

  render() {
    return (
      <div>
        <Form>
          <Row className="newCourse-divContainer">
            <Col>
              <Input
                innerRef={name => (this.nameInput = name)}
                className="newCourse-input"
                placeholder="Nombre del curso"
              ></Input>
              <Input
                type="textarea"
                innerRef={description => (this.descriptionInput = description)}
                className="newCourse-textarea"
                placeholder="Descripción"
              ></Input>
            </Col>
            <div className="newCourse-colImage">
              <Col>
                <UploadMedia
                  input
                  alt="Alt o descripción de la Imagen"
                  dataImg={this.state.dataImg}
                  dataMedia={this.state.dataMedia}
                  currentImage={this.state.photo}
                  modal={this.state.modal}
                  toggle={this.toggleIMG}
                  typeFile="image/*"
                  mime="image"
                  togglePadre={this.toggleIMG}
                  export={this.portadaImage}
                  elige="elige"
                  subeConfirma={this.state.zonamedia}
                />
              </Col>
            </div>
          </Row>
          <Row>
            <Col>
              <Input
                className="newCourse-Select"
                onChange={event => this.handleChangeExam(event)}
                value={this.state.examenCurso}
                type="select"
                name="examenes"
                id="examenes"
              >
                <option value="">Examen final...</option>
                {this.state.examenes.map((exam, i) => (
                  <option value={exam._id} key={i} defaultValue={exam.name}>
                    {exam.name}
                  </option>
                ))}
              </Input>
            </Col>
            <Col style={{ paddingTop: "25px" }}>
              <span className="span-porcentaje">
                Porcentaje de acierto para aprobar el examen:
              </span>
            </Col>
            <Col xs={2}>
              <Input
                type="number"
                width={10}
                defaultValue={70}
                innerRef={nota => (this.aprobadoInput = nota)}
                className="newCourse-Select2"
                placeholder="Porcentaje necesario para aprobar"
              ></Input>
            </Col>
            {/* <Col>
                    <Input className="newCourse-Select2" innerRef={(certificado) => this.certificadoInput = certificado} type="select" name="certificado" id="certificado">
                        <option value = "">Certificado...</option>
                        {this.state.certificados.map((cert, i) =>(
                            <option value={cert._id} key={i} defaultValue={cert.name}>{cert.name}</option>
                            ))}
                    </Input>
                </Col> */}
          </Row>
          <CursoSelectRol
            data={this.state.data}
            handleChangeCanal={this.handleChangeCanal}
            handleChangeRol={this.handleChangeRol}
            handleChangeStore={this.handleChangeStore}
          />
          {this.state.hayContent ? (
            <Row>
              <Button className="boton-add" onClick={this.toggleAddContent}>
                Añadir Contenido
              </Button>
              <div className="main_content listado newCourse-tableContent">
                <div className="rounded_cont contents shadow">
                  <ReactTable
                    data={this.state.selectBloque}
                    columns={[
                      {
                        Header: "Nombre",
                        accessor: "title",
                        Cell: row => <div>{row.value}</div>
                      },
                      {
                        Header: "Tipo",
                        accessor: "type",
                        Cell: row => <div>{row.value}</div>
                      },
                      {
                        Header: "Descripción",
                        accessor: "content",
                        Cell: row => <div>{row.value}</div>
                      },
                      {
                        Header: "Obligatorio",
                        accessor: "_id",
                        Cell: row => (
                          <div>
                            {(this.state.selectBloque && this.state.selectBloque.length > 0 && this.state.selectBloque[row.index].type ===
                              "Document") ? (
                              <input
                                name={row.value}
                                id={row.value}
                                type="checkbox"
                                disabled
                              />
                            ) : (
                              <input
                                name={row.value}
                                id={row.value}
                                type="checkbox"
                                onChange={() =>
                                  this.gestionaBloqueObligatorio(row.value)
                                }
                                checked={
                                  this.state.selectBloqueObligatorio.includes(
                                    row.value
                                  )
                                    ? true
                                    : false
                                }
                              />
                            )}
                          </div>
                        )
                      },
                      {
                        Header: "",
                        accessor: "_id",
                        Cell: row => (
                          <div className="name">
                            <span
                              onClick={() => {
                                this.onRemove(row.index);
                              }}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </span>
                          </div>
                        )
                      }
                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                  />
                </div>
              </div>
            </Row>
          ) : (
            <Row className="newCourse-content">
              <Col className="newCourse-emptyContent">
                <label>No hay contenidos asociados todavía</label>
                <Button className="boton-add2" onClick={this.toggleAddContent}>
                  Añadir Contenido
                </Button>
              </Col>
            </Row>
          )}
          {this.state.alertCurso && (
            <Row>
              <span className="spanAlertCurso">
                Faltan campos por rellenar o no has seleccionado ningún
                contenido
              </span>
            </Row>
          )}
          <Row>
            <Col>
              <Button
                type="button"
                className="boton-guardar"
                onClick={this.createCourse}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
        <Modal
          isOpen={this.state.modalContent}
          toggle={this.toggleAddContent}
          className={this.props.className + "marcomodal"}
        >
          <ModalBody className="registroUser">
            <div className="main_content listado">
              <div className="rounded_cont contents shadow">
                <input
                  type="text"
                  placeholder="Nombre del Contenido"
                  onChange={this.filtrarEntradas}
                  className="form-control list-search"
                />
                <ReactTable
                  data={this.state.contenido}
                  columns={[
                    {
                      Header: "",
                      accessor: "_id",
                      width: 40,
                      Cell: row => (
                        <div className="">
                          <input
                            name={row.value}
                            id={row.value}
                            type="checkbox"
                            onChange={() => this.gestionaBloque(row.original)}
                            checked={
                              this.state.selectBloque.includes(row.original)
                                ? true
                                : false
                            }
                          />
                          <div className="state"></div>
                        </div>
                      )
                    },
                    {
                      Header: "Nombre de contenido",
                      accessor: "title",
                      Cell: row => <div className="name">{row.value}</div>
                    },
                    {
                      Header: "Tipo de contenido",
                      accessor: "type",
                      Cell: row => <div className="retos">{row.value}</div>
                    },
                    {
                      Header: "Descripción",
                      accessor: "content",
                      Cell: row => <div className="name">{row.value}</div>
                    }
                    // ,
                    // {
                    //     Header: "Previsualizar",
                    //     accessor: "_id",
                    //     Cell: row => (
                    //     <div className = "name">
                    //         <Link to={"/edit/" + row.value}><i className="fas fa-eye"></i></Link>
                    //     </div>
                    //     )
                    // }
                  ]}
                  defaultPageSize={10}
                  className="-striped -highlight"
                />
              </div>
              <Button
                className="boton-seleccionar"
                onClick={this.filtrarContenidos}
              >
                Añadir
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
