import React, {Component} from 'react';
import ReactTable from "react-table";
import request from '../../utils/request';
import './styles.css';
import constantes from '../../utils/constantes';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, FormText  } from 'reactstrap';
import SearchListFilterComponent from '../../components/SearchListFilterComponent';
import SearchListServerSide from '../../utils/SearchListServerSide';
import Mailto from 'react-protected-mailto';


class Stores extends Component {
  constructor(props){
    super(props);
    this.state = {
        tiendas: [],
        DIT: ["Centro", "Levante","Noreste", "Sur"],
        selectBloque: [],
        toggleOjo: false,
        limit: 10,
        start: 0,
        total: 0,
        sort: [{id:"publish_date",desc:"true"}],
        loading: false,
        model: "store",
        filter: [],
        modal: false,
        area_admin: true,
        idEditando:'',        
        photo: '/img/userplaceholder.jpg'
    };
    //this.printStores = this.printStores.bind(this);
    this.deleteStore = this.deleteStore.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.gestionaBloque = this.gestionaBloque.bind(this);
    this.setLoading = this.setLoading.bind(this); 
    this.loadFilter = this.loadFilter.bind(this); 
    this.editStore = this.editStore.bind(this); 
    this.toggle = this.toggle.bind(this);  
    this.handleNew = this.handleNew.bind(this); 
    this.printDIT = this.printDIT.bind(this); 
    this.addStore = this.addStore.bind(this); 
    this.handleNewavatar = this.handleNewavatar.bind(this);
    this.handlechangeAvatar = this.handlechangeAvatar.bind(this);
    this.storeAvatar = this.storeAvatar.bind(this);
    this.eliminaAvatar = this.eliminaAvatar.bind(this);
  }
  printDIT(){ 
    return this.state.DIT.map((DIT) => {
                    return(
                      <option value={DIT} key={DIT}>{DIT}</option>  
                    );
                  });
  }
  limpiezaEstados(){
    this.setState({
      editStoreData: "",
      idEditando: "",
      storeEmail: "",
      storeName: "",
      storeDIT: "",
      storeCP: "",
      storeAddress:"" ,        
      storePoblacion:"" ,
      storeProvincia: "",
      storeTelf1: "",
      storeTelf2: "",
      storeTipo: "",
      storeIdSolvia: "",
      storeLatitud: "",
      storeLongitud:"" ,
      photo: '/img/userplaceholder.jpg'
    })
  }
  addStore(){ 
        let store = {
        area_admin: localStorage.getItem("admin"),
        email: this.state.storeEmail,
        name: this.state.storeName,
        dit:this.state.storeDIT,
        cp: this.state.storeCP,
        address: this.state.storeAddress,        
        poblacion: this.state.storePoblacion,
        provincia: this.state.storeProvincia,
        mainphone: this.state.storeTelf1,
        auxphone: this.state.storeTelf2,
        tipostore: this.state.storeTipo,
        idsolvia: this.state.storeIdSolvia,
        latitud: this.state.storeLatitud,
        longitud: this.state.storeLongitud
      }
      console.log(store)
      request(constantes("URL_API") + "/store", {
        method: "POST",
        body: store,
        
      
      }).then( response => {
        console.log(response._id)
        this.handleNewavatar("store", response._id);                 
        this.limpiezaEstados();
        this.componentDidMount();
      }).then(() => this.setState({
      modal: !this.state.modal
      }))
    }
  editStore(id){
      let store = {
        area_admin: localStorage.getItem("admin"),
        email: this.state.storeEmail,
        name: this.state.storeName,
        dit:this.state.storeDIT,
        cp: this.state.storeCP,
        address: this.state.storeAddress,        
        poblacion: this.state.storePoblacion,
        provincia: this.state.storeProvincia,
        mainphone: this.state.storeTelf1,
        auxphone: this.state.storeTelf2,
        tipostore: this.state.storeTipo,
        idsolvia: this.state.storeIdSolvia,
        latitud: this.state.storeLatitud,
        longitud: this.state.storeLongitud
      }
      request(constantes("URL_API") + "/store/"+id, {
          method: "PUT",
          body: store,
      }).then( response => {
        this.handlechangeAvatar("store", response._id);  
        this.limpiezaEstados();
        this.componentDidMount();
      }).then(() => this.setState({
        modal: !this.state.modal
      }))
    } 
  handleNew(value, concepto){
    let estado = "store"+concepto;
    this.setState({
        [estado]: value.target.value
    });
  }
  toggle(id = "") {
    //si está abierto lo cerramos
    if (this.state.modal===true)
    {this.limpiezaEstados();
      this.setState({
        modal: !this.state.modal
        
      });} else {
        //compruebo que tenga ID válido
        if (id!==""){
          //Si tiene ID, cargamos la data del ID y abrimos.        
          request(constantes("URL_API") + "/graphql?query=%7B%0A%20%20store(id%3A%22"+id+"%22)%7B%0A%20%20%20%20_id%0A%20%20%20%20name%20%20%0A%20%20%20%20cp%0A%20%20%20%20tipostore%0A%20%20%20%20provincia%20%20%20%20%0A%20%20%20%20dit%0A%20%20%20%20address%0A%20%20%20%20mainphone%0A%20%20%20%20longitud%0A%20%20%20%20idsolvia%0A%20%20%20%20latitud%0A%20%20%20%20email%0A%20%20%20%20poblacion%0A%20%20%20%20picture%7B%0A%20%20%20%20%20%20url%0A%20%20%20%20%20%20name%0A%20%20%20%20%7D%0A%20%20%20%20users%7B%0A%20%20%20%20%20%20name%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D", { method: "GET" })
          .then((store) => this.setState({
            editStoreData: store.data.store,
            idEditando: id,
            area_admin: "focus",
            storeEmail: store.data.store.email,
            storeName: store.data.store.name,
            storeDIT: store.data.store.dit,
            storeCP: store.data.store.cp,
            storeAddress: store.data.store.address,        
            storePoblacion: store.data.store.poblacion,
            storeProvincia: store.data.store.provincia,
            storeTelf1: store.data.store.mainphone,
            storeTelf2: store.data.store.auxphone,
            storeTipo: store.data.store.tipostore,
            storeIdSolvia: store.data.store.idsolvia,
            storeLatitud: store.data.store.latitud,
            storeLongitud: store.data.store.longitud,
            photo: store.data.store.picture?store.data.store.picture.url: '/img/userplaceholder.jpg',                   
            modal: !this.state.modal,
          }))
          ;
                     
        } else {
          //Si no tiene ID, abrimos el modal
          this.setState({
            modal: !this.state.modal          
          });
        }
      }
  }
  
  fetchData(state,instance){
    this.setState({
      start: instance.state.page * instance.state.pageSize,
      limit: instance.state.pageSize,
      sort: state.sorted,
      filter: this.state.filter
    })
  }
  setLoading(loading){
    this.setState({
      loading
    });
  }
  loadFilter(response,filter){
    this.setState({
      tiendas: response,
      loading: false,
      filter: filter
    });
  }
  gestionaBloque = (id) => {
    let array = this.state.selectBloque;
    if (array.includes(id))
    {
      //eliminar elemento del array
      var index = array.indexOf(id);
      if (index > -1) {
      array.splice(index, 1);
    }
      
    } else {
      //Añadir al array
      array.push(id)
    };
    this.setState({selectBloque: array});
  }
  actualizaTiendas(id){
    const tiendasMod= [...this.state.tiendas]; //copia el estado
    tiendasMod.find(e => e._id===id).visible = !(tiendasMod.find(e => e._id===id).visible); 
    this.setState({tiendas : tiendasMod});
  }
  handleNewavatar = async (ref, refId) => {
    const imgToUpload = new FormData();
      imgToUpload.append('files', this.state.idAvatar);
      imgToUpload.append('field', "picture");
      imgToUpload.append('refId', refId);
      imgToUpload.append('ref', ref);    
      request(constantes("URL_API") + "/upload", { method: 'POST',
                                                    body: imgToUpload,
                                                  }, false) 
        .catch( err => console.log( err ) );
    }
handlechangeAvatar = async () => {   
      const imgToUpload = new FormData();
        imgToUpload.append('files', this.state.idAvatar);
        imgToUpload.append('field', "picture");
        imgToUpload.append('refId', this.state.idEditando);
        imgToUpload.append('ref', "store");     
        request(constantes("URL_API") + "/upload", { method: 'POST',
                                                      body: imgToUpload,
                                                    }, false)
          .catch( err => console.log( err ) );
      } 
 
  deleteStore(id){
    request(constantes("URL_API") + "/store/"+id, { method: "DELETE" })
    .then((response) => this.componentDidMount());
  }
  storeAvatar(value){ 
    if (value.target.files && value.target.files[0]) {            
        this.setState({
          idAvatar: value.target.files[0]
          }); 
          let reader = new FileReader();
              reader.onload = (e) => {
                  this.setState({photo: e.target.result});
              };
              reader.readAsDataURL(value.target.files[0]); 
    }
  }
  eliminaAvatar(){
    this.setState({
      photo: '/img/userplaceholder.jpg',
      idAvatar:''
    })
  }
  
 async componentDidUpdate(prevProps, prevState){

    if(prevState.start !== this.state.start || prevState.limit !== this.state.limit || prevState.sort !== this.state.sort || prevState.filter !== this.state.filter){
      this.setState({
        loading: true
      });
      await SearchListServerSide(this.state.model, 0, 0, this.state.sort, this.state.area_admin, this.state.filter, this.state.defaultfilter)
      .then((response)=>this.setState({
        total: response.length
      }));
      await SearchListServerSide(this.state.model, this.state.start, this.state.limit, this.state.sort, this.state.area_admin, this.state.filter)
      .then((response)=>this.setState({
        tiendas: response
      }));          
      this.setState({
        loading: false
      });
    }
  }

  async componentDidMount(){
    this.setState({
      loading: true
    });
    await SearchListServerSide(this.state.model, 0, 0, this.state.sort, this.state.area_admin, this.state.filter, this.state.defaultfilter)
    .then((response)=>this.setState({
      total: response.length
    }));
    await SearchListServerSide(this.state.model, this.state.start, this.state.limit, this.state.sort, this.state.area_admin, this.state.filter)
    .then((response)=>this.setState({
      tiendas: response
    }));      
    this.setState({
      loading: false
    })
  }
  render() {
    
      return(
        <div className = "main_content listado">
          <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className + ('marcomodal')}>
            <ModalHeader className="modalHeader" toggle={this.toggle}>Edita tu store</ModalHeader>
            <ModalBody className="modal-registry shadow">
                              <div className="row">
                              <div className="col">
                                <Label htmlFor="avatar">Fotografía</Label>
                                <div className="row">
                                  <img id="target" src={this.state.photo} alt="Imagen Destacada" className="imageInput imagestore"/>
                                </div>
                                <div className="row">
                                  <Label htmlFor="avatar">Añadir imagen</Label> 
                                  <Label  onClick={this.eliminaAvatar}>Eliminar imagen</Label> 
                                  <Input className="col-12 d-none" type="file" name="file" id="avatar" accept=".jpg,.png, .gif" onChange={this.storeAvatar}/>
                                  <FormText color="muted">
                                    Elige la imagen principal que quieras utilizar.
                                  </FormText>
                                </div>   
                              </div>
                              <div className="col-7">
                                <div className="row">
                                  <div className="col">                                    
                                      <Label htmlFor="storeName">Nombre de la Store</Label>
                                      <Input type="text" name="storename" id="storeName" placeholder="Nombre de la Store" value={this.state.storeName} onChange={(value)=>this.handleNew(value, "Name")} className = "rounded_cont shadow"/>                                                                        
                                  </div>
                                  <div className="col-5">
                                    <Label htmlFor="dit">DIT</Label>
                                    <Input type="select" name="select" id="dit" value={this.state.storeDIT} onChange={(value)=>this.handleNew(value, "DIT")} className = "rounded_cont shadow">
                                       <option value="" key="none">Ninguno</option>
                                        { this.printDIT() }
                                    </Input>
                                  </div>
                                 </div>
                                 <div className="row">
                                  <div className="col">                                    
                                      <Label htmlFor="poblacion">Población</Label>
                                      <Input type="text" name="poblacion" id="poblacion" placeholder="Población" value={this.state.storePoblacion} onChange={(value)=>this.handleNew(value,"Poblacion")} className = "rounded_cont shadow"/>                                                                        
                                  </div>
                                  <div className = "col">
                                      <Label htmlFor="provincia">Provincia</Label>
                                      <Input type="text" name="provincia" id="provincia" placeholder="Provincia" value={this.state.storeProvincia} onChange={(value)=>this.handleNew(value, "Provincia")} className = "rounded_cont shadow"/>
                                  </div>
                                  <div className="col">                                    
                                      <Label htmlFor="cp">Código Postal</Label>
                                      <Input type="number" name="cp" id="cp" placeholder="Código Postal" value={this.state.storeCP} onChange={(value)=>this.handleNew(value, "CP")} className = "rounded_cont shadow"/>                                                                        
                                  </div>
                                </div>
                                <div className = "row">
                                  <div className = "col-7">
                                      <Label htmlFor="address">Dirección</Label>
                                      <Input type="text" name="address" id="address" placeholder="Dirección" value={this.state.storeAddress} onChange={(value)=>this.handleNew(value, "Address")} className = "rounded_cont shadow"/>
                                  </div>                                  
                                  <div className="col">
                                      <Label for="storetipo">Tipo de Store</Label>
                                      <Input type="select" name="storetipo" id="storetipo" value={this.state.storeTipo} onChange={(value)=>this.handleNew(value, "Tipo")} className = "rounded_cont shadow">                                        
                                        <option value ="">Ninguno</option>
                                        <option value ="Agencia">Agencia</option>
                                        <option value="Franquicia">Franquicia</option>
                                      </Input>
                                  </div>
                                </div>
                                <div className = "row">
                                  <div className = "col">
                                      <Label htmlFor="idsolvia">ID Solvia</Label>
                                      <Input type="number" name="idsolvia" id="idsolvia" placeholder="ID Solvia" value={this.state.storeIdSolvia} onChange={(value)=>this.handleNew(value, "IdSolvia")} className = "rounded_cont shadow"/>
                                  </div>
                                  <div className="col-7">
                                      <Label for="email">Email</Label>
                                      <Input type="text" name="email" id="email" placeholder="Email"  value={this.state.storeEmail} onChange={(value)=>this.handleNew(value, "Email")} className = "rounded_cont shadow"/>                                       
                                  </div>
                                </div>
                                <div className = "row">
                                  <div className = "col">
                                      <Label htmlFor="telf1">Teléfono Principal</Label>
                                      <Input type="number" name="telf1" id="telf1" placeholder="Teléfono Principal" value={this.state.storeTelf1} onChange={(value)=>this.handleNew(value, "Telf1")} className = "rounded_cont shadow"/>
                                  </div>
                                  <div className="col">
                                      <Label for="telf2">Teléfono Secundario</Label>
                                      <Input type="number" name="telf2" id="telf2" placeholder="Teléfono Secundario"  value={this.state.storeTelf2} onChange={(value)=>this.handleNew(value, "Telf2")} className = "rounded_cont shadow"/>                                       
                                  </div>
                                </div>
                                <div className = "row">
                                  <div className = "col">
                                      <Label htmlFor="latitud">Latitud</Label>
                                      <Input type="number" name="latitud" id="latitud" placeholder="Latitud" value={this.state.storeLatitud} onChange={(value)=>this.handleNew(value, "Latitud")} className = "rounded_cont shadow"/>
                                  </div>
                                  <div className="col">
                                      <Label for="longitud">Longitud</Label>
                                      <Input type="number" name="longitud" id="longitud" placeholder="Longitud"  value={this.state.storeLongitud} onChange={(value)=>this.handleNew(value, "Longitud")} className = "rounded_cont shadow"/>                                       
                                  </div>
                                </div>
                            </div> 
                      </div>
                      
              </ModalBody>
            <ModalFooter>
              <Button color="primary" 
              onClick={this.state.idEditando===''?this.addStore:() =>{this.editStore(this.state.idEditando)}}
              >{this.state.idEditando===''?'Añadir Store':'Guardar cambios'}</Button>
              <Button color="secondary" onClick={this.toggle}>Cancelar</Button>
            </ModalFooter>
          </Modal>
          <div><h3>Stores</h3></div>
          <div className="rounded_cont contents shadow">
            <div className="row">            
              <div className="col">
              <SearchListFilterComponent setLoading={this.setLoading} loadFilter={this.loadFilter} model={this.state.model} start={this.state.start} limit={this.state.limit} sort={this.state.sort} field="name" placeholder="Nombre de la Store"/>           
                  <ReactTable
                    manual
                    defaultSorted={this.state.sort}
                    pages={ parseInt(this.state.total/this.state.limit) < (this.state.total/this.state.limit) ? (parseInt(this.state.total/this.state.limit) + 1) : (this.state.total/this.state.limit) }
                    defaultPageSize={this.state.limit}
                    onFetchData={this.fetchData} // Request new data when things change
                    loading={this.state.loading}
                    data={this.state.tiendas}
                    
                    // filterable
                    // defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                    
                    columns={[                      
                      {
                        Header: '',                        
                        accessor: "_id",
                        width: 60,
                        Cell: row => (
                        <div className=""><input name={row.value} id={row.value} type="checkbox" onChange={() => this.gestionaBloque(row.value)} checked={this.state.selectBloque.includes(row.value)?true:false}/>
                        <div className="state"></div></div>                     
                          )
                      },
                      {
                        accessor: "picture.url",
                        width: 120,                          
                        Cell: row => (
                          <div className = "avatar shadow">
                            <img src= { (row.value !== null) ? row.value : "/img/userplaceholder.jpg"} alt=""/>
                          </div>
                        )
                      },
                      {
                        Header: 'Store',                        
                        accessor: "name",
                        headerClassName: "store",                        
                        width: 120,            
                        Cell: row => (
                          <div className = "store">
                            {/* <textarea>{ row.value }</textarea> */}
                            { row.value }
                          </div>
                        )
                      },
                      {
                        Header: "Email",
                        accessor: "email",
                        headerClassName: 'email',
                        Cell: row => (
                          <div className = "email">
                            <Mailto email = { row.value } />
                          </div>
                        )
                      },
                      {
                        Header: 'Manager',                        
                        accessor: "users[0].username",
                        headerClassName: "author",
                        Cell: row => (
                          <div className = "author">
                            { row.value===undefined?<i>Sin Manager</i>:row.value } 
                          </div>
                        )
                      },
                      {
                        Header: "DIT",
                        accessor: "dit",
                        width: 100,
                        Cell: row => (
                          <div className = "dit">
                            { row.value }
                          </div>
                         )
                      },
                      {
                        Header: "Editar",
                        accessor: "_id",
                        width: 100,
                        Cell: row => (
                          <div className = "edit">
                            <span onClick={() =>{this.toggle(row.value)}}> 
                              <i className="fas fa-edit"></i> 
                            </span>
                          </div>
                        )
                      },
                      {
                        Header: "Eliminar",
                        accessor: "_id",
                        width: 100,
                        Cell: row => (
                          <div className = "delete">
                            <span onClick={ () => {
                                  if (window.confirm("¿Estás seguro que quieres borrar esto?")){
                                    this.deleteStore(row.value)
                                  }
                                  //console.log(row.value);
                                }
                              } > <i className="fas fa-trash-alt"></i> 
                            </span>
                          </div>
                        )
                      }
                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                  />

            </div>
            
            {this.state.selectBloque.length > 0?
              <div className="col-12">
                <div className="row menuBloque">
                  <div className="col-10"> <span >Has seleccionado {this.state.selectBloque.length} {this.state.selectBloque.length<2?"store":"stores"}.</span>
                    </div>
                    <div className="col-2 text-center"> <span onClick={ () => {
                                if (window.confirm("¿Estás seguro que quieres borrar estas stores?")){
                                  this.state.selectBloque.forEach((element)=>this.deleteStore(element))                                                                    
                                };
                                this.setState({selectBloque:[]});
                              }
                            } > <i className="fas fa-trash-alt"></i> 
                          </span>
                    </div>
                </div>
                  
              </div>:""}
          </div>
        </div>
        <div className = "row">
              <div className = "col-3">
                  <Button color="primary" onClick={() =>{this.toggle()}}>Añadir Store</Button>
              </div>
          </div>
      </div>
      );
  }
}

export default Stores;
