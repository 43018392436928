import React, { Component } from "react";
import ReactQuill from "react-quill";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DateTimePicker from "react-datetime-picker";
import renderHTML from "react-render-html";
import "./styles.css";
import Select from "react-select";
import request from "../../utils/request";
import constantes from "../../utils/constantes";
import auth from "../../utils/auth";
import "../../../node_modules/react-quill/dist/quill.snow.css";
import "../../../node_modules/react-quill/dist/quill.bubble.css";
import UploadMedia from "../../components/UploadMedia";
import Upload from "../../components/Upload";
import Moment from "react-moment";
import ReactTable from "react-table";
import SearchListFilterComponent from "../../components/SearchListFilterComponent";
import SearchListServerSide from "../../utils/SearchListServerSide";
import Loading from "../../components/Loading";
import FiltroEntrada from "../../components/FiltroEntrada";
import _ from "lodash";
import gql from "../../utils/gql";

var moment = require("moment");

class NewContent extends Component {
  initialStatus = {
    selectedOption: [],
    theme: "snow",
    title: "",
    quill: null,
    audio: "",
    video: "",
    resume: "",
    categoria: "",
    tipo: "Noticia",
    destacada: false,
    modal: false,
    modalError: false,
    modalInputPhoto: false,
    date: new Date(),
    when: "now",
    strError: "",
    postDoIt: true,
    edit: this.edition(),
    zonamedia: this.edition(),
    comments: false,
    idCreate: "",
    toggleBigPhoto: false,
    visibilidad: true,
    exportMedia: {
      url: "",
      alt: ""
    },
    portadaImage: {
      url: "",
      alt: ""
    },
    categorias: [],
    categoria_padre: [],
    tests: [],
    selectedTest: [],
    retos: [],
    selectedReto: [],
    solviaCanal: [],
    solviaRol: [],
    stores: [],
    entrada: {},
    amountReto: 0,
    oftenReto: 0,
    comment: "",
    canalSelected: "",
    rolSelected: "",
    storeSelected: "",
    user: {},
    validData: true,
    modalIMG: true,
    modalNoticia: true,
    modalVideo: false,
    modalAudio: false,
    modalTest: false,
    modalDocument: false,
    modalReto: false,
    retoIsSelected: false,
    comentarios: [],
    cursos: [],
    media: {
      _id: "",
      photo: ""
    },
    photo: "",
    borrador: false,
    programada: false,
    literal_guardar: this.setLiteralGuardar(),
    publish_date: new Date(),
    create_notify: true,
    limit: 10,
    start: 0,
    loading: true,
    total: 0,
    sort: [],
    filter: [],
    actividad: '',
    defaultfilter: [{ id: "entrada", value: this.props.match.params.id }],
    model: "upload/files"
  };
  constructor(props) {
    super(props);
    this.state = this.initialStatus;
    this.checkProgramada = this.checkProgramada.bind(this);
    this.checkValidData = this.checkValidData.bind(this);
    this.deleteNotifi = this.deleteNotifi.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.getDate = this.getDate.bind(this);
    this.getEntradaObject = this.getEntradaObject.bind(this);
    this.getNotificationObject = this.getNotificationObject.bind(this);
    this.handleChangeQuill = this.handleChangeQuill.bind(this);
    this.handleChangeTitle = this.handleChangeTitle.bind(this);
    this.handleChangeCategoria = this.handleChangeCategoria.bind(this);
    this.handleChangeResume = this.handleChangeResume.bind(this);
    this.loadFilter = this.loadFilter.bind(this);
    this.portadaImage = this.portadaImage.bind(this);
    this.postNotification = this.postNotification.bind(this);
    this.renderCategorias = this.renderCategorias.bind(this);
    this.renderTipoEntrada = this.renderTipoEntrada.bind(this);
    this.saveContent = this.saveContent.bind(this);
    this.setLiteralGuardar = this.setLiteralGuardar.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.toggleIMG = this.toggleIMG.bind(this);
    this.toggleNoticia = this.toggleNoticia.bind(this);
    this.toggleActividad = this.toggleActividad.bind(this);
    this.togglePublish = this.togglePublish.bind(this);
    this.toggleVideo = this.toggleVideo.bind(this);
    this.toggleAudio = this.toggleAudio.bind(this);
  }

  async fetchData(state, instance) {
    await this.setState({
      start: instance.state.page * instance.state.pageSize,
      limit: instance.state.pageSize,
      sort: state.sorted,
      filter: this.state.filter
    });
  }

  edition() {
    if (this.props.match.url.split("/")[1] === "edit") {
      return true;
    } else {
      return false;
    }
  }

  setLoading(loading) {
    this.setState({
      loading
    });
  }

  loadFilter(response, filter) {
    this.setState({
      medias: response,
      loading: false,
      filter: filter,
      total: response.length
    });
  }

  // Notificaciones
  async postNotification(entrada) {
    let notification = await this.getNotificationObject(entrada);
    let response = await request(constantes("URL_API") + "/mynotifications", {
      method: "POST",
      body: notification
    });

    if (response._id) {
      this.setState({
        idCreateNot: response._id
      });
    }
  }

  async deleteNotifi(id) {
    request(constantes("URL_API") + "/mynotifications/" + id, {
      method: "DELETE"
    });
  }

  // FIN Notificaciones

  saveBorrador = async () => {
    await this.setState({
      borrador: true
    });

    let entrada = await this.getEntradaObject();
    let method;

    if (this.state.edit === true) {
      method = "PUT";
      var consulta = "/entradas/" + this.state.idCreate;
    } else {
      method = "POST";
      consulta = "/entradas";
    }

    let response = await request(constantes("URL_API") + consulta, {
      method: method,
      body: entrada
    });

    if (this.state.edit) {
      if (response._id) {
        this.setState({ canCreate: false });
      }
    } else if (response._id) {
      this.setState({
        idCreate: response._id,
        canCreate: false,
        edit: true
      });
    }
    this.props.history.push("/contents");
  };

  saveContent = async () => {
    await this.setState({
      borrador: false
    });

    let entrada = await this.getEntradaObject();
    let method;

    if (this.state.edit === true) {
      method = "PUT";
      var consulta = "/entradas/" + this.state.idCreate;
    } else {
      method = "POST";
      consulta = "/entradas";
    }

    let response = await request(constantes("URL_API") + consulta, {
      method: method,
      body: entrada
    });

    if (this.state.edit) {
      if (response._id) {
        this.setState({ canCreate: false });
      }
    } else if (response._id) {
      this.setState({
        idCreate: response._id,
        canCreate: false,
        edit: true
      });
    }
    if (!this.state.borrador) {
      // this.state.create_notify &&
      //   response.mynotifications &&
      //   response.mynotifications.length > 0 &&
      //   response.mynotifications.map(x => this.deleteNotifi(x._id));
      // // this.deleteNotifi(response.notificacions[0]._id);
      // this.state.create_notify && this.postNotification(response);
    }
    this.props.history.push("/contents");
  };

  // Comentarios
  addComent() {
    let comentario = {
      user: auth.getUserInfo()._id,
      comments: this.state.comment,
      entrada: this.state.id,
      publish_date: new Date()
    };

    request(constantes("URL_API") + "/comentarios", {
      method: "POST",
      body: comentario
    }).then(response => {
      this.setState({
        comment: ""
      });
      this.componentDidMount();
    });
  }

  deleteComment(id) {
    request(constantes("URL_API") + "/comentarios/" + id, {
      method: "DELETE"
    }).then(response => this.componentDidMount());
  }
  handleChangeTest = selectedOption => {
    this.setState({ selectedTest: selectedOption });
  };

  handleChangeReto = selectedOption => {
    this.setState({
      selectedReto: selectedOption,
      amountReto: selectedOption.amount,
      oftenReto: selectedOption.often,
      retoIsSelected: true
    });
  };

  // FIN Comentarios

  checkValidData() {
    // console.log("Entra aquí");
    // this.state.video ? console.log(this.state.video) : null
    if (
      this.state.title.length > 0 &&
      this.state.resume.length > 0 &&
      // && this.state.categoria._id != ""

      // && (this.state.quill != null && this.state.quill != "<p><br></p>" && this.state.modalIMG === true)
      this.state.video != null &&
      this.state.modalVideo === true
      // && ((this.state.quill != null && this.state.quill != "<p><br></p>" && this.state.modalIMG === true) || (this.state.audio != "" && this.state.modalAudio === true) || (this.state.video != "" && this.state.modalVideo === true) )
    ) {
      this.setState({ validData: true });
    } else {
      this.setState({ validData: false });
    }
    // console.log("estado final: ");
    // console.log(this.state.validData)
    this.setState({ validData: true });
  }

  exportMedia = async array => {
    this.setState({ exportMedia: array, dataMedia: array });
    // switch (this.state.tipo) {
    //   case "Audio":
    //     this.setState({ exportMedia: array, audio: array[0] });
    //     break;

    //   case "Video":
    //     this.setState({ exportMedia: array, video: array ? array[0] : null });
    //     break;

    //   default:
    //     await this.setState({ exportMedia: array });
    //     break;
    // }
    console.log("export media esta: " + this.state.exportMedia[1]);
  };

  portadaImage(array) {
    this.setState({ portadaImage: array });
  }

  getEntradaObject() {
    let entrada = {
      title: this.state.title,
      content: this.state.quill,
      excerpt: this.state.resume,
      type: this.state.tipo,
      destacada: this.state.destacada,
      categoria: this.state.categoria._id ? this.state.categoria : null,
      image: this.state.portadaImage,
      media: this.state.exportMedia,
      publish_date: this.state.publish_date,
      borrador: this.state.borrador,
      area_admin: localStorage.getItem("admin"),
      question: this.state.selectedTest ? this.state.selectedTest.value : null,
      reto: this.state.selectedReto ? this.state.selectedReto.value : null,
      // solviarol: this.state.rolSelected.value,
      solviacanal: this.state.canalSelected.value,
      actividad: this.state.actividad,
      cursos:
        this.state.area_admin === "smartlearning"
          ? this.state.selectedOption.map(x => x.value)
          : null,
      // store: this.state.storeSelected.value,
      visibilidad: this.state.visibilidad
    };
    console.log({ entrada });
    return entrada;
  }

  getUsers = async () => {
    const select = {
      canalSelected:
        this.state.canalSelected === ""
          ? null
          : JSON.stringify(this.state.canalSelected.value)
    };

    const query = `
    {
      solviacanals(where: {_id: $idselectedsolviacanal$}){
        _id
        users{
          _id
          email
        }
      }
    }
  `;

    const response = await request(
      gql(
        query,
        {
          idselectedsolviacanal: select.canalSelected,
          idsselectedsolviarol: select.rolSelected,
          idsselectedstores: select.storeSelected
        },
        constantes("URL_API")
      )
    );

    const reponseCanals = _.flatMap(response.data.solviacanals, x => x.users);
    const users = _.uniqBy(reponseCanals, "_id").map(x => x._id);
    const finalusers = users.filter(x => x.token !== "").map(x => x.token);

    return users;
  };

  getAllUsers = async () => {
    const query = `
    {
      users(limit:0, where: {token_ne:null}){
        _id
        token
      }
    }
  `;

    const response = await request(gql(query, {}, constantes("URL_API")));

    return response.data.users.filter(x => x.token !== "").map(x => x._id);
  };

  async getNotificationObject(entrada) {
    let notification = {
      entrada: entrada._id,
      body: entrada.excerpt ? entrada.excerpt.replace(/(<([^>]+)>)/gi, "") : "",
      title: entrada.title,
      users: !this.state.visibilidad
        ? await this.getUsers()
        : await this.getAllUsers(),
      date: moment(entrada.publish_date)
        .utc()
        .format(),
      data: {
        entrada: entrada._id
      }
      //fecha: entrada.publish_date
    };
    // if (this.props.match.url.split("/")[1] === "edit") {
    //   notification = Object.assign({ fecha: entrada.publish_date }, notification);
    // } else if (this.state.programada) {
    //   notification = Object.assign({ fecha: entrada.publish_date }, notification);
    // } else {
    //   notification = Object.assign({ fecha: null }, notification);
    // }
    return notification;
  }

  handleChangeQuill(value) {
    console.log(value)
    this.setState({ quill: value, canCreate: true });
    this.checkValidData();
  }

  handleChangeTitle(value) {
    this.setState({ title: value.target.value, canCreate: true });
    this.checkValidData();
  }

  handleChangeActividad(value) {
    this.setState({ actividad: value.target.value });
  }

  handleChangeResume(value) {
    this.state.tipo === "Noticia"
      ? this.setState({ resume: value.target.value, canCreate: true })
      : this.setState({
        resume: value.target.value,
        quill: value.target.value,
        canCreate: true
      });
    this.checkValidData();
  }

  checkProgramada(publish_date) {
    publish_date = new moment(publish_date);
    let today = new moment();
    let programada;
    publish_date > today ? (programada = true) : (programada = false);
    this.setState({ programada: programada });
    return programada;
  }

  getDate(event) {
    this.setState({ programada: event.target.checked });
    if (event.target.checked === false) {
      this.setState({ publish_date: new Date() });
    }
    return this.state.publish_date;
  }

  renderCursos = () => {
    return (
      <Select
        onChange={selectedOption => {
          this.setState({
            selectedOption
          });
        }}
        placeholder={"Seleccione los cursos asociados..."}
        closeMenuOnSelect={false}
        styles={{
          control: styles => ({ ...styles, marginBottom: "15px", width: "95%" })
        }}
        defaultValue={this.state.selectedOption}
        value={this.state.selectedOption}
        isMulti
        options={this.state.cursos.map(x => ({
          value: x._id,
          label: x.nombre
        }))}
      />
    );
  };

  renderCategorias() {
    return (
      <select
        onChange={event => this.handleChangeCategoria(event)}
        value={this.state.categoria._id}
        placeholder="Categoría"
        className="form-control rounded_cont shadow"
      >
        <option value="">Categoría Principal</option>
        {this.state.categorias.map(categoria => {
          return (
            <option key={categoria._id} value={categoria._id}>
              {categoria.nombre}
            </option>
          );
        })}
      </select>
    );
  }

  handleChangeCategoria(event) {
    event.persist();
    var categoria = event.target.value;
    if (categoria !== "") {
      let consulta =
        "/graphql?query=%7B%0A%09categoria(id%3A%22" +
        categoria +
        "%22)%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20nombre%0A%20%20%20%20%7D%0A%7D%0A";
      request(constantes("URL_API") + consulta, {
        method: "GET"
      }).then(categoria =>
        this.setState({ categoria: { _id: categoria.data.categoria._id } })
      );
    } else {
      this.setState({ categoria: { _id: "" } });
    }
    this.checkValidData();
  }

  // FIN Categorias

  repeat() {
    //comprobaciones para saber si lanzar el post/put o no
    if (this.state.canCreate === true) {
      this.saveContent();
    }
  }

  async togglePublish() {
    if (this.state.area_admin === "smartlearning") {
      await this.setState({
        visibilidad: false
      });
      this.saveContent();
    } else {
      this.setState({ modal: !this.state.modal });
    }
  }

  toggleIMG() {
    this.setState({ modalIMG: !this.state.modalIMG });
  }

  toggleActividad() {
    this.setState({
      modalAudio: !this.state.modalAudio,
      modalVideo: false,
      modalNoticia: false,
      tipo: "Actividad",
      video: "",
      quill: null,
      mediaPreview: ""
    });
  }

  toggleNoticia() {
    this.setState({
      modalNoticia: !this.state.modalNoticia,
      modalVideo: false,
      modalAudio: false,
      tipo: "Noticia",
      video: "",
      audio: ""
    });
  }

  toggleVideo() {
    this.setState({
      modalVideo: !this.state.modalVideo,
      modalNoticia: false,
      modalAudio: false,
      tipo: "Video",
      quill: null,
      audio: "",
      mediaPreview: ""
    });
  }

  toggleAudio() {
    this.setState({
      modalAudio: !this.state.modalAudio,
      modalVideo: false,
      modalNoticia: false,
      tipo: "Audio",
      video: "",
      quill: null,
      mediaPreview: ""
    });
  }

  toggleTest = async () => {
    this.setState({
      modalTest: !this.state.modalTest,
      modalAudio: false,
      modalVideo: false,
      modalNoticia: false,
      modalReto: false,
      tipo: "Test",
      video: "",
      quill: null,
      mediaPreview: ""
    });
  };

  toggleDocument = () => {
    this.setState({
      modalDocument: !this.state.modalDocument,
      modalTest: false,
      modalAudio: false,
      modalVideo: false,
      modalNoticia: false,
      modalReto: false,
      tipo: "Document",
      video: "",
      quill: null,
      mediaPreview: ""
    });
  };

  toggleReto = async () => {
    this.setState({
      modalReto: !this.state.modalTest,
      modalAudio: false,
      modalVideo: false,
      modalNoticia: false,
      tipo: "Reto",
      video: "",
      quill: null,
      mediaPreview: ""
    });
  };

  setLiteralGuardar(borrador = false) {
    return borrador ? "Guardar borrador" : "Publicar entrada";
  }

  // componentDidMount / componentDidUpdate

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setState(this.initialStatus);
      this.setState({ loading: false });
      // this.componentDidMount();
    }
    if (
      prevState.start !== this.state.start ||
      prevState.limit !== this.state.limit ||
      prevState.sort !== this.state.sort ||
      prevState.filter !== this.state.filter ||
      prevState.total !== this.state.total
    ) {
      this.setState({ loading: true });
      await SearchListServerSide(
        "comentario",
        this.state.start,
        this.state.limit,
        this.state.sort,
        this.state.filter,
        this.state.defaultfilter
      ).then(response => {
        this.setState({
          comentarios: response,
          total:
            prevState.filter !== this.state.filter
              ? parseInt(response.length / this.state.limit, 10) + 1
              : this.state.total
        });
      });
      this.setState({ loading: false });
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });

    request(
      constantes("URL_API") +
      "/graphql?query=%7B%0A%20%20cursos(where%3A%20%7Bcomplete%3A%20true%7D)%7B_id%20nombre%20complete%7D%0A%7D",
      { method: "GET" }
    ).then(cursos => {
      this.setState({
        cursos: cursos.data.cursos
      });
    });

    request(
      constantes("URL_API") +
      "/graphql?query=%7B%0A%09categorias%20%7B%0A%09%09_id%0A%20%20%20%20nombre%0A%20%20%20%20descripcion_larga%0A%09%09descripcion_corta%0A%20%20%20%20categoria_padre%7B%0A%09%09%09_id%0A%20%20%20%20%20%20nombre%0A%20%20%20%20%20%20descripcion_larga%0A%09%09%09descripcion_corta%0A%09%09%7D%0A%20%20%20%20%0A%09%09categorias_hijas%20%7B%0A%09%09%09_id%0A%09%09%09nombre%0A%09%09%09descripcion_larga%0A%09%09%09descripcion_corta%0A%09%09%7D%0A%20%20%20%20entradas%20%7B%0A%20%20%20%20%20%20title%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D",
      { method: "GET" }
    ).then(categorias => {
      if (Object.keys(categorias).includes("errors")) {
        localStorage.clear();
        this.props.history.push("/auth/login");
      }
      this.setState({
        categorias: categorias.data.categorias,
        area_admin: localStorage.getItem("admin")
      });
    });

    request(
      constantes("URL_API") +
      "/graphql?query=%7B%0A%09solviarols%7B%0A%09%09_id%0A%09%09name%0A%09%7D,%0A%09solviacanals%7B%0A%09%09_id%0A%09%09name%0A%09%7D,%0A%09stores%7B%0A%09%09_id%0A%09%09name%0A%09%7D%0A%7D",
      { method: "GET" }
    ).then(filtro => {
      let roles = filtro.data.solviarols;
      let tiendas = filtro.data.stores;
      let canales = filtro.data.solviacanals;
      this.setState({
        solviaCanal: canales.map(canal => {
          return {
            value: canal._id,
            label: canal.name
          };
        }),
        solviaRol: roles.map(rol => {
          return {
            value: rol._id,
            label: rol.name
          };
        }),
        stores: tiendas.map(store => {
          return {
            value: store._id,
            label: store.name
          };
        })
      });
    });

    const uriE = "/questions?_limit=0";
    const token = auth.getToken();
    fetch(constantes("URL_API") + uriE, {
      method: "GET",
      body: JSON.stringify(),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    })
      .then(data => data.json())
      .then(response => {
        let testList = response;

        testList.forEach(tes => {
          this.setState({
            tests: [...this.state.tests, tes]
          });
        });
        this.setState({
          tests: this.state.tests.map(test => {
            return {
              value: test._id,
              label: test.text
            };
          })
        });
      });

    const uriR = "/retos";
    fetch(constantes("URL_API") + uriR, {
      method: "GET",
      body: JSON.stringify(),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    })
      .then(data => data.json())
      .then(response => {
        let retosList = response;

        retosList.forEach(ret => {
          this.setState({
            retos: [...this.state.retos, ret]
          });
        });
        this.setState({
          retos: this.state.retos.map(reto => {
            return {
              value: reto._id,
              label: reto.name,
              amount: reto.amount,
              often: reto.often
            };
          })
        });
      });

    if (this.state.edit === true) {
      // Obtenemos los datos de la entrada
      let consulta_entry =
        "/graphql?query=%0A%7B%0A%20%20%20%20entrada(id%3A%22" +
        this.props.match.params.id +
        "%22)%7B%0A%20%20%20%20_id%0A%20%20%20%20title%0A%20%20%20%20content%0A%20%20%20%20excerpt%0A%20%20%20%20borrador%0A%20%20%20%20actividad%0A%20%20%20%20type%0A%20%20%20%20categoria%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%20question%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20text%0A%20%20%20%20%7D%0A%20%20%20%20%20cursos%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20nombre%0A%20%20%20%20%7D%0A%20%20%20%20create_date%0A%20%20%20%20publish_date%0A%20%20%20%20visitas%0A%20%20%20%20image%0A%20%20%20%20media%0A%20%20%20%20destacada%0A%20%20%20%20comentarios%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20publish_date%0A%20%20%20%20%20%20comments%0A%20%20%20%20%20%20user%7B%0A%20%20%20%20%20%20%20%20username%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D%0A%0A";

      request(constantes("URL_API") + consulta_entry, { method: "GET" }).then(
        entrada => {
          if (Object.keys(entrada).includes("errors")) {
            localStorage.clear();
            this.props.history.push("/auth/login");
          }
          this.setState({
            exportMedia: entrada.data.entrada.media,
            portadaImage: entrada.data.entrada.image,
            title: entrada.data.entrada.title,
            quill: entrada.data.entrada.content,
            resume: entrada.data.entrada.excerpt,
            actividad: entrada.data.entrada.actividad,
            question: entrada.data.entrada.question,
            selectedTest: entrada.data.entrada.question ? { value: entrada.data.entrada.question._id, label: entrada.data.entrada.question.text } : null,
            photo: entrada.data.entrada.image
              ? entrada.data.entrada.image
              : null,
            dataImg: entrada.data.entrada.image
              ? entrada.data.entrada.image
              : null,
            dataMedia: entrada.data.entrada.media
              ? entrada.data.entrada.media
              : null,
            mediaPreview: entrada.data.entrada.media
              ? entrada.data.entrada.media.url
              : null,
            tipo: entrada.data.entrada.type,
            id: entrada.data.entrada._id,
            publish_date: entrada.data.entrada.publish_date
              ? new Date(entrada.data.entrada.publish_date)
              : new Date(),
            borrador: entrada.data.entrada.borrador,
            literal_guardar: this.setLiteralGuardar(
              entrada.data.entrada.borrador
            ),
            destacada: entrada.data.entrada.destacada,
            categoria: entrada.data.entrada.categoria
              ? entrada.data.entrada.categoria
              : "",
            edit: true,
            selectedOption: entrada.data.entrada.cursos.map(x => ({
              value: x._id,
              label: x.nombre
            })),
            idCreate: entrada.data.entrada._id,
            programada: this.checkProgramada(entrada.data.entrada.publish_date)
          });
        }
      );

      this.checkValidData();
      ////////////////////////////////////////////

      // Obtenemos los comentarios de la entrada
      if (this.state.comments) {
        let consulta_comentarios =
          "/graphql?query=%0A%7B%09%09%0A%09entrada(id%3A%22" +
          this.props.match.params.id +
          "%22)%7B%0A%09%09%09comentarios%7B%0A%09%09%09%09_id%0A%09%09%09%09comments%0A%09%09%09%09publish_date%0A%09%09%09%09visible%0A%09%09%09%09user%7B%0A%09%09%09%09%09username%0A%09%09%09%09%7D%0A%09%09%09%7D%0A%20%20%7D%0A%7D%0A";
        request(constantes("URL_API") + consulta_comentarios, {
          method: "GET"
        }).then(comentarios => {
          if (Object.keys(comentarios).includes("error")) {
            localStorage.clear();
            this.props.history.push("/auth/login");
          }
          this.setState({
            toggleOjo: comentarios.data.entrada.comentarios.visible
          });
        });

        await SearchListServerSide(
          "comentario",
          this.state.start,
          this.state.limit,
          this.state.sort,
          this.state.filter,
          this.state.defaultfilter
        ).then(response =>
          this.setState({
            comentarios: response,
            total: parseInt(response.length / this.state.limit, 10) + 1
          })
        );
      }
      ////////////////////////////////////////////
    } else {
      // setInterval(this.repeat, 10000);
      this.setState(this.initialStatus);
    }

    this.setState({ loading: false });
  }
  // FIN componentDidMount / componentDidUpdate

  handleChangeCanal = selectedOptionCanal => {
    this.setState({
      canalSelected: selectedOptionCanal
    });
  };

  visibilidadChange = async () => {
    await this.setState({
      visibilidad: !this.state.visibilidad
    });
  };

  handleChangeRol = selectedOptionRol => {
    this.setState({
      rolSelected: selectedOptionRol
    });
  };

  handleChangeStore = selectedOptionStore => {
    this.setState({
      storeSelected: selectedOptionStore
    });
  };


  renderTipoEntrada() {
    switch (this.state.tipo) {
      case "Noticia":
        return (
          <ReactQuill
            theme="snow"
            value={this.state.quill}
            onChange={this.handleChangeQuill}
            modules={NewContent.modules}
            formats={NewContent.formats}
          />
        );

      case "Audio":
        return (
          <div>
            <span className="t2">Audio</span>
            <Upload
              key="audio"
              mime="audio"
              typeFile="audio/*"
              exportFunc={this.exportMedia}
              mediaProp={this.state.exportMedia}
              dataMedia={this.state.dataMedia}
            />

            {/* <UploadMedia
              input
              alt="Alt o descripción del Audio"
              dataImg={this.state.dataImg}
              dataMedia={this.state.dataMedia}
              modal={this.state.modal}
              currentImage={this.state.mediaPreview}
              toggle={this.toggleAudio}
              typeFile="audio/*"
              mime="audio"
              togglePadre={null}
              export={this.exportMedia}
              elige="elige"
              subeConfirma={this.state.zonamedia}
              key="upaudio"
              checkValidData={this.checkValidData}
            /> */}
          </div>
        );

      case "Video":
        return (
          <div>
            <span className="t2">Video</span>
            <Upload
              mime="video"
              typeFile="video/*"
              exportFunc={this.exportMedia}
              mediaProp={this.state.exportMedia}
              dataMedia={this.state.dataMedia}
            />
            {/* <UploadMedia
              input
              alt="Alt o descripción del Video"
              dataImg={this.state.dataImg}
              dataMedia={this.state.dataMedia}
              modal={this.state.modal}
              currentImage={this.state.mediaPreview}
              toggle={this.toggleVideo}
              typeFile="video/*"
              mime="video"
              togglePadre={null}
              export={this.exportMedia}
              elige="elige"
              subeConfirma={this.state.zonamedia}
              key="upvideo"
              checkValidData={this.checkValidData}
            /> */}
          </div>
        );

      case "Actividad":
        return (<div>
          <span className="t2">Pregunta</span>
          <input
            id="actividad"
            type="text"
            placeholder="Pregunta de la actividad"
            value={this.state.actividad}
            onChange={event => this.handleChangeActividad(event)}
            className="form-control"
          />
        </div>)

      case "Test":
        return (
          <div>
            <span className="t2">Tests</span>
            <Select
              options={this.state.tests}
              onChange={this.handleChangeTest}
              value={this.state.selectedTest}
              classNamePrefix="Test..."
              isDisabled={this.state.toAll}
            />
          </div>
        );

      case "Document":
        return (
          <div>
            <Upload
              mime="application/pdf"
              typeFile="application/pdf"
              exportFunc={this.exportMedia}
              mediaProp={this.state.exportMedia}
              dataMedia={this.state.dataMedia}
            />
            {/* <UploadMedia
              input
              alt="Alt o descripción del Documento"
              dataImg={this.state.dataImg}
              dataMedia={this.state.dataMedia}
              modal={this.state.modal}
              currentImage={this.state.mediaPreview}
              toggle={this.toggleDocument}
              typeFile="application/pdf"
              mime="application/pdf"
              togglePadre={null}
              export={this.exportMedia}
              elige="elige"
              subeConfirma={this.state.zonamedia}
              key="upvideo"
              checkValidData={this.checkValidData}
            /> */}
          </div>
        );

      case "Reto":
        return (
          <div>
            <span className="t2">Retos</span>
            <Select
              options={this.state.retos}
              className="form-control rounded-select rounded_cont shadow select-comp"
              onChange={this.handleChangeReto}
              value={this.state.selectedReto}
              classNamePrefix="Reto..."
              isDisabled={this.state.toAll}
            />
            {this.state.retoIsSelected && (
              <div className="row">
                <div className="col retos-imputs1">
                  <label>Duración del reto (días)</label>
                  <br />
                  <label className="retos-number">
                    {this.state.amountReto}
                  </label>
                </div>
                <div className="col retos-imputs2">
                  <label>Frecuencia alarmas (días)</label>
                  <br />
                  <label className="retos-number">{this.state.oftenReto}</label>
                </div>
              </div>
            )}
          </div>
        );
      default:
        return "Noticia";
    }
  }

  render() {
    moment.locale("es");
    return (
      <div className="main_content" style={{ marginBottom: 50 }}>
        {this.state.edit ? <Loading loading={this.state.loading} /> : null}

        <Modal isOpen={this.state.modalError} toggle={this.toggleError}>
          <ModalHeader className="modalHeaderError" toggle={this.toggleError}>
            Error
          </ModalHeader>

          <ModalBody>
            <p>{renderHTML(this.state.strError)}</p>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" onClick={this.toggleError}>
              OK
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modal} toggle={this.togglePublish}>
          <ModalBody>
            <div className="row">
              <div className="col">
                <div className="pretty p-switch p-fill">
                  <input
                    name="create_notify"
                    id="create_notify"
                    onChange={() =>
                      this.setState({
                        create_notify: !this.state.create_notify
                      })
                    }
                    type="checkbox"
                    checked={this.state.create_notify}
                  />
                  <div className="state">
                    <label htmlFor="create_notify">¿Enviar Notificación?</label>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>

          {localStorage.getItem("admin") === "content" &&
            this.state.visibilidad === false ? (
            <div>
              <div
                style={{ marginLeft: "15px", marginBottom: "15px" }}
                className="pretty p-switch p-fill"
              >
                <input
                  onChange={() => this.visibilidadChange()}
                  type="checkbox"
                  checked={this.state.visibilidad}
                />
                <div className="state">
                  <label htmlFor="create_notify">
                    ¿Visible para todos los usuarios?
                  </label>
                </div>
              </div>
              <FiltroEntrada
                filtro={this.state.solviaCanal}
                placeholder={"Canal..."}
                handleChange={this.handleChangeCanal}
                value={this.state.canalSelected}
              />
              {/* <FiltroEntrada
                filtro={this.state.solviaRol}
                placeholder={"Roles..."}
                handleChange={this.handleChangeRol}
                value={this.state.rolSelected}
              />
              <FiltroEntrada
                filtro={this.state.stores}
                placeholder={"Stores..."}
                handleChange={this.handleChangeStore}
                value={this.state.storeSelected}
              /> */}
            </div>
          ) : (
            <div
              style={{ marginLeft: "15px", marginBottom: "15px" }}
              className="pretty p-switch p-fill"
            >
              {localStorage.getItem("admin") === "content" && (
                <div>
                  <input
                    onChange={() => this.visibilidadChange()}
                    type="checkbox"
                    checked={this.state.visibilidad}
                  />
                  <div className="state">
                    <label htmlFor="create_notify">
                      ¿Visible para todos los usuarios?
                    </label>
                  </div>
                </div>
              )}
            </div>
          )}

          <ModalFooter>
            {/* <Button color="primary" {...this.state.borrador ? { className: "borrador" } : null} onClick={this.saveContent}>{this.state.literal_guardar}</Button>{' '} */}
            <Button color="primary" onClick={this.saveContent}>
              Publicar Entrada
            </Button>{" "}
            <Button color="secondary" onClick={this.togglePublish}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>

        <div className="row">
          <div className="col-7">
            <div className="row">
              <div className="col">
                <h4>Título de la entrada</h4>
                <div className="rounded_cont shadow">
                  <input
                    id="titulo"
                    type="text"
                    placeholder="Título"
                    value={this.state.title}
                    onChange={event => this.handleChangeTitle(event)}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <h4>Tipo de contenido</h4>
            <div className="row content_type_container">
              <div
                className={
                  this.state.tipo !== "Noticia"
                    ? "col texto shadow"
                    : "col texto shadow active"
                }
                onClick={this.toggleNoticia}
              >
                <div className="content_type">Texto</div>
              </div>
              <div
                className={
                  this.state.tipo !== "Audio"
                    ? "col audio shadow"
                    : "col audio shadow active"
                }
                onClick={this.toggleAudio}
              >
                <div className="content_type">Audio</div>
              </div>
              <div
                className={
                  this.state.tipo !== "Video"
                    ? "col video shadow"
                    : "col video shadow active"
                }
                onClick={this.toggleVideo}
              >
                <div className="content_type">Video</div>
              </div>
              {localStorage.getItem("admin") === "smartlearning" && (
                <div
                  className={
                    this.state.tipo !== "Test"
                      ? "col test shadow"
                      : "col test shadow active"
                  }
                  onClick={this.toggleTest}
                >
                  <i
                    className="fas fa-question-square"
                    style={{
                      fontSize: "40px",
                      marginTop: "14px",
                      color: "#cecbca"
                    }}
                  />
                  <div className="content_type">Test</div>
                </div>
              )}
              {localStorage.getItem("admin") === "smartlearning" && (
                <div
                  className={
                    this.state.tipo !== "Document"
                      ? "col test shadow"
                      : "col test shadow active"
                  }
                  onClick={this.toggleDocument}
                >
                  <i className="fas fa-file-pdf newcontent_docs" />
                  <div className="content_type">Docs</div>
                </div>
              )}
              {localStorage.getItem("admin") === "smartlearning" && (
                <div
                  className={
                    this.state.tipo !== "Actividad"
                      ? "col test shadow"
                      : "col test shadow active"
                  }
                  onClick={this.toggleActividad}
                >
                  <i class="fas fa-keyboard newcontent_docs"></i>
                  <div className="content_type">Actividad</div>
                </div>
              )}
              {localStorage.getItem("admin") === "smartlearning" && (
                <div
                  className={
                    this.state.tipo !== "Reto"
                      ? "col test shadow"
                      : "col test shadow active"
                  }
                  onClick={this.toggleReto}
                >
                  <img style={{ marginTop: "15px" }} src="img/iconoreto.png" />
                  <div className="content_type">Retos</div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col">
                <h4>Contenido</h4>
                <div className="rounded_cont shadow">
                  {this.renderTipoEntrada()}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h4>Resumen de la entrada</h4>
                <div className="rounded_cont shadow">
                  <textarea
                    onChange={this.handleChangeResume}
                    value={this.state.resume !== null ? this.state.resume : ""}
                    placeholder="Resumen extracto"
                    className="form-control extract"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="meta-content">
              <div className="row">
                <div className="col">
                  <h4>Imagen destacada</h4>
                  <div className="rounded_cont shadow" style={{ width: "95%" }}>
                    <Upload
                      key="Imagen"
                      exportFunc={this.portadaImage}
                      mediaProp={this.state.portadaImage}
                      dataImg={this.state.dataImg}
                      dataMedia={this.state.dataMedia}
                    />
                    {/* <UploadMedia
                      input
                      alt="Alt o descripción de la Imagen"
                      dataImg={this.state.dataImg}
                      dataMedia={this.state.dataMedia}
                      currentImage={this.state.photo}
                      modal={this.state.modal}
                      toggle={this.toggleIMG}
                      typeFile="image/*"
                      mime="image"
                      togglePadre={this.toggleIMG}
                      export={this.portadaImage}
                      elige="elige"
                      subeConfirma={this.state.zonamedia}
                    /> */}
                  </div>
                </div>
              </div>

              {this.state.area_admin === "smartlearning" ? (
                <div className="row">
                  <div className="col">
                    <h4>Cursos</h4>
                    {this.renderCursos()}
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col">
                    <h4>Categoría</h4>
                    {this.renderCategorias()}
                  </div>
                </div>
              )}

              {this.state.area_admin !== "smartlearning" && (
                <div className="row">
                  <div className="col">
                    <h4>Publicación</h4>
                    <div className="rounded_cont shadow borrador">
                      <div className="pretty p-switch p-fill">
                        <input
                          name="programada"
                          id="programada"
                          onChange={event =>
                            this.setState({ destacada: event.target.checked })
                          }
                          type="checkbox"
                          checked={this.state.destacada}
                        />
                        <div className="state">
                          <label htmlFor="programada">
                            <em>Entrada Destacada</em>
                          </label>
                        </div>
                      </div>
                      <hr />
                      <div className="pretty p-switch p-fill">
                        <input
                          name="programada"
                          id="programada"
                          onChange={event => this.getDate(event)}
                          type="checkbox"
                          checked={this.state.programada ? true : false}
                        />
                        <div className="state">
                          <label htmlFor="programada">
                            <em>Programar publicación</em>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {this.state.area_admin !== "smartlearning" && (
                <div className="row">
                  <div className="col">
                    <span className="publish_date">Fecha de publicación:</span>
                    {this.state.programada ? (
                      <DateTimePicker
                        className="rounded_cont calendar"
                        onChange={date => this.setState({ publish_date: date })}
                        value={this.state.publish_date}
                      />
                    ) : (
                      <Moment format="DD [de] MMMM [de] YYYY [ a las ] HH:mm">
                        {this.state.publish_date}
                      </Moment>
                    )}
                  </div>
                </div>
              )}

              <div className="row" style={{ width: "95%" }}>
                <div className="col">
                  <Button
                    className="btn-gradient-style"
                    disabled={!this.state.validData}
                    className="guardarborrador"
                    onClick={this.saveBorrador}
                  >
                    Guardar Borrador
                  </Button>
                </div>
                <div className="col">
                  <Button
                    className="btn-gradient-style"
                    disabled={!this.state.validData}
                    onClick={this.togglePublish}
                  >
                    {this.state.area_admin === "smartlearning"
                      ? this.state.edit
                        ? "Editar Entrada"
                        : "Crear Entrada"
                      : "Publicar Entrada"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.edit && this.state.comments ? (
          <div>
            <div className="main_content listado">
              <div className="rounded_cont contents shadow">
                <div className="row">
                  <div className="col">
                    <SearchListFilterComponent
                      setLoading={this.setLoading}
                      loadFilter={this.loadFilter}
                      model={this.state.model}
                      start={this.state.start}
                      limit={this.state.limit}
                      sort={this.state.sort}
                      field="comments"
                      placeholder="Buscar comentario"
                      defaultfilter={this.state.defaultfilter}
                    />
                    <ReactTable
                      data={this.state.comentarios}
                      manual
                      pages={this.state.total}
                      defaultPageSize={this.state.limit}
                      onFetchData={this.fetchData}
                      loading={this.state.loading}
                      columns={[
                        {
                          Header: "",
                          accessor: "username",
                          width: 120,
                          Cell: row => (
                            <div className="image shadow">
                              <img
                                onClick={() => this.onClick(row.value)}
                                src={
                                  row.value != null
                                    ? row.value
                                    : "/img/userplaceholder.jpg"
                                }
                                alt=""
                              />
                            </div>
                          )
                        },
                        {
                          Header: "Nombre del archivo",
                          accessor: "comments",
                          headerClassName: "file",
                          Cell: row => <div className="file">{row.value}</div>
                        },
                        {
                          Header: "Fecha",
                          accessor: "publish_date",
                          width: 180,
                          Cell: row => (
                            <div className="updated-at">
                              <Moment format="DD/MM/YY HH:mm">
                                {row.value}
                              </Moment>
                            </div>
                          )
                        },
                        {
                          Header: "Eliminar",
                          accessor: "_id",
                          width: 100,
                          Cell: row => (
                            <div className="delete">
                              <span
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "¿Estás seguro que quieres borrar esto?"
                                    )
                                  ) {
                                    this.deleteComment(row.value);
                                  }
                                }}
                              >
                                {" "}
                                <i className="fas fa-trash-alt" />
                              </span>
                            </div>
                          )
                        }
                      ]}
                      className="-striped -highlight"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="main_content listado modal-registry ">
              <div className="rounded_cont comments shadow">
                <div className="row">
                  <div className="col">
                    <span className="avatar">
                      {auth.getUserInfo().username}{" "}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <input
                      type="text"
                      placeholder="Introduce tu comentario"
                      name="comment"
                      value={this.state.comment}
                      onChange={comment =>
                        this.setState({ comment: comment.target.value })
                      }
                    />
                  </div>
                  <div className="col-3">
                    <Button color="primary" onClick={() => this.addComent()}>
                      {" "}
                      Comentar{" "}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

NewContent.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ['link'],
    ["clean"]
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
NewContent.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video"
];

export default NewContent;
