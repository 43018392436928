import React, { Component } from 'react';
import request from '../../utils/request';
import moment from 'moment';
import constantes from '../../utils/constantes';
import './styles.css';
import { Button, Row, Col } from 'reactstrap';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";





class Metricas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ninmuebles: 0,
      nprescriptores: 0,
      nseguimientos: 0,
      nnotas: 0,
      primeraVisita: 0,
      segundaVisita: 0,
      cerrado: 0,
      start_date: new Date(moment().subtract(1, 'months').format("YYYY-MM-DD")),
      end_date: new Date(moment().format("YYYY-MM-DD")),
      retosactivos: 0,
      ncompletados: 0,
      nactivos: 0,
      usuarioretosempezados: 0,
      storeretosempezados: 0,
      storeretoscompletados: 0,
      rankingstores: 0,
      usuarios: [],
      usuariosActivos: [],
      usuariosInactivos: [],
    };
    this.getNinmuebles = this.getNinmuebles.bind(this);
    this.getNnotas = this.getNnotas.bind(this);
    this.getNprescriptores = this.getNprescriptores.bind(this);
  }


  getRatiocompletadosempezados() {
    request(constantes("URL_API") + "/avanceretos/count?activo=true&_limit=0", { method: "GET" })
      .then((response) => this.setState({
        nactivos: response
      })).catch((err) => {
        if (err.response.payload.statusCode === 404) {
          this.setState({
            nactivos: 0
          })
        }
      }
      );
    request(constantes("URL_API") + "/avanceretos/count?activo=true&acabado=true&_limit=0", { method: "GET" })
      .then((response) => this.setState({
        ncompletados: response
      })).catch((err) => {
        if (err.response.payload.statusCode === 404) {
          this.setState({
            ncompletados: 0
          })
        }
      }
      );
  }

  getRetosactivos() {
    request(constantes("URL_API") + "/graphql?query=%7B%0A%20%20avs(where%3A%20%7Bstate%3A%20%22iniciado%22%20%7D)%20%7B%0A%20%20%20%20_id%0A%20%20%20%09state%0A%20%20%20%20user%7B%0A%20%20%20%20%20%20username%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D%0A", { method: "GET" })
      .then(async (response) =>
        this.setState({
          retosactivos: await new Set(response.data.avs.map(avancereto => avancereto.user.username)).size
        }))
  }

  getNinmuebles() {
    request(constantes("URL_API") + "/inmuebles/count?_limit=0&createdAt_gt=" + this.state.start_date + "&createdAt_lt=" + this.state.end_date, { method: "GET" })
      .then((response) => {
        console.log(response)
        this.setState({
          ninmuebles: response
        })
      }).catch((err) => {
        if (err.response.payload.statusCode === 404) {
          this.setState({
            ninmuebles: "No hay inmuebles para el rango seleccionado"
          })
        }
      }
      );
  }

  getNprescriptores() {
    request(constantes("URL_API") + "/prescriptors/count?_limit=0&createdAt_gt=" + this.state.start_date + "&createdAt_lt=" + this.state.end_date, { method: "GET" })
      .then((response) => this.setState({
        nprescriptores: response
      })).catch((err) => {
        if (err.response.payload.statusCode === 404) {
          this.setState({
            nprescriptores: "No hay prescriptores para el rango seleccionado"
          })
        }
      }
      );
  }

  getNseguimientos() {
    request(constantes("URL_API") + "/graphql?query=%7B%0A%20%20notificacions(limit%3A%200,where%3A%20%7BcreatedAt_gt%3A%20%22" + this.state.start_date + "%22,%20createdAt_lt%3A%20%22" + this.state.end_date + "%22%7D)%7B%0A%20%20%20%20createdAt%0A%20%20%20%20prescriptor%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%20%20inmueble%7B_id%7D%0A%20%20%20%20entrada%7B_id%7D%0A%20%20%7D%0A%7D", { method: "GET" })
      .then((response) => this.setState({
        nseguimientos: response.data.notificacions.filter(noti => noti.prescriptors || noti.inmuebles).length
      })).catch((err) => {
        if (err.response.payload.statusCode === 404) {
          this.setState({
            nseguimientos: "No hay seguimientos para el rango seleccionado"
          })
        }
      }
      );
  }

  getNnotas() {
    request(constantes("URL_API") + "/notas/count?_limit=0&createdAt_gt=" + this.state.start_date + "&createdAt_lt=" + this.state.end_date, { method: "GET" })
      .then((response) => this.setState({
        nnotas: response
      })).catch((err) => {
        if (err.response.payload.statusCode === 404) {
          this.setState({
            nnotas: "No hay notas para el rango seleccionado"
          })
        }
      }
      );
  }

  getNprimeraVisita() {
    request(constantes("URL_API") + "/inmuebles/count?_limit=0&state=2&createdAt_gt=" + this.state.start_date + "&createdAt_lt=" + this.state.end_date, { method: "GET" })
      .then((response) => this.setState({
        primeraVisita: response
      })).catch((err) => {
        if (err.response.payload.statusCode === 404) {
          this.setState({
            primeraVisita: "No hay inmuebles que han pasado a primera visita para el rango seleccionado"
          })
        }
      }
      );
  }

  getNsegundaVisita() {
    request(constantes("URL_API") + "/inmuebles/count?_limit=0&state=3&createdAt_gt=" + this.state.start_date + "&createdAt_lt=" + this.state.end_date, { method: "GET" })
      .then((response) => this.setState({
        segundaVisita: response
      })).catch((err) => {
        if (err.response.payload.statusCode === 404) {
          this.setState({
            segundaVisita: "No hay inmuebles que han pasado a segunda visita para el rango seleccionado"
          })
        }
      }
      );
  }


  getUsers() {
    let consulta = "/users";
    request(constantes("URL_API") + consulta, { method: "GET" })
      .then(datos => {
        let zona = localStorage.getItem("admin");
        this.setState({
          usuarios: datos.filter(e => e.zonas_app[zona] === true),
          usuariosActivos: datos.filter(e => e.zonas_app[zona] === true).filter((a) => a.status === true),
          usuariosInactivos: datos.filter(e => e.zonas_app[zona] === true).filter((a) => a.status !== true),
        })
      }
      )

  }

  getNcerrado() {
    request(constantes("URL_API") + "/inmuebles/count?_limit=0&state=4&createdAt_gt=" + this.state.start_date + "&createdAt_lt=" + this.state.end_date, { method: "GET" })
      .then((response) => this.setState({
        cerrado: response
      })).catch((err) => {
        if (err.response.payload.statusCode === 404) {
          this.setState({
            cerrado: "No hay inmuebles que han pasado a cerrado para el rango seleccionado"
          })
        }
      }
      );
  }

  async componentDidMount() {
    this.getNinmuebles();
    this.getNprescriptores();
    this.getNnotas();
    this.getNprimeraVisita();
    this.getNsegundaVisita();
    this.getNcerrado();
    this.getNseguimientos();
    this.getRetosactivos();
    this.getRatiocompletadosempezados();
    this.getUsers();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.start_date !== prevState.start_date || this.state.end_date !== prevState.end_date) {
      this.getNinmuebles();
      this.getNprescriptores();
      this.getNnotas();
      this.getNprimeraVisita();
      this.getNsegundaVisita();
      this.getNcerrado();
      this.getNseguimientos();
    }
  }


  render() {
    return (
      <Row>
        <div className="col main_content ">
          <h2 className="text-metricas">Métricas</h2>
          <Row>
            <Col>
              <div className="content-card">

              </div>
            </Col>
            <Col xs="4">
              <div className="content-card users">
                <h3 className="titulo-metricas">Usuarios Activos</h3>
                <Progress
                  percent={Math.round((this.state.usuariosActivos.length * 100)/this.state.usuarios.length)}
                  theme={{

                    active: {
                      color: '#28a745'
                    },
                    default: {
                      symbol: '',
                      color: '#fbc630'
                    }
                  }}
                />
                <h3 className="titulo-metricas">Usuarios inactivos</h3>
                <Progress
                  percent={Math.round((this.state.usuariosInactivos.length * 100)/this.state.usuarios.length)}
                  theme={{
                    active: {
                      color: '#c90000'
                    },
                    default: {
                      symbol: '',
                      color: '#fbc630'
                    }
                  }}

                />

                <h3 className="titulo-metricas">Total Usuarios</h3>
                <h4>{this.state.usuarios.length}</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="content-card card-small">
                <h4>Retos</h4>
                <div className="container-retos">
                  <div>
                    <p>Usuarios con retos activos</p>
                    <Progress
                      type="circle"
                      width={50}
                      percent={this.state.retosactivos}
                    />
                  </div>

                  <div>
                    <p>Store: retos empezados</p>
                    <Progress
                      type="circle"
                      width={50}
                      percent={this.state.storeretosempezados}
                    />
                  </div>

                  <div>
                    <p>Store: retos completados</p>
                    <Progress
                      type="circle"
                      width={50}
                      percent={this.state.storeretoscompletados}
                    />

                  </div>

                  <div>
                    <p>Completado de retos</p>
                    <p className="number">{this.state.ncompletados} / {this.state.nactivos}</p>
                  </div>
                </div>


              </div>
            </Col>
            <Col>
              <div className="content-card card-small ">
                <h4>Farming</h4>
                {/* <DatePicker onChange={ date => this.setState({ start_date: date }) } value={ this.state.start_date } /><DatePicker onChange={ date => this.setState({ end_date: date }) } value={ this.state.end_date } /> */}
                <p>Inmuebles introducidos | <span className="number">{this.state.ninmuebles}</span></p>
                <p>Prescriptores | <span className="number">{this.state.nprescriptores}</span></p>
                <p>Seguimientos | <span className="number">{this.state.nseguimientos}</span></p>
                <p>Notas | <span className="number">{this.state.nnotas}</span></p>
                <p>Inmuebles para primera visita | <span className="number">{this.state.primeraVisita}</span></p>
                <p>Inmuebles para segunda visita | <span className="number">{this.state.segundaVisita}</span></p>
                <p>Inmuebles cerrados | <span className="number">{this.state.cerrado}</span></p>

              </div>
            </Col>
            <Col>
              <div className="content-card card-small ">
              <h4>Contenidos más vistos</h4>
                <div className="container-retos">
                <div className="contaners-hijos-retos">
                <img className="pictures-mas-vistos" src="https://picsum.photos/g/400/300" alt=""></img>
                <p className="numbers-mas-vistos">20</p>
                <p className="jk">Mas ventas</p>
                </div>

                <div className="contaners-hijos-retos">
                <img className="pictures-mas-vistos" src="https://picsum.photos/g/400/300" alt=""></img>
                <p className="numbers-mas-vistos">38</p>
                <p>Tu primer inmueble</p>
                </div>

                <div className="contaners-hijos-retos">
                <img className="pictures-mas-vistos" src="https://picsum.photos/g/400/300" alt=""></img>
                <p className="numbers-mas-vistos">38</p>
                <p>Tu primer inmueble</p>
                </div>

                <div className="contaners-hijos-retos">
                <img className="pictures-mas-vistos" src="https://picsum.photos/g/400/300" alt=""></img>
                <p className="numbers-mas-vistos">38</p>
                <p>Tu primer inmueble</p>
                </div>


                </div>
              </div>
            </Col>
          </Row>
          <div className="col-4">
            <div className="col">
              <Button color="primary" onClick={() => this.toggle()} >Descargar informe</Button>
            </div>
          </div>
        </div>
      </Row>

    );
  }
}

export default Metricas;