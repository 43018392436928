import React, {Component} from 'react';
import ReactTable from "react-table";
import { Link } from 'react-router-dom';
import request from '../../utils/request';
import './styles.css';
import TimeContentCard from '../../components/TimeContentCard/';
import constantes from '../../utils/constantes';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SearchListFilterComponent from '../../components/SearchListFilterComponent';
import SearchListServerSide from '../../utils/SearchListServerSide';


class Comments extends Component {
  constructor(props){
    super(props);
    this.state = {
        comentarios: [],
        selectBloque: [],
        toggleOjo: false,
        limit: 10,
        start: 0,
        total: 0,
        sort: [{id:"publish_date",desc:"true"}],
        loading: false,
        model: "comentarios",
        filter: [],
        modalEditComment: false,
        area_admin: true
    };
    //this.printComments = this.printComments.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.gestionaBloque = this.gestionaBloque.bind(this);
    this.setLoading = this.setLoading.bind(this); 
    this.loadFilter = this.loadFilter.bind(this); 
    this.editComment = this.editComment.bind(this); 
    this.toggleEditComment = this.toggleEditComment.bind(this); 
    this.confirmEditComment = this.confirmEditComment.bind(this); 
    this.handleChange = this.handleChange.bind(this); 
  }
  handleChange(value){
    this.setState({
      editComment: value.target.value
    })
  }
  async confirmEditComment(){
    await request(constantes("URL_API")+"/comentarios/"+this.state.commentid,
     { method: "PUT", body: {comments: this.state.editComment} })
    .then((response) => {
      alert("Mensaje editado con exito")
      this.toggleEditComment();
      this.componentDidMount();
    });
  }
  toggleEditComment() {
    this.setState({
      modalEditComment: !this.state.modalEditComment,
    });
  }
  async editComment(id){
    this.setState({
      loading: true,
      commentid: id
    })
    await request(constantes("URL_API")+"/comentarios/"+id, { method: "GET" })
    .then((response) => {
      this.setState({
        editComment: response.comments,
        loading: false,
        modalEditComment: true
      });
    });
  }
  fetchData(state,instance){
    this.setState({
      start: instance.state.page * instance.state.pageSize,
      limit: instance.state.pageSize,
      sort: state.sorted,
      filter: this.state.filter
    })
  }
  setLoading(loading){
    this.setState({
      loading
    });
  }
  loadFilter(response,filter){
    this.setState({
      comentarios: response,
      loading: false,
      filter: filter
    });
  }
  gestionaBloque = (id) => {
    let array = this.state.selectBloque;
    if (array.includes(id))
    {
      //eliminar elemento del array
      var index = array.indexOf(id);
      if (index > -1) {
      array.splice(index, 1);
    }
      
    } else {
      //Añadir al array
      array.push(id)
    };
    this.setState({selectBloque: array});
  }
  actualizaComentarios(id){
    const comentariosMod= [...this.state.comentarios]; //copia el estado
    comentariosMod.find(e => e._id===id).visible = !(comentariosMod.find(e => e._id===id).visible); 
    this.setState({comentarios : comentariosMod});
  }
 
  deleteComment(id){
    request(constantes("URL_API") + "/comentarios/"+id, { method: "DELETE" })
    .then((response) => this.componentDidMount());
  }
  
 async componentDidUpdate(prevProps, prevState){

    if(prevState.start !== this.state.start || prevState.limit !== this.state.limit || prevState.sort !== this.state.sort || prevState.filter !== this.state.filter){
      this.setState({
        loading: true
      });
      await SearchListServerSide(this.state.model, 0, 0, this.state.sort, this.state.area_admin, this.state.filter, this.state.defaultfilter)
      .then((response) => {
        if(!response){
          localStorage.clear();
          this.props.history.push('/auth/login');
        }else{
          this.setState({
            total: response.length
          })
        }
    });
      await SearchListServerSide(this.state.model, this.state.start, this.state.limit, this.state.sort, this.state.area_admin, this.state.filter)
      .then((response) => {
        if(!response){
          localStorage.clear();
          this.props.history.push('/auth/login');
        }else{
          this.setState({
            comentarios: response,
            loading:false
          })
        }
    });  
    }
  }

  async componentDidMount(){
    this.setState({
      loading: true
    });
    await SearchListServerSide(this.state.model, 0, 0, this.state.sort, this.state.area_admin, this.state.filter, this.state.defaultfilter)
    .then((response) => {
      if(!response){
        localStorage.clear();
        this.props.history.push('/auth/login');
      }else{
        this.setState({
          total: response.length
        })
      }
  });
    await SearchListServerSide(this.state.model, this.state.start, this.state.limit, this.state.sort, this.state.area_admin, this.state.filter)   
    .then((response) => {
      if(!response){
        localStorage.clear();
        this.props.history.push('/auth/login');
      }else{
        this.setState({
          comentarios: response
        })
      }
  });   
    this.setState({
      loading: false
    })
  }
  render() {
    
      return(
        <div className = "main_content listado">
          <Modal isOpen={this.state.modalEditComment} toggle={this.toggleEditComment}>
            <ModalHeader className="modalHeader" toggle={this.toggleEditComment}>Edita tu comentario</ModalHeader>
            <ModalBody>
              <div className = "row">
                <div className = "col">
                  <input name="comments" value={this.state.editComment} onChange={(event) => this.handleChange(event)} type="text" placeholder="Comentario"/>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.confirmEditComment}>Confirmar</Button>
              <Button color="secondary" onClick={this.toggleEditComment}>Cancelar</Button>
            </ModalFooter>
          </Modal>
          <div><h3>Comentarios</h3></div>
          <div className="rounded_cont contents shadow">
            <div className="row">            
              <div className="col">
              <SearchListFilterComponent setLoading={this.setLoading} loadFilter={this.loadFilter} model={this.state.model} start={this.state.start} limit={this.state.limit} sort={this.state.sort} field="comments" placeholder="Comentario"/>           
                  <ReactTable
                    manual
                    defaultSorted={this.state.sort}
                    pages={ parseInt(this.state.total/this.state.limit, 10) < (this.state.total/this.state.limit) ? (parseInt(this.state.total/this.state.limit, 10) + 1) : (this.state.total/this.state.limit) }
                    defaultPageSize={this.state.limit}
                    onFetchData={this.fetchData} // Request new data when things change
                    loading={this.state.loading}
                    data={this.state.comentarios}
                    
                    // filterable
                    // defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                    
                    columns={[                      
                      {
                        Header: '',                        
                        accessor: "_id",
                        width: 60,
                        Cell: row => (
                        <div className=""><input name={row.value} id={row.value} type="checkbox" onChange={() => this.gestionaBloque(row.value)} checked={this.state.selectBloque.includes(row.value)?true:false}/>
                        <div className="state"></div></div>                     
                          )
                      },
                      {
                        Header: 'Comentario',                        
                        accessor: "comments",
                        headerClassName: "comment",
                        Cell: row => (
                          <div className = "comment">
                            {/* <textarea>{ row.value }</textarea> */}
                            { row.value }
                          </div>
                        )
                      },
                      {
                        Header: 'Entrada',                        
                        accessor: "entrada",
                        headerClassName: "comment",
                        Cell: row => (
                          <div className = "comment">
                            {row.original.entrada?
                            <Link to={"/edit/"+ row.original.entrada._id}>{row.original.entrada.title}</Link>:''}
                          </div>
                        )
                      },
                      {
                        Header: 'Usuario',                        
                        accessor: "user.username",
                        headerClassName: "author",
                        width: 180,
                        Cell: row => (
                          <div className = "author">
                            { row.value===undefined?<i>Usuario eliminado</i>:row.value } 
                          </div>
                        ),
                        // filterMethod: (filter, row) =>
                        //   row[filter.id].startsWith(filter.value) &&
                        //   row[filter.id].endsWith(filter.value)
                      },
                      {
                        Header: "Fecha",
                        accessor: "publish_date",
                        width: 120,
                        Cell: row => (
                          <div className = "publish_date">
                            <TimeContentCard time={ row.value }/>
                          </div>
                         )
                      },                      
                      {
                        Header: 'Visible',                        
                        accessor: "visible",
                        headerClassName: "visible",
                        width: 100,
                        Cell: row => (
                          <div className = "visible">
                            {row.value===!true?<span onClick=
                            {() =>{request(constantes("URL_API") + "/comentarios/"+row.original._id,  
                            {method: "PUT", body: {visible:!row.value}}).then(() => this.actualizaComentarios(row.original._id)).catch( err => {console.log( err )})}}> <img src="/img/ico/icon_noshow.png" alt=""/></span>:
                            <span onClick=
                            {() =>{request(constantes("URL_API") + "/comentarios/"+row.original._id, 
                            {method: "PUT",body: {visible:!row.value}}).then(() => this.actualizaComentarios(row.original._id)).catch( err => {console.log( err )})}}> <img src="/img/ico/icon_show.png" alt=""/></span>}
                          </div>
                        )
                      },
                      {
                        Header: "Modificar",
                        accessor: "_id",
                        width: 100,
                        Cell: row => (
                          <div className = "edit">
                            <span onClick={ () => {this.editComment(row.value)}
                              } > <i className="fas fa-edit"></i> 
                            </span>
                          </div>
                        )
                      },
                      {
                        Header: "Eliminar",
                        accessor: "_id",
                        width: 100,
                        Cell: row => (
                          <div className = "delete">
                            <span onClick={ () => {
                                  if (window.confirm("¿Estás seguro que quieres borrar esto?")){
                                    this.deleteComment(row.value)
                                  }
                                  //console.log(row.value);
                                }
                              } > <i className="fas fa-trash-alt"></i> 
                            </span>
                          </div>
                        )
                      }
                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                  />

            </div>
            {this.state.selectBloque.length > 0?
              <div className="col-12">
                <div className="row menuBloque">
                  <div className="col-10"> <span >Has seleccionado {this.state.selectBloque.length} {this.state.selectBloque.length<2?"comentario":"comentarios"}.</span>
                    </div>
                    <div className="col-2 text-center"> <span onClick={ () => {
                                if (window.confirm("¿Estás seguro que quieres borrar estos comentarios?")){
                                  this.state.selectBloque.forEach((element)=>this.deleteComment(element))                                                                    
                                };
                                this.setState({selectBloque:[]});
                              }
                            } > <i className="fas fa-trash-alt"></i> 
                          </span>
                    </div>
                </div>
                  
              </div>:""}
          </div>
        </div>
      </div>
      );
  }
}

export default Comments;
