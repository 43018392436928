/* Componente que retorna array con las necesidades de paginacion, sorting y filtering */
import constantes from './constantes';
import request from './request';

const SearchListServerSide = async (model, start, limit, sort, area_admin, filter, defaultfilter, enviaString, extra) => {
    console.log({ area_admin })
    // const SearchListServerSide = async (model, start, limit, sort, filter, defaultfilter) => {


    //creamos la variable url y le concatenamos start limit y model
    //adaptado para Strapi /endpoint
    let data;
    let url = "/" + model + "?_start=" + start + "&_limit=" + limit;

    if (sort && sort.length !== 0) {
        //recorro el array de sort {id,desc}
        //?_sort=id:asc
        //esta pensado para solo 1 sort ahora mismo
        url = url + "&_sort=";
        sort.map((item) => {
            let desc = item.desc ? "desc" : "asc";
            url = url + item.id + ":" + desc;
        })
    }

    if (defaultfilter && defaultfilter.length !== 0) {
        //recorro el array de filter {id:, value}
        //?_sort=id:asc
        defaultfilter.map((item) => {
            if (item.value !== "") {
                // console.log(url + "&" + item.id + (item.compare?"_"+item.compare:"") + "=" +item.value);
                url = url + "&" + item.id + (item.compare ? "_" + item.compare : "") + "=" + item.value;
            }
        })
    }

    if (filter && filter.length !== 0) {
        //recorro el array de filter {id:, value}
        //?_sort=id:asc
        filter.map((item) => {
            if (item.id === "publish_date") {
                if (item.value !== "") {
                    let refDate = new Date(item.value)
                    let bDate = encodeURIComponent(refDate)
                    if (enviaString !== undefined) {
                        enviaString("&" + item.id + "_gte=" + bDate);
                    }
                    if (extra !== undefined) { url = url + "&" + item.id + "_gte=" + bDate + extra; } else { url = url + "&" + item.id + "_gte=" + bDate; }
                    // url = url + "&" + item.id + "_gte=" + bDate;
                } else {
                    if (enviaString !== undefined) {
                        enviaString(undefined);
                    }
                    if (extra !== undefined) { url = url + extra; } else { url = url; }
                }
            } else {
                if (item.value !== "") {
                    if (enviaString !== undefined) {
                        enviaString("&" + item.id + "_containss=" + item.value.trim());
                    }
                    if (extra !== undefined) { url = url + extra + "&" + item.id + "_containss=" + item.value; } else { url = url + "&" + item.id + "_containss=" + item.value; }
                    // url = url + "&" + item.id + "_containss="+item.value;
                } else {
                    if (enviaString !== undefined) {
                        enviaString(undefined);
                    }
                    if (extra !== undefined) { url = url + extra; } else { url = url; }
                }
            }
        })
    }

    // console.log(constantes("URL_API")+url);
    // console.log({
    //     model,start,limit,sort,filter,url:constantes("URL_API")+url,defaultfilter
    // });
    let request_ql;
    area_admin ?
        request_ql = constantes("URL_API") + url + "&area_admin=" + localStorage.getItem('admin')
        :
        request_ql = constantes("URL_API") + url;

    await request(request_ql, { method: "GET" })
        .then((response) => {
            data = response;
        }).catch((err) => {
            localStorage.clear();
        });
    return data;
}

export default SearchListServerSide;