import React, { Component } from "react";
import { Container, Col, Row } from "reactstrap";
import request from "../../utils/request";
import constantes from "../../utils/constantes";
import "./styles.css";
import Loading from "../../components/Loading";
import TablaExamenes from "./TablaExamenes";
import ModalExamen from "./ModalExamen";

class ExamRef extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      exams: []
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    request(constantes("URL_API") + "/exams", {
      method: "GET"
    }).then(exams => {
      this.setState({ exams, loading: false });
    });
  };

  render() {
    return (
      <Container className="main_content listado">
        <div>
          <h3>Exámenes</h3>
        </div>
        <Loading loading={this.state.loading} />
        <div className="rounded_cont contents shadow">
          <Row>
            <Col>
              <TablaExamenes
                exams={this.state.exams}
                componentDidMount={this.componentDidMount}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ModalExamen componentDidMount={this.componentDidMount} />
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}
export default ExamRef;
