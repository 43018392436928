import React from 'react';
import './styles.css';

export default function AnalyticBoxDuracion (props){


    return(
        <div>
          <div className="rounded_contAn contents shadow col statCol">
                    <div >
                      <span className="statText">{props.texto}</span><br/> 
                      <span className="statText">{props.texto2}</span>  
                      
                    </div>
                </div>
        </div>
    )
  }

    