import React from 'react';
import Select from "react-select";
import './styles.css';

export default function FiltroEntrada (props){

    return(
    <div>
        <Select
            options={props.filtro}
            className="form-control rounded-select rounded_cont shadow"
            styles={{marginHorizonal: "10px"}}
            placeholder={props.placeholder}
            onChange={props.handleChange}
            value={props.value}
        />
    </div>
)
    


}
