import React, {Component} from 'react';
import './styles.css';
import Moment from 'react-moment';



var moment = require('moment');

class TimeContentCard extends Component {
    render() {
        moment.locale('es');
        var ahora = moment(new Date());
        var tiempo =  moment(this.props.time); 
        var duration = moment.duration(ahora.diff(tiempo));
        var difTime = duration.asDays();
        const tiempoMostrar = moment(tiempo).fromNow()
        function haceTiempo(string){
            return string.charAt(0).toUpperCase() + string.slice(1);
          }
       
        if (difTime > 7) {
            return (     
                        <span>
                             {this.props.horas===true?<Moment format="DD/MM/YYYY hh:mm">{tiempo}</Moment>:<Moment format="DD/MM/YYYY">{tiempo}</Moment>}
                        </span> 
                    )
        } else {
            return (     
                <span>
                    {haceTiempo(tiempoMostrar)}
                </span> 
                    )
        }
        
    }
}

export default TimeContentCard;
