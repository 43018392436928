import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

class Logo extends Component {

  render() {
      return(
        <div className="logo">
          <Link to = { "./" } ><img src={ "https://training-solvia.s3.eu-west-1.amazonaws.com/fd52aa28c4e64a57ab27bca7ef28140f.png" } alt="logo"></img></Link>
        </div>
      );
  }
}

export default Logo;
