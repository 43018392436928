import React, { Component } from "react";
import ContenidosMasVistos from "../../components/ContenidosMasVistos";
import UsersActivosContent from "../../components/UsersActivosContent";
import AnalyticBoxUsers from "../../components/AnalyticBoxUsers";
import AnalyticBoxSesiones from "../../components/AnalyticBoxSesiones";
import AnalyticBoxDuracion from "../../components/AnalyticBoxDuracion";
import DateTimePicker from "react-datetime-picker";
import request from "../../utils/request";
import constantes from "../../utils/constantes";
import _ from "lodash";
import "./styles.css";
var moment = require("moment");

export default class GeneralAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AnContenidos: [],
      AnContenidosExport: [],
      users: [],
      logsContent: [],
      entradasAn: [],
      initDate: new Date(
        moment(new Date())
          .subtract(60, "days")
          .utc()
          .format()
        // 2019,6,1
      ),
      endDate: new Date(),
      meses: [
        { value: "1", label: "Enero" },
        { value: "2", label: "Febrero" },
        { value: "3", label: "Marzo" },
        { value: "4", label: "Abril" },
        { value: "5", label: "Mayo" },
        { value: "6", label: "Junio" },
        { value: "7", label: "Julio" },
        { value: "8", label: "Agosto" },
        { value: "9", label: "Septiembre" },
        { value: "10", label: "Octubre" },
        { value: "11", label: "Noviembre" },
        { value: "12", label: "Diciembre" }
      ],
      mesSelected: [],
      refDate: moment(new Date())
        .subtract(7, "days")
        .utc()
        .format(),
      sessions: 0,
      prevDate: moment(new Date())
        .subtract(7, "days")
        .utc()
        .format(),
      conjuntoSesiones: [],
      duracionMedia: 0
    };
  }

  lambdaActiveUsers = async (date, uri, token) => {
    const startDate = moment(date)
      .startOf("day")
      .utc()
      .format();
    const endDate = moment(date)
      .endOf("day")
      .utc()
      .format();

    const finalUri = `${uri}/graphql?query=%7B%0A%20%20logs(where%3A%20%7BcreatedAt_gte%3A%20%22${startDate}%22,%20createdAt_lte%3A%22${endDate}%22%7D,%20limit%3A0)%7B%0A%20%20%20%20pagina%0A%20%20%20%20idpagina%0A%20%20%20%20createdAt%0A%20%20%20%20user%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20email%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D`;
    const response = await fetch(finalUri, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }).then(data => data.json());

    // await fetch(`${uri}/contentanalitics`, {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`
    //   },
    //   body: JSON.stringify({
    //     date: moment(date).utc().format(),
    //     activeUser: _.uniqBy(response.data.logs) ///aqui saco los users de los logs traidos por query, y hago uniqBy
    //   })
    // });
  };

  calculaSession = () => {
    this.state.users.map((user, i) => {
      var logsUsuario = user.logs
        .filter(
          log =>
            new Date(log.createdAt) > this.state.initDate &&
            new Date(log.createdAt) < this.state.endDate
        )
        .sort(function(a, b) {
          a = new Date(a.createdAt);
          b = new Date(b.createdAt);
          return a > b ? 1 : a < b ? -1 : 0;
        });
      const finesDeSesion = logsUsuario
        .map((log, i, array) =>
          moment(array[i - 1] !== undefined && array[i - 1].createdAt).diff(
            moment(log.createrAt),
            "minutes"
          ) > 15
            ? i - 1
            : log.pagina === "Fin de sesión"
            ? i
            : null
        )
        .filter(e => e);

      const eventos = finesDeSesion.map((fin, i) =>
        logsUsuario.slice(i === 0 ? 0 : finesDeSesion[i - 1] + 1, fin + 1)
      );
      let sesiones = eventos
        .map((sesion, i) => ({
          inicio: moment(sesion[0].createdAt).format(),
          user: user._id,
          duracion: moment(sesion[sesion.length - 1].createdAt).diff(
            sesion[0].createdAt
          ),
          eventos: sesion
            .map((ev, j) => ({
              ...ev,
              tiempo:
                j !== eventos[i].length - 1 &&
                moment(eventos[i][j + 1].createdAt).diff(moment(ev.createdAt))
            }))
            .slice(0, -1)
          // .filter(ev => ev.tiempo > 0 && ev.tiempo < 1000)
        }))
        .filter(sesion => sesion.eventos.length > 0);

      sesiones.forEach(sesion => {
        sesion.duracion = sesion.eventos.reduce(
          (a, b) => ({ tiempo: a.tiempo + b.tiempo }),
          { tiempo: 0 }
        ).tiempo;
      });

      var b = sesiones;
      if (b.length > 0) {
        b.map(log => {
          var a = [...this.state.conjuntoSesiones];
          a.push(log);
          this.setState({
            conjuntoSesiones: a,
            duracionMedia:
              a
                .map(e => e.duracion)
                .reduce((previous, current) => (current += previous)) / a.length,
              usersExport: this.state.users.map(u => ({
                  email: u.email,
                  sesiones: a.filter(e => e.user === u._id).length,
                  tiempoMedio: a.filter(e => e.user === u._id).length>0?
                  moment(a.filter(e => e.user === u._id).map(e =>e.duracion).reduce((previous, current) => current += previous)/a.filter(e => e.user === u._id).length).format("mm:ss"):0,
                  tiempoTotal: a.filter(e => e.user === u._id).length>0?
                  moment(a.filter(e => e.user === u._id).map(e =>e.duracion).reduce((previous, current) => current += previous)).format("mm:ss"):0
      
                }))
              });
        });
      }
      return {
        // ...usuario,
        sesiones
      };

      // });
    });
  };

  calculaContenidos = async () => {
    let fechaini = moment.utc(this.state.initDate, "YYYY-MM-DD").format();
    let fechafin = moment
      .utc(this.state.endDate, "YYYY-MM-DD")
      .add(1, "days")
      .format();

    let queryLogsContents =
      "/graphql?query=%7B%0A%20%20logs(where%3A%20%7B%20createdAt_gte%3A%20%22" +
      fechaini +
      "%22,%20createdAt_lt%3A%20%22" +
      fechafin +
      "%22%20%7D,%20limit%3A%200)%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20createdAt%0A%20%20%20%20%20%20pagina%0A%20%20%20%20%20%20idpagina%0A%20%20%20%20%20%20user%7B%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%20%20name%0A%20%20%20%20%20%20%20%20surname%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%7D";
    await request(constantes("URL_API") + queryLogsContents, {
      method: "GET"
    }).then(response2 => {
      this.setState({
        logsContent: response2.data.logs
      });
    });

    let queryEntradas =
      "/graphql?query=%7B%0A%20%20entradas(limit%3A%200)%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20title%0A%20%20%20%20publish_date%0A%20%20%20%20%7D%0A%7D";
    await request(constantes("URL_API") + queryEntradas, {
      method: "GET"
    }).then(response3 => {
      this.setState({
        entradasAn: response3.data.entradas
      });
    });
    const listaUsuarios = _.uniqBy(
      this.state.logsContent.map(e => e.user),
      "_id"
    );
    const datosPorUsuario = listaUsuarios
      .map(usuario => {
        const logs = this.state.logsContent.filter(row => {
          return row.user !== null && usuario && row.user._id === usuario._id;
        });
        const finesDeSesion = logs
          .map((log, i) => (log.pagina === "Fin de sesión" ? i : null))
          .filter(e => e);
        const eventos = finesDeSesion.map((fin, i) =>
          logs.slice(i === 0 ? 0 : finesDeSesion[i - 1] + 1, fin + 1)
        );
        let sesiones = eventos
          .map((sesion, i) => ({
            inicio: moment(sesion[0].createdAt).format(),
            //duracion: moment(sesion[sesion.length - 1].createdAt).diff(sesion[0].createdAt, "seconds"),
            eventos: sesion
              .map((ev, j) => ({
                ...ev,
                titulo:
                  ev.idpagina &&
                  this.state.entradasAn.find(en => en._id === ev.idpagina)
                    ? this.state.entradasAn.find(en => en._id === ev.idpagina)
                        .title
                    : "-",
                tiempo:
                  j !== eventos[i].length - 1 &&
                  moment(eventos[i][j + 1].createdAt).diff(
                    moment(ev.createdAt),
                    "seconds"
                  )
              }))
              .slice(0, -1)
              .filter(ev => ev.tiempo > 0 && ev.tiempo < 1000)
          }))
          .filter(sesion => sesion.eventos.length > 0);

        sesiones.forEach(sesion => {
          sesion.duracion = sesion.eventos.reduce(
            (a, b) => ({ tiempo: a.tiempo + b.tiempo }),
            { tiempo: 0 }
          ).tiempo;
        });

        return {
          ...usuario,
          sesiones
        };
      })
      .filter(
        usuario =>
          usuario.sesiones.length > 0 &&
          ![
            "5be0539f80089c4f8ccfe0a7",
            "5bd8343346dfa1c5fcb437f4",
            "5bd835d43d34032797d76402"
          ].includes(usuario._id)
      );

    const contenidosRepes = this.state.logsContent.map(row => ({
      pagina: row.pagina,
      idpagina: row.idpagina,
      titulo:
        row.idpagina &&
        this.state.entradasAn.find(en => en._id === row.idpagina)
          ? this.state.entradasAn.find(en => en._id === row.idpagina).title
          : "-",
      fecha:
        row.idpagina &&
        this.state.entradasAn.find(en => en._id === row.idpagina)
          ? this.state.entradasAn.find(en => en._id === row.idpagina)
              .publish_date
          : "-"
    }));
    const listaContenidos = [
      ..._.uniqBy(contenidosRepes.filter(c => c.pagina === "Post"), "idpagina"),
      //..._.uniqBy(contenidosRepes.filter(c => c.pagina !== "Post"), "pagina")
    ];

    const todosLosEventos = _.flattenDeep(
      datosPorUsuario.map(u => u.sesiones.map(s => s.eventos))
    );
    const datosPorContenido = listaContenidos.map(cont => ({
      ...cont,
      visualizaciones: todosLosEventos.filter(ev => {
        if (ev.pagina === cont.pagina && ev.pagina !== "Post") {
          return true;
        } else if (
          ev.pagina === cont.pagina &&
          ev.pagina === "Post" &&
          ev.idpagina === cont.idpagina
        ) {
          return true;
        } else {
          return false;
        }
      })
    }));

    // console.log([...datosPorContenido])

    this.setState({
      AnContenidos: [...datosPorContenido],
      AnContenidosExport: [...datosPorContenido.map(cont => ({
        "Página": cont.titulo,
        // "Página": cont.pagina === "Post" ? "Post - " + cont.titulo : cont.pagina,
        "Visualizaciones": cont.visualizaciones.length,
        "Tiempo medio de visualización": Math.round((cont.visualizaciones.reduce((a, b) => ({ tiempo: a.tiempo + b.tiempo }), {tiempo: 0}).tiempo)/cont.visualizaciones.length * 100)/100 || 0,
        "fecha": cont.fecha
    }))]
      
    
    });
  };

  componentDidMount = () => {
    var consulta =
      "/graphql?query=%7B%0A%20%20%20%20users%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20email%0A%20%20%20%20%20%20logs%20(limit%3A2147483647,%20sort%3A%22createdAt%3Adesc%22)%7B%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%20%20createdAt%0A%20%20%20%20%20%20%20%20pagina%0A%20%20%20%20%20%20%20%20idpagina%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%20%20%0A%20%20%20%20%20%20%0A%7D";
    request(constantes("URL_API") + consulta, { method: "GET" }).then(
      response => {
        this.setState({
          users: response.data.users,          
        });
        this.calculaSession();
        //  this.state.users.map(user => console.log(user._id + " " + user.logs.length))
      }
    );

    this.calculaContenidos();
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.initDate !== prevState.initDate ||
      this.state.endDate !== prevState.endDate
    ) {
      this.calculaSession();
      this.calculaContenidos();
    }
    // if (this.state.users!==prevProps.users||this.state.conjuntoSesiones!==prevState.conjuntoSesiones||this.state.duracionMedia!==prevState.duracionMedia){
    //     this.setState({
    //         dailyRecord: {
    //             activeUsers: this.state.users.filter(e =>
    //                 e.logs.length>0
    //                 &&new Date(e.logs[0].createdAt)>this.state.initDate
    //                 && new Date(e.logs[0].createdAt)<this.state.endDate
    //                 ).length,
    //             totalUsers: this.state.users.length,
    //             sessions: this.state.conjuntoSesiones,
    //             averageSessionDuration: this.state.duracionMedia,
    //              contentsTable: this.state.AnContenidosExport,
    //              usersTable: this.state.usersExport
    //         }
    //     })
    // }
  };

  handleChangeMes = selectedOption => {
    this.setState({
      mesSelected: selectedOption
    });
  };

  render() {
    return (
      <div className="main_content listado">
        <div>
          <h3>Analítica</h3>
        </div>

        <div className="row">
          <div className="col pickerCol">
            <span style={{ color: "white" }}>Fecha inicio</span>
            <DateTimePicker
              className="pick"
              onChange={initDate => this.setState({ initDate })}
              value={this.state.initDate}
            />
          </div>
          <div className="col pickerCol">
            <span style={{ color: "white" }}>Fecha fin</span>
            <DateTimePicker
              className="pick"
              onChange={endDate => this.setState({ endDate })}
              value={this.state.endDate}
            />
          </div>
          {/* <div className="col selectCol">
                        <Select
                            options={this.state.meses}
                            onChange={()=>this.handleChangeMes()}
                            value={this.state.mesSelected}
                            placeholder={"Mes..."}
                        />
                    </div> */}
        </div>
        <div className="row">
          <div className="col">
            <AnalyticBoxUsers
              texto={"Usuarios activos"}
              users={this.state.users}
              initDate={this.state.initDate}
              endDate={this.state.endDate}
            />
          </div>
          <div className="col">
            <AnalyticBoxSesiones
              texto={"Sesiones"}
              refDate={this.state.refDate}
              sessions={this.state.conjuntoSesiones.length}
            />
          </div>
          <div className="col">
            <AnalyticBoxDuracion
              texto={"Duración media de la sesión (En minutos)"}
              refDate={this.state.refDate}
              texto2={moment(this.state.duracionMedia).format("mm:ss")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col" style={{ marginBottom: "40px" }}>
            <ContenidosMasVistos dataContent={this.state.AnContenidos} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <UsersActivosContent
              dataUsers={this.state.users}
              sesiones={this.state.conjuntoSesiones}
            />
          </div>
        </div>
      </div>
    );
  }
}
