import React, {Component} from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import ReactTable from "react-table";
import request from '../../utils/request';
import Moment from 'react-moment';
import './styles.css';
import constantes from '../../utils/constantes';
import UploadMedia from '../../components/UploadMedia';
import SearchListServerSide from '../../utils/SearchListServerSide';


class Media extends Component {
  constructor(props){
    super(props);
    this.state = {
        medias: [],
        selectBloque: [],
        modal: false,
        modalGaleria:false,
        photo: 'img/userplaceholder.jpg',
        image: 'img/userplaceholder.jpg',
        zonamedia: this.props.match.url.split("/")[1] === "media" ? true : false,
        bigPhoto: "",
        toggleBigPhoto: false,
        exportMedia: [],
        modalIMG: false,
        modalVIDEO:false,
        modalAudio:false,
        limit: 10,
        start: 0,
        loading: true,
        totalCount: 0,
        sort: [],
        filter: [],
        //defaultfilter: [{id:"area_admin",value: localStorage.getItem("admin")}],
        // defaultfilter: [{id:"area_admin",value: localStorage.getItem("admin")}],
        // defaultfilter: [{id:"name",value:"_ADM",compare:"contains"}],
        model: "upload/files",
        area_admin: false,
    };
    this.printMedias = this.printMedias.bind(this); 
    this.gestionaBloque = this.gestionaBloque.bind(this);
    this.borraBloque = this.borraBloque.bind(this);   
    this.deleteMedia = this.deleteMedia.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleIMG = this.toggleIMG.bind(this);
    this.toggleVIDEO = this.toggleVIDEO.bind(this);
    this.toggleAudio = this.toggleAudio.bind(this);
    this.exportMedia = this.exportMedia.bind(this);
    this.reload=this.reload.bind(this);
    this.fetchData=this.fetchData.bind(this);
    this.setLoading = this.setLoading.bind(this); 
    this.loadFilter = this.loadFilter.bind(this); 
    this.photoBig = this.photoBig.bind(this);
  }
  fetchData(state,instance){
    this.setState({
      start: instance.state.page * instance.state.pageSize,
      limit: instance.state.pageSize,
      sort: state.sorted,
      filter: this.state.filter
    })
  }
  setLoading(loading){
    this.setState({
      loading
    });
  }

  loadFilter(response,filter){
    this.setState({
      medias: response,
      loading: false,
      filter: filter,
      totalCount: response.length
    });
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  borraBloque(){
    // console.log("Voy a borrar!")
  }
  gestionaBloque = (id) => {
    let array = this.state.selectBloque;
    if (array.includes(id))
    {
      //eliminar elemento del array
      var index = array.indexOf(id);
      if (index > -1) {
      array.splice(index, 1);
    }
      
    } else {
      //Añadir al array
      array.push(id)
    };
    this.setState({selectBloque: array});
  }
  toggleIMG() {
    this.setState({
      modalIMG: !this.state.modalIMG
    });
  }
  toggleVIDEO() {
    this.setState({
      modalVIDEO: !this.state.modalVIDEO
    });
  }
  toggleAudio() {
    this.setState({
      modalAudio: !this.state.modalAudio
    });
  }
  deleteMedia(id){
    this.setState({
      loading: true
    });
    request(constantes("URL_API") + "/upload/files/"+id, { method: "DELETE" })
    .then((response) => this.componentDidMount());
  }
  exportMedia(array){    
    this.setState({
      exportMedia: array
    });

  }
  printMedias(){
    return this.state.medias.map((media) => {
      return(
          <tr key={media._id}>
              <td className="valign"><input type="checkbox"/></td>
              <td className="valign">
                <img onClick={() => this.photoBig( media.url)} src={(media.url != null) ? media.url : "/img/userplaceholder.jpg"} alt=""/>
              </td>
              <td className="valign">{media.mime}</td>
              <td className="valign">{media.name}</td>
              <td className="valign"><Moment format="DD/MM/YYYY HH:mm">{media.updatedAt}</Moment></td>
              <td className="valign">{media.size}KB</td>
              <td className="valign actions">
                <Button className="btn-gradient-style" onClick={() => this.deleteMedia(media._id)} > Borrar </Button>
              </td>
          </tr>
      );
    });
  }
  reload = () => 
{
    //RELOAD COMPONENT
    this.componentDidMount();
};
async componentDidUpdate(prevProps, prevState){
  if(prevState.start !== this.state.start || prevState.limit !== this.state.limit || prevState.sort !== this.state.sort || prevState.filter !== this.state.filter || prevState.totalCount !== this.state.totalCount){
    this.setState({
      loading: true
    });
    // await request(constantes("URL_API") + "/upload/files?_limit=0", { method: "GET" })
    // .then((count) => {
    //   this.setState({
    //     totalCount: count.length
    //   })
    // });
    await SearchListServerSide("upload/files", 0, 0, this.state.sort, this.state.area_admin, [], this.state.defaultfilter)
    .then((response) => {
      if(!response){
        localStorage.clear();
        this.props.history.push('/auth/login');
      }else{
        this.setState({
          totalCount: response.length
        })
      }
  });
    await SearchListServerSide("upload/files", this.state.start, this.state.limit, this.state.sort, this.state.area_admin, this.state.filter, this.state.defaultfilter)
    .then((response) => {
      if(!response){
        localStorage.clear();
        this.props.history.push('/auth/login');
      }else{
        this.setState({
          medias: response
        })
      }
  });  
    this.setState({
      loading: false
    });
  }
}
  async componentDidMount(){
    this.setState({
      loading: true
    });
    // await request(constantes("URL_API") + "/upload/files?_limit=0&name_containss=_ADM&name_containss_ne=avatar", { method: "GET" })
    // await request(constantes("URL_API") + "/upload/files?_limit=0", { method: "GET" })
    // .then((count) => {
    //   this.setState({
    //     totalCount: count.length
    //   })
    // });
    await SearchListServerSide("upload/files", 0, 0, this.state.sort, this.state.area_admin, [], this.state.defaultfilter)
    .then((response) => {
      if(!response){
        localStorage.clear();
        this.props.history.push('/auth/login');
      }else{
        this.setState({
          totalCount: response.length
        })
      }
  });
    await SearchListServerSide("upload/files", this.state.start, this.state.limit, this.state.sort, this.state.area_admin, this.state.filter, this.state.defaultfilter)
    .then((response) => {
      if(!response){
        localStorage.clear();
        this.props.history.push('/auth/login');
      }else{
        this.setState({
          medias: response
        })
      }
  });  
    this.setState({
      loading: false
    });
  }

  photoBig(src){
    if(src.url.length > 1 && (src.mime.includes("image")||src.mime.includes("video"))){
      this.setState({
        bigMedia: src.mime,
        bigPhoto: src.url,
        toggleBigPhoto: true
      });
    }else{
      this.setState({
        bigMedia: '',
        bigPhoto: '',
        toggleBigPhoto: false
      });
    }
  }

  render() {
    // console.log(this.state)
      return(
        <div className = "main_content listado">
          <div><h3>Medios</h3></div>
          <div className="rounded_cont contents shadow">
            <div className="row">
              <div className="col">        
              {
                //console.log(this.state.totalCount)
              }
                {/* <SearchListFilterComponent setLoading={this.setLoading} loadFilter={this.loadFilter} model={this.state.model} start={this.state.start} limit={this.state.limit} sort={this.state.sort} field="name" placeholder="Buscar media" defaultfilter={this.state.defaultfilter}/>        */}
                <ReactTable
                  manual
                  pages={ parseInt(this.state.totalCount/this.state.limit, 10) < (this.state.totalCount/this.state.limit) ? (parseInt(this.state.totalCount/this.state.limit, 10) + 1) : (this.state.totalCount/this.state.limit) }
                  defaultPageSize={this.state.limit}
                  onFetchData={this.fetchData}
                  data={this.state.medias}
                  loading={this.state.loading}
                  columns={[{
                    Header: '',                        
                    accessor: "_id",
                    width: 60,
                    Cell: row => (                      
                        <input name={row.value} id={row.value} type="checkbox" onChange={() => this.gestionaBloque(row.value)} checked={ this.state.selectBloque.includes(row.value) ? true : false }/>
                      )
                    },
                    {
                      Header: '',                        
                      accessor: "",
                      width: 120,
                      Cell: row => (
                        <div className = "image shadow">
                          <img onClick={() => this.photoBig( row.value )} src={
                              (row.value.mime.includes("image") ? 
                              row.value.url : ((row.value.mime.includes("video") ? 
                              "/img/videoplaceholder.png" : ((row.value.mime.includes("audio") ? 
                              "/img/audioplaceholder.png" : "/img/fileplaceholder.png")))))
                              } alt=""/>
                        </div>
                      )
                    },
                    {
                      Header: 'Nombre del archivo',                        
                      accessor: "name",
                      headerClassName: "file",
                      Cell: row => (
                        <div className = "file">
                          { row.value }
                        </div>
                      )
                    },
                    { Header: "Tipo",
                      accessor: "mime",
                      width: 160,
                      Cell: row => (
                        <div className = "file-type">
                          { row.value }
                        </div>
                      )
                    },
                    {
                      Header: "Fecha de subida",
                      accessor: "updatedAt",
                      width: 180,
                      Cell: row => (
                        <div className = "updated-at">
                          <Moment format="DD/MM/YYYY HH:mm">{ row.value }</Moment>
                        </div>
                      )
                    },
                    {
                      Header: "Tamaño",
                      accessor: "size",
                      width: 120,
                      Cell: row => (
                        <div className = "size">
                          { row.value } Kb
                        </div>
                      )
                    },
                    {
                      Header: "Eliminar",
                      accessor: "_id",
                      width: 100,
                      Cell: row => (
                        <div className = "delete">
                          <span onClick={ () => {
                                if (window.confirm("¿Estás seguro que quieres borrar esto?")){
                                  this.deleteMedia(row.value)
                                }
                              }
                            } > <i className="fas fa-trash-alt"></i> 
                          </span>
                        </div>
                      )
                    }
                  ]}
                  defaultSorted={[
                    {
                      id: "updatedAt",
                      desc: true
                    }
                  ]}
                  defaultPageSize={10}
                  className="-striped -highlight"
                />

              </div>
              {this.state.selectBloque.length > 0?
              <div className="col-12">
                <div className="row menuBloque">
                  <div className="col-10"> <span >Has seleccionado {this.state.selectBloque.length} {this.state.selectBloque.length<2?"elemento":"elementos"}.</span>
                    </div>
                    <div className="col-2 text-center"> <span onClick={ () => {
                                if (window.confirm("¿Estás seguro que quieres borrar estos elementos?")){
                                  this.state.selectBloque.forEach((element)=>this.deleteMedia(element))                                                                    
                                };
                                this.setState({selectBloque:[]});
                              }
                            } > <i className="fas fa-trash-alt"></i> 
                          </span>
                    </div>
                </div>
                  
              </div>:""}

           
              {
                this.state.toggleBigPhoto ? <div onClick={() => this.photoBig({url:""})} className="coverAll">
                {this.state.bigMedia.includes("image")?
                  <img style={{width: 500, heigth: 500, objectFit: "contain"}} src={this.state.bigPhoto} alt=""/>:this.state.bigMedia.includes("video")?
                  <video preload="metadata" className="imageInput" controls src={this.state.bigPhoto}>Your browser does not support the video tag.</video>:
                  <audio controls src={this.state.bigPhoto} style={{width: 500, heigth: 500, objectFit: "contain"}}>
                      Your browser does not support the video tag.
                  </audio>}
                </div>: null
              }
            </div>
            
          </div>

          <Modal isOpen={this.state.modalIMG} size="lg">         
            <ModalBody className="text-center">
            <UploadMedia cancel="cancel" input = "true" modal={this.state.modal} toggle={this.toggleIMG} typeFile="image/*" mime="image" togglePadre={this.toggleIMG} export={this.exportMedia} recarga={this.reload} elige="noelige" subeConfirma={this.state.zonamedia} listamedia></UploadMedia>
            </ModalBody>                                        
          </Modal>

          <Modal isOpen={this.state.modalVIDEO} size="lg">
            <ModalBody className="text-center">
            <UploadMedia cancel="cancel" input = "true" modal={this.state.modal} toggle={this.toggleVIDEO} typeFile="video/*" mime="video" togglePadre={this.toggleVIDEO} export={this.exportMedia} recarga={this.reload} elige="noelige" subeConfirma={this.state.zonamedia} listamedia></UploadMedia>
            </ModalBody>                                        
          </Modal>

          <Modal isOpen={this.state.modalAudio} size="lg">    
            <ModalBody className="text-center">
            <UploadMedia cancel="cancel" input = "true" modal={this.state.modal} toggle={this.toggleAudio} typeFile="audio/*" mime="audio" togglePadre={this.toggleAudio} export={this.exportMedia} recarga={this.reload} elige="noelige" subeConfirma={this.state.zonamedia} listamedia></UploadMedia>
            </ModalBody>                                        
          </Modal>

          <div className="row add-media">

            <div className="col-4">
              <Button className="btn-gradient-style" onClick={this.toggleIMG}>Añadir Imagen</Button>
            </div>

            <div className="col-4">
              <Button className="btn-gradient-style" onClick={this.toggleVIDEO}>Añadir Video</Button> 
            </div>

            <div className="col-4">
              <Button className="btn-gradient-style" onClick={this.toggleAudio}>Añadir Audio</Button>                      
            </div>

          </div>   
        </div>
      );
  }
}

export default Media;
