import React, { Component } from "react";
import "./styles.css";
import auth from "../../utils/auth";
import request from "../../utils/request";
import constantes from "../../utils/constantes";
import { Link } from "react-router-dom";

class UserView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userAvatar: []
    };
  }
  componentDidMount() {
    request(
      constantes("URL_API") +
        "/graphql?query=%7B%0A%09user%20(id%3A%22" +
        auth.getUserInfo()._id +
        "%22)%7B%0A%09%09picture%7B%0A%09%09%09url%0A%09%09%7D%0A%09%7D%0A%7D",
      { method: "GET" }
    )
      .then(avatar =>
        this.setState({
          userAvatar: avatar.data.user.picture
        })
      )
      .catch(err => {
        // console.log(err);
        // localStorage.clear();
      });
  }
  render() {
    //console.log(auth.getUserInfo()._id)
    return (
      <div className="usuario row">
        <div className="col-12">
          {/* <Link to='/profile/'> */}
          <img
            src={
              this.state.userAvatar
                ? this.state.userAvatar.url
                : "./img/userplaceholder.jpg"
            }
            alt="Avatar"
            className="avatarUser"
          />
          {/* </Link>   */}
        </div>
        <div>
          <div className="infoUser">
            <p className="nameUser">
              {/* <Link to='/profile/'> */}
              {auth.getUserInfo() === null ? "" : auth.getUserInfo().name}{" "}
              {auth.getUserInfo() === null ? "" : auth.getUserInfo().surname}
              {/* </Link> */}
            </p>
            <p className="cargoUser">
              {auth.getUserInfo() === null ? "" : auth.getUserInfo().role.name}
            </p>
            <p style={{ fontSize: 10, color: "darkgrey" }}>
              {"Versión: " + constantes("version")}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default UserView;
