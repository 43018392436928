import React, { Component } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { Alert } from 'reactstrap';
import constantes from '../../utils/constantes';
import request from '../../utils/request';
import auth from '../../utils/auth';
import Loading from '../../components/Loading';

export default class CompartirDocumentos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            exito: false,
            webinarID: this.props.webinarID
        };
    }

    handleSubmit = async (files, allFiles) => {
        this.setState({
            loading:true
        })
        const token = await auth.getToken();
        let results = [];

        files.forEach( async f => {                
            const imgToUpload = new FormData();
            imgToUpload.append('refId', this.state.webinarID);//id del modelo
            imgToUpload.append('ref', "webinar" );//modelo strapi al que se va asociar
            imgToUpload.append('field', "adjuntos");//Campo del modelo (donde va la img)
            imgToUpload.append('files', f.file);

            const response = fetch(constantes("URL_API")+"/upload", {
                method: 'POST',
                body: imgToUpload,
                headers: {
                    'Accept': 'multipart/form-data',
                    'Authorization':`Bearer ${token}`
                },
            })

            results = [...results,response]
        })

        Promise.all(results).then(()=>{       
            allFiles.forEach(f => f.remove())
            this.setState({
                loading:false,
                exito: true
            })
        })

        // console.log(files.map(f => f.meta))
    }

    onDismiss = () => {
        this.setState({
            exito: !this.state.exito
        })
    }

    render() {
        return (
            <div>
                <Loading loading={this.state.loading} />
                {this.state.exito&&<Alert color="success" toggle={this.onDismiss}>
                        {"Se adjuntarón correctamente los ficheros al Webinar."}
                </Alert>}
                <Dropzone
                    onSubmit={this.handleSubmit}
                    inputContent={"Adjunte aquí sus archivos..."}
                    inputWithFilesContent={"Añadir más"}
                    submitButtonContent={"Subir archivos"}
                />
            </div>
        )
    }
}