import React from 'react';
import ReactTable from "react-table";
import './styles.css';
var moment = require('moment');

export default function UsersActivosContent (props){
    return (
      <div>
        <ReactTable
          data={props.dataUsers.map( x => {
            x.sesiones = props.sesiones.filter(e => e.user === x._id).length;
            x.tiempoMedio = props.sesiones.filter(e => e.user === x._id).length>0?
            props.sesiones.filter(e => e.user === x._id).map(e =>e.duracion).reduce((previous, current) => current += previous)/props.sesiones.filter(e => e.user === x._id).length:0;
            x.tiempoTotal = props.sesiones.filter(e => e.user === x._id).length>0?
            props.sesiones.filter(e => e.user === x._id).map(e =>e.duracion).reduce((previous, current) => current += previous):0
            return x
            })}
            sorted={[{id: "sesiones", desc:true}]}
          columns={[
            {
              Header: "Nombre",
              accessor: "email",
              Cell: row => <div className="name">{row.value}</div>
            },
            {
              Header: "Sesiones",
              accessor: "sesiones",
              Cell: row => <div className="retos">{row.value}</div>
            },
            {
              Header: "Tiempo medio sesión",
              accessor: "tiempoMedio",
              Cell: row => (
                <div className="retos">
                  {row.value===0?0:moment(row.value).format("hh:mm:ss")
                  }
                </div>
              )
            },
            {
              Header: "Tiempo total uso",
              accessor: "tiempoTotal",
              Cell: row => (
                <div className="retos">
                  {row.value===0?0:moment(row.value).format("hh:mm:ss")}
                </div>
              )
            }
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </div>
    );
        
    
    
    }
    